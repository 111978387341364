import { createContext, useState, useMemo, useContext } from 'react';

const FallasContext = createContext();

export function FallasProvider(props) {
    const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));
    const [catego, setCatego] = useState([]);
    const [fallas, setFallas] = useState([]);
    const [fallasBk, setFallasBk] = useState([]);
    const [areas, setAreas] = useState([]);
    const [text, setText] = useState('');
    const [textF, setTextF] = useState('');
    const [openM, setOpenM] = useState(false);
    const [openC, setOpenC] = useState(false);
    const [updateGridF, setUpdateGridF] = useState(false);
    const [updateGridC, setUpdateGridC] = useState(false);
    const [selectedArea, setSelectedArea] = useState('');
    const [selectedCatego, setSelectedCatego] = useState('');

    const getCatego = async () => {
        const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/categorias`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
        });
        const categos = await data.json();
        categos.forEach((element) => {
            element.id = element.categoria;
        });

        setCatego(categos);
    };

    const getAreasAndFallas = async () => {
        const dataAreas = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/areas`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
        });
        const areasJS = await dataAreas.json();
        setAreas(areasJS);
        const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/fallas`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
        });
        const fallasJS = await data.json();

        fallasJS.forEach((element) => {
            element.id = element.idFalla;
            areasJS.forEach((item) => {
                if (item.area1 === element.area) {
                    element.areaNombre = item.nombre;
                }
            });
        });
        setFallasBk(fallasJS);
        setFallas(fallasJS);
    };

    const postFalla = (data) => {
        fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/fallas`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(async (response) => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data1 = isJson && (await response.json());
            if (!response.ok) {
                const error = (data1 && data1.message) || response.status;
                return Promise.reject(error);
            } else {
                setOpenM(false);
                setTextF('');
                setSelectedArea('');
                setSelectedCatego('');
                setUpdateGridF(true);
            }
        });
    };

    const postCatego = (data) => {
        fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/categorias/CrearCategoria`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(async (response) => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data1 = isJson && (await response.json());
            if (!response.ok) {
                const error = (data1 && data1.message) || response.status;

                return Promise.reject(error);
            } else {
                setUpdateGridC(true);
                setOpenC(false);
            }
        });
    };

    const handleDataSelect = (info) => {
        if (info.length !== 0) {
            let filterFalla = [];
            fallasBk.forEach((fal) => {
                info.forEach((inf) => {
                    if (fal.idCategoria === inf) {
                        filterFalla.push(fal);
                    }
                });
            });
            setFallas([]);
            setFallas(filterFalla);
        } else {
            getAreasAndFallas();
        }
    };

    const createCatego = (event) => {
        event.preventDefault();
        const data = {
            nombre: text,
            tipo: 1,
        };
        postCatego(data);
    };

    const createFalla = (event) => {
        event.preventDefault();

        const arrFallas = [];
        fallasBk.forEach((element) => {
            arrFallas.push(parseInt(element.idFalla));
        });
        const maxFallaId = Math.max(...arrFallas);

        const recolectedData = {
            idFalla: maxFallaId + 1,
            area: parseInt(selectedArea),
            idCategoria: parseInt(selectedCatego),
            nombre: textF,
        };

        postFalla(recolectedData);
    };

    const handleOpenM = () => {
        setOpenM(true);
    };

    const handleCloseM = () => {
        setOpenM(false);
    };

    const handleOpenC = () => {
        setOpenC(true);
    };

    const handleCloseC = () => {
        setOpenC(false);
    };

    const fallasValues = useMemo(() => {
        return {
            catego,
            setCatego,
            fallas,
            setFallas,
            areas,
            setAreas,
            postFalla,
            postCatego,
            handleDataSelect,
            text,
            setText,
            textF,
            setTextF,
            openM,
            setOpenM,
            createCatego,
            handleOpenM,
            handleCloseM,
            handleOpenC,
            handleCloseC,
            createFalla,
            selectedArea,
            setSelectedArea,
            selectedCatego,
            setSelectedCatego,
            getCatego,
            getAreasAndFallas,
            fallasBk,
            updateGridF,
            setUpdateGridF,
            updateGridC,
            setUpdateGridC,
            openC,
        };
    }, [
        catego,
        fallas,
        fallasBk,
        areas,
        text,
        textF,
        selectedArea,
        selectedCatego,
        openM,
        updateGridF,
        updateGridC,
        getAreasAndFallas,
        getCatego,
        setUpdateGridF,
        setUpdateGridC,
        openC,
    ]);

    return <FallasContext.Provider value={fallasValues} {...props} />;
}

export function useFallas() {
    const context = useContext(FallasContext);
    if (!context) {
        throw new Error('useFallas debe estar dentro del proveedor FallasContext');
    }
    return context;
}
