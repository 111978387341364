import { createTheme } from '@material-ui/core/styles';

export const tecnoTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#eb7d1e',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#3f4a51',
            contrastText: '#FFFFFF',
        },
        divider: '#656E73',
    },
});
