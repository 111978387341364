import React, { createContext, useState, useEffect, useMemo, useContext } from 'react';

const BeepersContext = createContext();

export function BeepersProvider(props) {
    const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));
    const [beepers, setBeepers] = useState([]);
    const [areasAtencion, setAreasAtencion] = useState([]);
    const [nuevo, setNuevo] = useState(false);
    const [editar, setEditar] = useState(false);
    const [isOptionsDisabled, setIsOptionsDisabled] = useState(true);
    const [selectionModel, setSelectionModel] = useState([]);
    const [borrarBeeper, setBorrarBeeper] = useState(false);
    const [borrarComent, setBorrarComent] = useState(false);
    const [snackMsg, setSnackMsg] = useState('Cargando...');
    const [openSnack, setOpenSnack] = useState(false);
    const [comentario, setComentario] = useState(
        'Los comentarios se muestran a medida que seleccione un Beeper'
    );
    const [theBeeper, setTheBeeper] = useState({
        beeper1: 0,
        areaAtencion: 0,
        nivel1: false,
        nivel2: false,
        nivel3: false,
        usarSonido: false,
        comentario: '',
    });

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    const getInfo = async (dto) => {
        const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/${dto}`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
        });
        if (data !== null) {
            const obj = await data.json();

            switch (dto) {
                case 'Beeper/ListadoBeepers':
                    obj.forEach((item) => {
                        item.id = item.beeper1;
                    });

                    setBeepers(obj);
                    break;
                case 'MicroServiciosController?categoria=areasAtencion':
                    obj.forEach((item) => {
                        item.id = item.codigo;
                    });

                    setAreasAtencion(obj);
                    break;
                default:
                    break;
            }
        }
    };

    const getTheBeeper = (idBeeper) => {
        fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/Beeper/${idBeeper}`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
        })
            .then((res) => res.json())
            .catch((error) => console.error('Error: ', error))
            .then((response) => {
                setTheBeeper(response);
                setComentario(response.comentario);
            });
    };

    const postBeeper = (data) => {
        fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/Beeper/AsignarBeeper`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
            .catch((error) => console.error('Error: ', error))
            .then(() => {
                reload();
            });
    };

    const putBeeper = (data) => {
        fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/Beeper/EditarBeeper`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
            .catch((error) => console.error('Error: ', error))
            .then((response) => {
                setSnackMsg(response);
                setOpenSnack(true);
                reload();
            });
    };

    const delBeeper = (id) => {
        fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/Beeper/EliminarBeeper?idBeeper=${id}`, {
            method: 'DELETE',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
            .catch((error) => console.error('Error: ', error))
            .then((response) => {
                setSnackMsg(response);
                setOpenSnack(true);
                reload();
            });
    };

    const reload = async () => {
        await sleep(1500);
        getInfo('Beeper/ListadoBeepers');
        setSelectionModel([]);
        setIsOptionsDisabled(false);
        setComentario(
            'Seleccione un Beeper para ver los comentarios, ademas podrá editarlos desde los tres puntos ubicados en la parte superior'
        );
    };

    useEffect(() => {
        getInfo('Beeper/ListadoBeepers');
        getInfo('MicroServiciosController?categoria=areasAtencion');
    }, []);

    const BeepersValues = useMemo(() => {
        return {
            beepers,
            theBeeper,
            setTheBeeper,
            comentario,
            areasAtencion,
            nuevo,
            setNuevo,
            editar,
            setEditar,
            isOptionsDisabled,
            setIsOptionsDisabled,
            selectionModel,
            setSelectionModel,
            borrarBeeper,
            setBorrarBeeper,
            borrarComent,
            setBorrarComent,
            snackMsg,
            setSnackMsg,
            openSnack,
            setOpenSnack,
            getTheBeeper,
            postBeeper,
            putBeeper,
            delBeeper,
        };
    }, [
        nuevo,
        editar,
        beepers,
        theBeeper,
        comentario,
        areasAtencion,
        isOptionsDisabled,
        selectionModel,
        borrarBeeper,
        borrarComent,
        snackMsg,
        openSnack,
    ]);

    return <BeepersContext.Provider value={BeepersValues} {...props} />;
}

export function useBeepers() {
    const context = useContext(BeepersContext);
    if (!context) throw new Error('useBeepers debe estar dentro del proveedor BeepersContext');
    return context;
}
