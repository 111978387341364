import React from "react";
//Material-UI
import {
  Grid,
  makeStyles,
  Paper,
} from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//Components
import Personal from "./Personal";
import Laboral from "./Laboral";
//Context
import { CuentaProvider, useCuenta } from "./CuentaContext";
import Contacto from "./Contacto";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 140,
    width: '100%',
    backgroundColor: '#DBE0EA',
  },
  circle: {
    borderRadius: '50%',
    width: 132,
    height: 132,
    backgroundColor: '#F2F4F8',
    marginTop: -95,
    marginLeft: 40,
  },
  info: {
    marginTop: 30,
  },
  card: {
    backgroundColor: '#ffff',
    paddingBottom: 30,
    paddingRight: 20,
    borderColor: '#00000014',
    borderStyle: 'solid',
    borderWidth: 1,
  },
  content: {
    paddingLeft: 20,
    paddingTop: 8,
    paddingBottom: 5,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Cuenta() {
  const { openSnack, setOpenSnack, snackMsg } = useCuenta();
  const classes = useStyles();

  const handleClose = () => {
    setOpenSnack(false);
  }

  return (
    <Grid container direction="column" className="doc">
      <Grid item className="head">
        <h1 className="titulo">Mi cuenta</h1>
        <p className="desc">
          Visualiza o modifica la información sobre tu perfil.
        </p>
      </Grid>
      <Grid item className="body">
        <Paper className={classes.paper} />
        <Paper className={classes.circle} />
        <Grid container spacing={5} className={classes.info}>
          <Grid item md={7}>
            <div className={classes.card}>
              <Personal />
            </div>
          </Grid>
          <Grid item md={5}>
            <div className={classes.card}>
              <Laboral />
            </div>
          </Grid>
          <Grid item md={7}>
            <div className={classes.card}>
              <Contacto />
            </div>
          </Grid>
        </Grid>
        <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {snackMsg}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}

export default () => (
  <CuentaProvider>
    <Cuenta></Cuenta>
  </CuentaProvider>
)