import React, { useEffect, useState } from 'react';
//Material-UI
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
//Icon
import DoneIcon from '@material-ui/icons/Done';
//Context
import { useLlamadas } from './LlamadasContext';
//Styles
import '../../styles/llamadas.css';

export default function GeneralEdit() {
  const { areasAtencion, GreenChip, YellowChip, RedChip, BlueChip, theCallType,
    selectedArea, setSelectedArea, setSelectedColor, handleInputChange } = useLlamadas();
  const [green, setGreen] = useState(<DoneIcon />);
  const [red, setRed] = useState(<p></p>);
  const [blue, setBlue] = useState(<p></p>);
  const [yellow, setYellow] = useState(<p></p>);

  const handleAreaChange = (event) => {
    event.preventDefault();
    handleInputChange(event);
    setSelectedArea(event.target.value);
  }
  const handleColorChange = (e, id) => {
    e.preventDefault();
    setSelectedColor(id);
    switch (id) {
      case 1:
        setGreen(<DoneIcon />);
        setRed(<p></p>);
        setBlue(<p></p>);
        setYellow(<p></p>);
        break;
      case 2:
        setGreen(<p></p>);
        setRed(<DoneIcon />);
        setBlue(<p></p>);
        setYellow(<p></p>);
        break;
      case 3:
        setGreen(<p></p>);
        setRed(<p></p>);
        setBlue(<DoneIcon />);
        setYellow(<p></p>);
        break;
      case 4:
        setGreen(<p></p>);
        setRed(<p></p>);
        setBlue(<p></p>);
        setYellow(<DoneIcon />);
        break;
      default:
        setGreen(<p></p>);
        setRed(<p></p>);
        setBlue(<p></p>);
        setYellow(<p></p>);
        break;
    }
  }

  useEffect(() => {
    switch(theCallType.color){
      case 1:
        setGreen(<DoneIcon />);
        setRed(<p></p>);
        setBlue(<p></p>);
        setYellow(<p></p>);
        break;
      case 2:
        setGreen(<p></p>);
        setRed(<DoneIcon />);
        setBlue(<p></p>);
        setYellow(<p></p>);
        break;
      case 3:
        setGreen(<p></p>);
        setRed(<p></p>);
        setBlue(<DoneIcon />);
        setYellow(<p></p>);
        break;
      case 4:
        setGreen(<p></p>);
        setRed(<p></p>);
        setBlue(<p></p>);
        setYellow(<DoneIcon />);
        break;
      default:
        setGreen(<p></p>);
        setRed(<p></p>);
        setBlue(<p></p>);
        setYellow(<p></p>);
        break;
    }
    setSelectedArea(theCallType.areaAtencion);
  }, [theCallType])

  return (
    <div className="popupbodyCalltype">
      <div className="textfieldCalltype">
        <TextField
          name="nombre"
          label="NOMBRE"
          fullWidth
          defaultValue={theCallType.nombre}
          onChange={e => handleInputChange(e)}
        />
      </div>
      <div className="textfieldCalltype">
        <InputLabel shrink id="lblArea" fullWidth>
          AREA DE ATENCIÓN
        </InputLabel>
        <Select
          labelId="lblArea"
          name="areaAtencion"
          onChange={handleAreaChange}
          displayEmpty
          value={selectedArea}
          fullWidth>
          {areasAtencion.map(item => (
            <MenuItem
              key={item.codigo.toString()}
              value={item.codigo.toString()}>
              {item.nombre.toString()}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="color">
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend" style={{ marginBottom: 20 }}>SELECCIONAR COLOR</FormLabel>
          <Grid container spacing={1} style={{ marginBottom: 20 }}>
            <Grid item sm={3}>
              <GreenChip
                label="VERDE"
                color="primary"
                clickable
                onClick={e => handleColorChange(e, 1)}
                onDelete={e => handleColorChange(e, 1)}
                deleteIcon={green}
              />
            </Grid>
            <Grid item sm={3}>
              <RedChip
                label="ROJO"
                color="primary"
                clickable
                onClick={e => handleColorChange(e, 2)}
                onDelete={e => handleColorChange(e, 2)}
                deleteIcon={red}
              />
            </Grid>
            <Grid item sm={3}>
              <BlueChip
                label="AZUL"
                color="primary"
                clickable
                onClick={e => handleColorChange(e, 3)}
                onDelete={e => handleColorChange(e, 3)}
                deleteIcon={blue}
              />
            </Grid>
            <Grid item sm={3}>
              <YellowChip
                label="AMARILLO"
                color="primary"
                clickable
                onClick={e => handleColorChange(e, 4)}
                onDelete={e => handleColorChange(e, 4)}
                deleteIcon={yellow}
              />
            </Grid>
          </Grid>
        </FormControl>
      </div>
    </div>
  )
}