import React from 'react';
//components
import PopUpS from './popupsmall';
//Material-UI
import { Button } from '@material-ui/core';
//Icons
import { Trash } from 'tabler-icons-react';
//Context
import { useProductos } from './ProductosContext';

export default function DeleteProduct() {
    const { delProd, actions, setBorrar } = useProductos();
    return (
        <PopUpS
            colorButton="default"
            buttonClass=""
            textButton={
                <div>
                    <Trash size={20} className="icon" /> Borrar
                </div>
            }
            popupSeconButton="Cancelar"
            popupsbclass="btn-del-cnl"
            isContained="text"
            isActive={actions}
            popupTitle={
                <div className="icon-big">
                    <Trash size={120} color={'#7D7F90'} />
                </div>
            }
            popupBody={
                <div className="popup-delete">
                    <div className="message">¿Está Seguro Que Desea Eliminar Este Producto?</div>
                    <div className="popups-button">
                        <Button
                            color="primary"
                            variant="contained"
                            className="btn-del"
                            onClick={(e) => {
                                delProd();
                                setBorrar(false);
                            }}
                        >
                            Eliminar
                        </Button>
                    </div>
                </div>
            }
        />
    );
}
