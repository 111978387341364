import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
//Contexto
import { useReportes } from './ReportesContex';

import {
    DataGrid,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbar,
    esES,
} from '@material-ui/data-grid';
//Icons
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { TextField, IconButton, Chip } from '@material-ui/core';
import { CustomFooterTotalComponent1 } from './CustomFooter1';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        backgroundColor: '#F2F4F8',
    },
    divider: {
        margin: 4,
    },
    textField: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        width: '99%',
    },
}));

//BEGIN SEARCH TOOLBAR
function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
    const classes = useStyles();
    return (
        <div>
            <div>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </div>
            <TextField
                variant="standard"
                fullWidth
                value={props.value}
                onChange={props.onChange}
                placeholder="Buscar..."
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon size="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon size="small" />
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

//END SEARCH TOOLBAR

export default function Corridas() {
    const { getCorridas, corridas } = useReportes();
    const [producido, setProducido] = useState();
    const [malas, setMalas] = useState();
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState(corridas);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = corridas.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
    };

    const columns = [
        {
            field: 'sku',
            headerName: 'Sku',
            width: 150,
            editable: false,
            align: 'center',
        },
        {
            field: 'ordenProd',
            headerName: 'Orden',
            width: 180,
            editable: false,
            align: 'center',
        },
        {
            field: 'corrida',
            headerName: 'Corrida',
            width: 200,
            editable: false,
            align: 'center',
        },
        {
            field: 'fechaIniReal',
            headerName: 'Fecha Inicio',
            width: 180,
            editable: false,
            align: 'center',
        },
        {
            field: 'fechaFinReal',
            headerName: 'Fecha Final',
            width: 200,
            editable: false,
            align: 'center',
        },
        {
            field: 'producido',
            headerName: 'Producido',
            width: 180,
            editable: false,
            align: 'center',
        },
        {
            field: 'piezasMalas',
            headerName: 'Piezas Malas',
            width: 180,
            editable: false,
            align: 'center',
        },
    ];

    useEffect(() => {
        getCorridas();
    }, []);

    useEffect(() => {
        setRows(corridas);
    }, [corridas]);

    return (
        <div style={{ height: 700, width: '100%', marginLeft: '30px' }}>
            <DataGrid
                components={{ Toolbar: QuickSearchToolbar }}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (event) => requestSearch(event.target.value),
                        // clearSearch: () => requestSearch(''),
                    },
                }}
                rows={rows}
                disableDensitySelector
                localeText={esES.props.MuiDataGrid.localeText}
                columns={columns}
                checkboxSelection={false}
                components={{
                    Toolbar: GridToolbar,
                    Footer: CustomFooterTotalComponent1,
                }}
                componentsProps={{
                    footer: { producido, malas },
                }}
                pageSize={100}
                disableSelectionOnClick
                onStateChange={(state) => {
                    let producido = 0;
                    let malas = 0;
                    if (state.state.filter.items.length !== 0) {
                        if (state.state.filter.items[0].value !== undefined) {
                            const visibleRows = state.state.visibleRows.visibleRowsLookup;

                            for (const [id, value] of Object.entries(visibleRows)) {
                                if (value === true) {
                                    producido = producido + corridas[parseInt(id)].producido;
                                    malas = malas + corridas[parseInt(id)].piezasMalas;
                                }
                            }
                        } else {
                            corridas.map((sOrder) => {
                                producido = producido + sOrder.producido;
                                malas = malas + sOrder.piezasMalas;
                            });
                        }
                    } else {
                        corridas.map((sOrder) => {
                            producido = producido + sOrder.producido;
                            malas = malas + sOrder.piezasMalas;
                        });
                    }

                    setProducido(producido);
                    setMalas(malas);
                }}
            />
        </div>
    );
}
