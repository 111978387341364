import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        fontFamily: 'WorkSans',
        position: 'absolute',
        width: 384,
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '0px 0px 20px 0px',
    },
}));

function Alerta(props) {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setOpen(props.abrir);
    }, [props.abrir]);

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <div id="simple-modal-title">{props.popupTitle}</div>
            <div id="simple-modal-description">
                {props.popupBody}
                <div className="popupbottom">
                    <div className={props.popupsbclass}>
                        <Button variant="outlined" color="primary" className="popup-button-s" onClick={handleClose} fullWidth>
                            {props.popupSeconButton}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
        </div>
    )
}

Alerta.defaultProps = {
    popupTitle: "Título",
    popupBody: "Cuerpo de la ventana emergente",
    popupsbclass: "btn-del-cnl",
    popupSeconButton: "Cancelar",
    colorButton: "primary",
    buttonClass: "btn-guardar",
    textButton: "Submit",
    isContained: "contained",
    isActive: true
}

export default Alerta;