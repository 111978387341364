import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import '../login.css';
import LogoTSR from '../logos/LogoTSR.svg';
import LogoTBB from '../logos/LogoTBB.svg';
import LogoNova from '../logos/LogoNova.svg';
import LogoColB from '../logos/LogoColB.svg';
import LogoMTB from '../logos/LogoMTB.png';
import LogoSK from '../logos/LogoSK.png';
import InputAdornment from '@material-ui/core/InputAdornment';
import { deepPurple, green, deepOrange } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { User, EyeOff, Eye } from 'tabler-icons-react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { FormHelperText, makeStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { useGeneral } from '../Context/GeneralContext';

let logo,
    background,
    bColor,
    flag = false;
if (process.env.REACT_APP_THEME === 'tecnoTheme') {
    logo = LogoTBB;
    background = 'tecnobloques';
    flag = true;
    bColor = '#eb7d1e';
} else if (process.env.REACT_APP_THEME === 'colTheme') {
    logo = LogoColB;
    flag = true;
} else if (process.env.REACT_APP_THEME === 'matteTheme') {
    logo = LogoMTB;
    flag = true;
} else if (process.env.REACT_APP_THEME === 'skyTheme') {
    logo = LogoSK;
    flag = true;
} else {
    logo = '';
    background = 'nova';
    flag = false;
    bColor = '#903BFF';
}

async function LoginUser(credentials) {
    return fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/usuarios/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
    }).then((data) => {
        if (data.status === 200) {
            return data.json();
        } else {
            return false;
        }
    });
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginLeft: '0%',
        position: 'absolute',
        fontFamily: 'WorkSans',
        fontSize: '23px',
        fontWeight: 'bold',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    root1: {
        display: 'flex',
        alignItems: 'center',
        width: '70%',
        height: '2.75rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        textTransform: 'capitalize',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        width: '100%',
        height: '2.75rem',
    },
    buttonSuccess: {
        width: '100%',
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        width: '100%',
        color: bColor[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -20,
        marginLeft: -12,
    },
}));

export default function Login({ setToken }) {
    const { infoUser, setInfoUser, setIsConsulta } = useGeneral();
    const [usuario, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [errorV, setErrorV] = useState(false);
    const [errorU, setErrorU] = useState(false);
    const [errorC, setErrorC] = useState(false);
    const [openC, setOpenC] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = React.useState(false);
    const classes = useStyles();

    const getUsuario = async (token, usuario) => {
        const data = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/usuarios/GetUsuario?username=${usuario}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
            }
        );
        const info = await data.json();

        if (data.status !== 404) {
            return info.roles;
        } else {
            return [];
        }
    };

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccess(false);
        if (usuario.length === 0 && password.length === 0) {
            setErrorU(true);
            setErrorC(true);
            setLoading(false);
            setSuccess(false);
        } else if (usuario.length === 0) {
            setErrorU(true);
            setLoading(false);
            setSuccess(false);
        } else if (password.length === 0) {
            setErrorC(true);
            setLoading(false);
            setSuccess(false);
        } else {
            setErrorU(false);
            setErrorC(false);
            const token = await LoginUser({
                usuario,
                password,
            });
            if (token) {
                const roles = await getUsuario(token, usuario);

                let isAdmin = false;

                if (roles.length !== 0) {
                    roles.map((rol) => {
                        if (rol === 'Administrador') {
                            isAdmin = true;
                        } else if (rol === 'Consulta') {
                            isAdmin = true;
                            setIsConsulta(true);
                        }
                    });
                } else {
                    setErrorV(true);
                    setLoading(false);
                    setSuccess(false);
                }
                if (isAdmin) {
                    setToken(token);
                    setInfoUser(usuario);
                    setLoading(false);
                    setSuccess(true);
                } else {
                    setOpenC(true);
                    setSuccess(false);
                    setLoading(false);
                }
                // setToken(token);
            } else {
                setErrorV(true);
                setLoading(false);
                setSuccess(false);
            }
        }
    };
    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const ColorButton = withStyles((theme) => ({
        root: {
            color: 'primary',
            backgroundColor: 'primary',
            '&:hover': {
                backgroundColor: 'primary',
            },
        },
    }))(Button);

    const handlePassChange = (e) => {
        setErrorC(false);
        setErrorV(false);
        setPassword(e.target.value);
        handleChange('password')(e);
    };

    const handleUserName = (e) => {
        setUserName(e);
        setErrorU(false);
        setErrorV(false);
    };

    return (
        <div className={background}>
            <div className="login-wrapper">
                <div className={classes.root}>
                    <Collapse in={openC}>
                        <Alert
                            severity="error"
                            variant="filled"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenC(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            No cuenta con los permisos para realizar esta acción!
                        </Alert>
                    </Collapse>
                </div>
                <div className="login-wrapper-titles">
                    <img src={LogoNova} className="login-wrapper-titles-novalogo" alt="tsr-logo" />
                    <p className="login-title">Sistema Andon OEE</p>
                    <p className="login-title2">Módulo de reportes y planeación de la producción</p>
                </div>
                <div className="login-wrapper-formulario">
                    <form onSubmit={handleSubmit}>
                        <div className="login-username-field">
                            <FormControl error={errorU} color="primary" className="login-username">
                                <InputLabel htmlFor="standard-adornment-username">
                                    Nombre de usuario
                                </InputLabel>
                                <Input
                                    type="text"
                                    id="c"
                                    value={values.username}
                                    onChange={(e) => handleUserName(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <User
                                                className="login-username-icon"
                                                color="#5F606F"
                                                strokeWidth={2}
                                                size={25}
                                            ></User>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText className="error">
                                    {errorU ? 'Debe diligenciar el usuario' : ''}
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div className="login-username-field">
                            <FormControl className="login-password" color="primary" error={errorC}>
                                <InputLabel htmlFor="standard-adornment-password">
                                    Contraseña
                                </InputLabel>
                                <Input
                                    id="standard-adornment-password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handlePassChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                size="small"
                                                className="login-password-icon"
                                            >
                                                {values.showPassword ? (
                                                    <Eye
                                                        className="login-username-icon"
                                                        size={25}
                                                        strokeWidth={2}
                                                        color="#5F606F"
                                                    />
                                                ) : (
                                                    <EyeOff
                                                        className="login-username-icon"
                                                        size={25}
                                                        strokeWidth={2}
                                                        color="#5F606F"
                                                    />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText className="error">
                                    {errorC ? 'Debe diligenciar la contraseña' : ''}
                                </FormHelperText>

                                <FormHelperText className="error">
                                    {errorV ? 'usuario o contraseña invalido' : ''}
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div className="login-button-field">
                            <div className={classes.root1}>
                                <div className={classes.wrapper}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={buttonClassname}
                                        disabled={loading}
                                        type="submit"
                                    >
                                        Ingresar
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            size={33}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </div>
                            </div>

                            {/* <Button
                                type="submit"
                                variant="contained"
                                className="login-button"
                                color="primary"
                            >
                                Ingresar
                            </Button> */}
                        </div>
                    </form>
                </div>
            </div>
            <div
                className="login-logo"
                style={{
                    display: flag ? 'felx' : 'none',
                }}
            >
                <div className="login-logo-companylogo">
                    <img src={logo} className="login-logo-complogo-img" alt="company-logo" />
                </div>

                <div className="login-logo-tsr-logo">
                    <img src={LogoTSR} className="login-logo-tsrlogo-img" alt="tsr-logo" />
                </div>
            </div>
            <div
                className="login-logo"
                style={{
                    display: flag ? 'none' : 'flex',
                }}
            >
                <div className="login-logo-otsrlogo">
                    <img src={LogoTSR} className="login-logo-otsrlogo-img" alt="tsr-logo" />
                </div>
            </div>
        </div>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
};
