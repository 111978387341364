import { createContext, useState, useMemo, useContext, useEffect } from 'react';

const ProductosContext = createContext();

export function ProductosProvider(props) {
    const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));
    const [selectedIndex, setSelectedIndex] = useState('');
    const [theProd, setTheProd] = useState({ piezasPorPaquete: '', paquetesPorTarima: '' });
    const [prodName, setProdName] = useState('');
    const [isSaveLines, setIsSaveLines] = useState(true);
    const [isSaveCap, setIsSaveCap] = useState(true);
    const [isProdSelected, setIsProdSelected] = useState(true);
    const [isLineSelected, setIsLineSelected] = useState(true);
    const [alerta, setAlerta] = useState('');
    const [abrir, setAbrir] = useState(false);
    const [selectedLine, setSelectedLine] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [checked, setChecked] = useState([]);
    const [product, setProduct] = useState([]);
    const [lines, setLines] = useState([]);
    const [pzas, setPzas] = useState(0);
    const [ideal, setIdeal] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [std, setStd] = useState(0);
    const [editProduct, setEditProduct] = useState({
        eNombre: '',
        EPP: '',
        EPT: '',
        sku: '',
    });
    const [capacities, setCapacities] = useState([]);
    const [prodLine, setProdLine] = useState([]);
    const [actions, setActions] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [nuevo, setNuevo] = useState(false);
    const [editar, setEditar] = useState(false);
    const [borrar, setBorrar] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [msgSb, setMsgSb] = useState('Se guardó la información correctametne');
    const [newProd, setNewProd] = useState({
        id: null,
        sku: '',
        nombre: '',
    });

    const handleListItemClick = (index) => {
        setSelectedIndex(index);
        getCapacities(index);
        getSelectedLines(index);
        setIsProdSelected(false);
        setSelectedLine(0);
        setIsLineSelected(true);
        setIsSaveCap(true);
        // setPzas(0);
        // setIdeal(0);
        // setStd(0);
    };
    const handleListLinesClick = (event, index) => {
        setSelectedLine(index);
        // getCapacities(selectedIndex);
        showCapacities(index);
        isChecked(index - 1);
    };
    const handleClick = (sku, name) => {
        //setAnchorEl(event.currentTarget);
        setSelectedIndex(sku);
        setProdName(name);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        setIsSaveLines(false);
    };
    const loadLines = (array) => {
        const newChecked = [...checked];
        newChecked.length = 0;

        array.forEach((element) => {
            newChecked.push(element);
        });

        setChecked(newChecked);
        setIsSaveLines(true);
    };
    const clearLines = () => {
        const newChecked = [...checked];
        newChecked.length = 0;
        setChecked(newChecked);
    };
    const handleinputChange = (event) => {
        setNewProd({
            ...newProd,
            [event.target.name]: event.target.value,
        });
    };
    const getProducts = async () => {
        const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/productos`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
        });
        const products = await data.json();
        setProduct(products);
    };
    const getTheProduct = (sku) => {
        fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/productos/GetProductoSku?sku=${sku}`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
        })
            .then((res) => res.json())
            .catch((error) => console.error('Error: ', error))
            .then((response) => {
                setProdName(response.nombre);
                setTheProd(response);
            });
    };
    const getLines = async () => {
        const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/lineas`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
        });
        const line = await data.json();

        setLines(line);
    };
    const getCapacities = (sku) => {
        if (sku !== '') {
            fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/capacidades?sku=${sku}`, {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                },
            })
                .then((res) => res.json())
                .catch((error) => console.error('Error: ', error))
                .then((response) => {
                    if (response != null) {
                        if (
                            response !==
                            'El producto especificado no existe o no tiene asignada una capacidad'
                        ) {
                            setCapacities(response);
                        } else {
                            setPzas(0);
                            setIdeal(0);
                            setStd(0);
                        }
                    }
                });
        }
    };
    const showCapacities = (line) => {
        if (line !== '' && capacities.length > 0) {
            const capa = capacities.find((capacitie) => capacitie.linea === line);
            if (capa !== undefined) {
                setPzas(capa.piezasPorPaquete);
                setIdeal(capa.ritmoIdeal);
                setStd(capa.ritmoEstandar);
            } else {
                setPzas(0);
                setIdeal(0);
                setStd(0);
            }
        } else {
            setPzas(0);
            setIdeal(0);
            setStd(0);
        }
    };
    const getSelectedLines = (sku) => {
        if (sku !== '') {
            fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/productolinea/${sku}`, {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                },
            })
                .then((res) => res.json())
                .catch((error) => console.error('Error: ', error))
                .then((response) => {
                    const array = [];
                    if (
                        response != null &&
                        response !== 'Producto no encontrado o ha sido eliminado'
                    ) {
                        response.forEach((item) => array.push(item.linea - 1));
                        loadLines(array);
                        setProdLine(array);
                    } else {
                        clearLines();
                    }
                });
        }
    };

    const cnlLines = (event) => {
        event.preventDefault();
        getSelectedLines(selectedIndex);
    };

    const cnlCap = (event) => {
        event.preventDefault();
        setIsSaveCap(true);
        showCapacities(selectedLine);
    };

    const setNewProduct = async (data) => {
        const res = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/Productos`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        const respuesta = await res.text();

        if (res.status === 200) {
            getProducts();
            setMsgSb('Producto creado correctamente.');
            setSnackbar(true);
            setNuevo(false);
        } else {
            console.log(respuesta, res.status);
        }
    };

    const setEditProd = (data) => {
        fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/productos/${data.sku}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
            .catch((error) => console.error('Error: ', error))
            .then((response) => {
                getProducts();
                setMsgSb(response);
                setSnackbar(true);
                setSearchText('');
            });
    };
    const delProd = async () => {
        const resp = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/productos/Eliminar?sku=${editProduct.sku}`,
            {
                method: 'PUT',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                },
            }
        );
        const respuesta = await resp.json();
        if (resp.status === 200) {
            console.log(respuesta);
            setMsgSb('Producto eliminado satisfactoriamente.');
            setSnackbar(true);
            getProducts();
        } else {
            setMsgSb(respuesta);
            setSnackbar(true);
            getProducts();
        }
    };
    const saveLines = async (event) => {
        event.preventDefault();
        const addLines = checked.filter((elemento) => prodLine.indexOf(elemento) === -1);
        const deleteLines = prodLine.filter((elemento) => checked.indexOf(elemento) === -1);
        if (addLines.length > 0) {
            let data;

            const postLines = await Promise.all(
                addLines.map(async (addline) => {
                    data = {
                        linea: addline + 1,
                        sku: editProduct.sku,
                    };

                    return await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/productolinea`, {
                        method: 'POST',
                        body: JSON.stringify(data),
                        headers: {
                            authorization: `Bearer ${tokenSessionObject.token}`,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    });
                })
            );

            let cont = 0;

            postLines.map((postLine) => {
                console.log(postLine.status === 201 || postLine.status === 204);
                if (postLine.status === 201 || postLine.status === 204) {
                    cont++;
                }
            });
            if (cont === postLines.length) {
                setMsgSb('Se guardó la información correctamente');
                setSnackbar(true);
            }
        }
        if (deleteLines.length > 0) {
            let data1;
            const delLines = await Promise.all(
                deleteLines.map(async (deleteLine) => {
                    data1 = {
                        linea: deleteLine + 1,
                        sku: editProduct.sku,
                    };

                    return await fetch(
                        `${process.env.REACT_APP_SERVICE_ENDPOINT}/productolinea/eliminar`,
                        {
                            method: 'PUT',
                            body: JSON.stringify(data1),
                            headers: {
                                authorization: `Bearer ${tokenSessionObject.token}`,
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                        }
                    );
                })
            );
            let cont1 = 0;

            delLines.map((delLine) => {
                if (delLine.status === 201 || delLine.status === 204) {
                    cont1++;
                }
            });
            if (cont1 === delLines.length) {
                setMsgSb('Se guardó la información correctamente');
                setSnackbar(true);
            }
        }
        getSelectedLines(editProduct.sku);
    };

    const saveCapacities = (event) => {
        event.preventDefault();
        let flag = true;
        //Buscamos la informacion de la capacidad
        capacities.forEach((capacity) => {
            if (capacity.linea === selectedLine && capacity.sku === selectedIndex && flag) {
                flag = false;
                const data = {
                    linea: capacity.linea,
                    sku: capacity.sku,
                    celda: capacity.celda,
                    esDeLinea: capacity.esDeLinea,
                    piezasPorPaquete: pzas,
                    ritmoIdeal: ideal,
                    ritmoEstandar: std,
                };
                fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/capacidades`, {
                    method: 'PUT',
                    body: JSON.stringify(data),
                    headers: {
                        authorization: `Bearer ${tokenSessionObject.token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                    .then((res) => res.json())
                    .catch((error) => console.error('Error: ', error))
                    .then((response) => {
                        if (response !== undefined) setMsgSb(response);
                        else setMsgSb('Se guardó la información correctametne');
                        setSnackbar(true);
                        setIsSaveCap(true);
                    });
            }
        });
        if (flag) {
            const data = {
                linea: selectedLine,
                sku: selectedIndex,
                celda: 0,
                esDeLinea: true,
                piezasPorPaquete: pzas,
                ritmoIdeal: ideal,
                ritmoEstandar: std,
            };
            fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/capacidades`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
                .then((res) => res.json())
                .catch((error) => console.error('Error: ', error))
                .then((response) => {
                    if (response !== undefined) setMsgSb(response);
                    else setMsgSb('Se guardó la información correctametne');
                    setSnackbar(true);
                    setIsSaveCap(true);
                });
        }
    };
    const postNewProd = (event) => {
        event.preventDefault();
        setNewProduct(newProd);
    };
    const putProd = (event) => {
        event.preventDefault();
        const prodEd = {
            id: editProduct.sku,
            nombre: editProduct.eNombre,
            sku: editProduct.sku,
            piezasPorPaquete: editProduct.EPP,
            paquetesPorTarima: editProduct.EPT,
        };
        setEditar(false);
        setEditProd(prodEd);
    };

    // function sleep(ms) {
    //     return new Promise((resolve) => setTimeout(resolve, ms));
    // }
    // const dataTable = async () => {
    //     await sleep(3500);
    //     getProducts();
    // };
    const isChecked = (index) => {
        const currentIndex = checked.indexOf(index);

        if (currentIndex !== -1) {
            setIsLineSelected(false);
        } else {
            setIsLineSelected(true);
        }
    };
    const increment = (event, field) => {
        event.preventDefault();
        setIsSaveCap(false);
        switch (field) {
            case 0:
                setPzas(pzas + 1);
                break;
            case 1:
                setIdeal(ideal + 1);
                break;
            case 2:
                setStd(std + 1);
                break;
            default:
                break;
        }
    };
    const decrement = (event, field) => {
        event.preventDefault();
        setIsSaveCap(false);
        switch (field) {
            case 0:
                if (pzas !== 0) {
                    setPzas(pzas - 1);
                }
                break;
            case 1:
                if (ideal !== 0) {
                    setIdeal(ideal - 1);
                }
                break;
            case 2:
                if (std !== 0) {
                    setStd(std - 1);
                }
                break;
            default:
                break;
        }
    };

    const productosValues = useMemo(() => {
        return {
            selectedIndex,
            setSelectedIndex,
            theProd,
            prodName,
            setProdName,
            getTheProduct,
            isSaveLines,
            setIsSaveLines,
            isSaveCap,
            setIsSaveCap,
            actions,
            setActions,
            isProdSelected,
            setIsProdSelected,
            isLineSelected,
            setIsLineSelected,
            alerta,
            setAlerta,
            abrir,
            setAbrir,
            selectedLine,
            setSelectedLine,
            anchorEl,
            setAnchorEl,
            checked,
            setChecked,
            product,
            setProduct,
            lines,
            setLines,
            pzas,
            setPzas,
            ideal,
            setIdeal,
            std,
            setStd,
            getProducts,
            capacities,
            setCapacities,
            prodLine,
            setProdLine,
            newProd,
            setNewProd,
            handleListItemClick,
            handleListLinesClick,
            handleClick,
            handleClose,
            handleToggle,
            handleinputChange,
            getLines,
            cnlLines,
            cnlCap,
            saveLines,
            msgSb,
            setMsgSb,
            saveCapacities,
            postNewProd,
            putProd,

            increment,
            decrement,
            snackbar,
            setSnackbar,
            selectionModel,
            setSelectionModel,
            nuevo,
            setNuevo,
            editar,
            setEditar,
            borrar,
            setBorrar,
            editProduct,
            setEditProduct,
            searchText,
            setSearchText,
            delProd,
        };
    }, [
        selectedIndex,
        theProd,
        prodName,
        isSaveLines,
        isSaveCap,
        isProdSelected,
        isLineSelected,
        alerta,
        abrir,
        selectedLine,
        anchorEl,
        checked,
        product,
        lines,
        pzas,
        ideal,
        snackbar,
        msgSb,
        std,
        capacities,
        prodLine,
        newProd,
        actions,
        selectionModel,
        nuevo,
        editar,
        borrar,
        editProduct,
        setEditProduct,
        searchText,
        setSearchText,
        delProd,
    ]);

    return <ProductosContext.Provider value={productosValues} {...props} />;
}

export function useProductos() {
    const context = useContext(ProductosContext);
    if (!context) throw new Error('useProductos debe estar dentro del proveedor ProductosContext');
    return context;
}
