import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import '../App.css';
import '../popup.css';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        fontFamily: 'WorkSans',
        position: 'absolute',
        // width: 384,
        // height: 530,
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '0px 0px 0px 0px',
    },
}));

export default function ModalS(props) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <div id="simple-modal-title">{props.popupTitle}</div>
            <div id="simple-modal-description">{props.popupBody}</div>
        </div>
    );

    return (
        <div>
            <Modal
                open={props.openM}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}
