import { createContext, useState, useEffect, useMemo, useContext } from "react";
//Style
import { withStyles } from "@material-ui/core/styles";
import { blue, red, green } from '@material-ui/core/colors';
//Material UI
import Chip from '@material-ui/core/Chip';

const LlamadasContext = createContext();

export function LlamadasProvider(props) {
  const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));
  const [nuevo, setNuevo] = useState(false);
  const [editar, setEditar] = useState(false);
  const [llamadas, setLlamadas] = useState([]);
  const [areasAtencion, setAreasAtencion] = useState([]);
  const [deptos, setDeptos] = useState([]);
  const [sites, setSites] = useState([]);
  const [areas, setAreas] = useState([]);
  const [lineas, setLineas] = useState([]);
  const [celdas, setCeldas] = useState([]);
  const [tiposUbicacion, setTiposUbicacion] = useState([]);
  const [step, setStep] = useState(0);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState('Se creó el nuevo tipo de llamada correctamente');
  //Edit tipo llamada
  const [isEdit, setIsEdit] = useState(false);
  const [theCallType, setTheCallType] = useState([]);
  //Nuevo tipo llamada
  const [selectedArea, setSelectedArea] = useState(1);
  const [selectedColor, setSelectedColor] = useState(1);
  const [ubRepeater, setUbRepeater] = useState([]);
  const [rolesChecked, setRolesChecked] = useState([]);
  const [nivel1Checked, setNivel1Checked] = useState([]);
  const [nivel2Checked, setNivel2Checked] = useState([]);
  const [nivel3Checked, setNivel3Checked] = useState([]);
  const [newCallType, setNewCallType] = useState({
    "tipoLlamada": -1,
    "color": 0,
    "areaAtencion": 1,
    "nombre": '',
    "esDowntime": false,
    "esSonora": false,
    "tiempoStdRespuesta": 0,
    "tiempoStdReparacion": 0,
    "tiempoNivel3": 0,
    "requiereClasificacion": false,
    "soloBeeper": false,
    "borrado": false,
    "llamadasUbicacion": [],
    "rolesLlamadas": [],
    "nivel1": [],
    "nivel2": [],
    "nivel3": []
  });

  const getInfo = async (dto) => {
    const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/${dto}`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
      },
    })
    if (data !== null) {
      const obj = await data.json();

      switch (dto) {
        case 'tiposllamada/Listado':
          obj.forEach(element => {
            element.id = element.tipoLlamada;
          });
          setLlamadas(obj);
          break;
        case 'MicroServiciosController?categoria=AreasAtencion':
          setAreasAtencion(obj);
          break;
        case 'MicroServiciosController?categoria=TiposUbicacion':
          setTiposUbicacion(obj);
          break;
        case 'sites':
          obj.forEach(item => {
            item.codigo = item.site1
          });
          setSites(obj);
          break;
        case 'areas':
          obj.forEach(item => {
            item.codigo = item.area1;
          });
          setAreas(obj);
          break;
        case 'lineas':
          obj.forEach(item => {
            item.codigo = item.id;
          });
          setLineas(obj);
          break;
        case 'celdas':
          obj.forEach(item => {
            item.codigo = item.celda1;
          });
          setCeldas(obj);
          break;
        case 'tiposllamada/CatalogoDepartamentos':
          setDeptos(obj);
          break;
        default:
          console.error(obj);
          break;
      }
    }
  }

  const getTheCallType = (idCallType) => {
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/tiposllamada/TipoLllamadaEspecifica?tipolladada=${idCallType}`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
      },
    }).then(res => res.json())
      .catch(error => console.error('Error: ', error))
      .then(response => {
        setTheCallType(response);
      });
  }

  const handleInputChange = (event) => {
    setNewCallType({
      ...newCallType,
      [event.target.name]:
        event.target.type === 'number' ?
          parseInt(event.target.value) :
          event.target.type === 'checkbox' ?
            event.target.checked :
            event.target.value,
    });
  };

  const postCallType = () => {
    const data = newCallType; 
    data.llamadasUbicacion = ubRepeater;
    data.rolesLlamadas = rolesChecked;
    data.nivel1 = nivel1Checked;
    data.nivel2 = nivel2Checked;
    data.nivel3 = nivel3Checked;
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/tiposllamada/Agregar`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(res => res.json())
      .catch(error => console.error('Error: ', error))
      .then(response => {
        setSnackbarText(response[0]);
        setSnackbar(true);
        clear();
      });
  }

  const putCallType = () => {
    const data = newCallType; 
    data.llamadasUbicacion = ubRepeater;
    data.rolesLlamadas = rolesChecked;
    data.nivel1 = nivel1Checked;
    data.nivel2 = nivel2Checked;
    data.nivel3 = nivel3Checked;
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/tiposllamada/Editar`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(res => res.json())
      .catch(error => console.error('Error: ', error))
      .then(response => {
        setSnackbarText(response[0]);
        setSnackbar(true);
        clear();
      });
  }

  const GreenChip = withStyles({
    root: {
      backgroundColor: green[400],
      color: '#FFFF',
      '&$clickable': {
        backgroundColor: green[600],
        color: '#FFFF'
      }
    },
    clickable: {},
  })((props) => <Chip color="default" {...props} />);

  const YellowChip = withStyles({
    root: {
      backgroundColor: '#FFC800',
      color: '#FFFF',
      '&$clickable': {
        backgroundColor: '#FFC800',
        color: '#FFFF'
      }
    },
    clickable: {},
  })((props) => <Chip color="default" {...props} />);

  const BlueChip = withStyles({
    root: {
      backgroundColor: blue[400],
      color: '#FFFF',
      '&$clickable': {
        backgroundColor: blue[600],
        color: '#FFFF'
      }
    },
    clickable: {},
  })((props) => <Chip color="default" {...props} />);

  const RedChip = withStyles({
    root: {
      backgroundColor: red[400],
      color: '#FFFF',
      '&$clickable': {
        backgroundColor: red[600],
        color: '#FFFF'
      }
    },
    clickable: {},
  })((props) => <Chip color="default" {...props} />);

  const clear = () => {
    setUbRepeater([]);
    getInfo('tiposllamada/Listado');
    setNuevo(false);
    setEditar(false);
    setSelectedColor(1);
    setSelectedArea(1);
    setStep(0);
    setRolesChecked([]);
    setNivel1Checked([]);
    setNivel2Checked([]);
    setNivel3Checked([]);
    setNewCallType({
      "tipoLlamada": -1,
      "color": 0,
      "areaAtencion": 1,
      "nombre": '',
      "esDowntime": false,
      "esSonora": false,
      "tiempoStdRespuesta": 0,
      "tiempoStdReparacion": 0,
      "tiempoNivel3": 0,
      "requiereClasificacion": false,
      "soloBeeper": false,
      "borrado": false,
      "llamadasUbicacion": [],
      "rolesLlamadas": [],
      "nivel1": [],
      "nivel2": [],
      "nivel3": []
    })
  }

  useEffect(() => {
    getInfo('MicroServiciosController?categoria=TiposUbicacion');
    getInfo(`MicroServiciosController?categoria=AreasAtencion`);
    getInfo('tiposllamada/CatalogoDepartamentos');
    getInfo('areas');
    getInfo('lineas');
    getInfo('celdas');
    getInfo('sites');
  }, []);

  useEffect(() => {
    const newData = { ...newCallType };
    newData.color = selectedColor;
    setNewCallType(newData);
  }, [selectedColor])

  const llamadasValues = useMemo(() => {
    return ({
      getInfo, areasAtencion, llamadas, step, setStep, snackbar, setSnackbar,
      tiposUbicacion, areas, celdas, lineas, snackbarText,
      deptos, selectedArea, setSelectedArea, selectedColor, setSelectedColor,
      handleInputChange, postCallType, sites, ubRepeater, setUbRepeater,
      nivel1Checked, setNivel1Checked, nivel2Checked, setNivel2Checked, 
      nivel3Checked, setNivel3Checked, rolesChecked, setRolesChecked,
      theCallType, isEdit, setIsEdit, getTheCallType, putCallType,
      nuevo, setNuevo, editar, setEditar, setNewCallType, clear,
      GreenChip, YellowChip, BlueChip, RedChip
    })
  }, [areas, llamadas, step, tiposUbicacion, sites, areas, celdas, lineas, deptos, rolesChecked,
    selectedArea, selectedColor, newCallType, ubRepeater, nivel1Checked, nivel2Checked, nivel3Checked,
    snackbar, snackbarText, isEdit, theCallType, nuevo, editar]);

  return <LlamadasContext.Provider value={llamadasValues} {...props} />;
}

export function useLlamadas() {
  const context = useContext(LlamadasContext);
  if (!context) {
    throw new Error('useLlamadas debe estar dentro del proveedor LlamadasContext');
  }
  return context;
}
