import React, { useEffect, useState } from 'react';
import '../styles/capacitiesDefault.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import TextField from '@material-ui/core/TextField';
import { Checkbox } from 'tabler-icons-react';
import ModalS from './ModalS';

const SimpleList = () => {
    const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));
    const [lines, setLines] = useState([]);
    const [linea, setLinea] = useState('');
    const [piezasPorPaquete, setPiezasPaquete] = useState('-');
    const [ritmoEstandar, setRitmoEstandar] = useState('-');
    const [ritmoIdeal, setRitmoIdeal] = useState('-');
    const [valorRollover, setValorRollover] = useState('-');
    const [nombreLinea, setNombreLinea] = useState('Sin seleccionar');
    const [verNombreLinea, setVerNombreLinea] = useState('Sin seleccionar');
    const [jsonStatus, setJsonStatus] = useState(400);
    const [openD, setOpenD] = React.useState(false);

    const getLines = async () => {
        const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/lineas`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
        });
        const lines = await data.json();
        setLines(lines);
    };

    /**
     * Función para asignar las capacidades de la linea seleccionada
     * @param {*} linea
     */
    const getCapacity = async (newLinea) => {
        await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/capacidadesdefault/GetCapacidadesDefault?linea=${newLinea}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                },
            }
        ).then(async (response) => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data1 = isJson && (await response.json());
            const { piezasPorPaquete, ritmoEstandar, ritmoIdeal, valorRollover, nombreLinea } =
                data1;
            if (!response.ok) {
                setLinea(newLinea);
                setNombreLinea(lines[newLinea - 1].nombre);
                setPiezasPaquete('0');
                setRitmoEstandar('0');
                setRitmoIdeal('0');
                setValorRollover('0');
                setJsonStatus(400);
                setVerNombreLinea('Sin seleccionar');
            } else {
                setLinea(newLinea);
                setPiezasPaquete(piezasPorPaquete);
                setRitmoEstandar(ritmoEstandar);
                setRitmoIdeal(ritmoIdeal);
                setValorRollover(valorRollover);
                setNombreLinea(nombreLinea);
                setJsonStatus(200);
                setVerNombreLinea(nombreLinea);
            }
        });
    };
    useEffect(() => {
        getLines();
    }, []);

    const LoadLineCapacity = (event) => {
        getCapacity(event);
    };
    const functionMas = (id, value) => {
        if (value !== '-') {
            value = parseInt(value) + 1;
            functionSwitch(id, value);
        }
    };
    const functionMenos = (id, value) => {
        value = parseInt(value);
        if (value >= 1) {
            value -= 1;
            functionSwitch(id, value);
        }
    };
    const functionSwitch = (id, value) => {
        switch (id) {
            case 'piezasPaquetes':
                setPiezasPaquete(value);
                break;
            case 'ritmoIdeal':
                setRitmoIdeal(value);
                break;
            case 'ritmoEstandar':
                setRitmoEstandar(value);
                break;
            default:
                break;
        }
    };

    const functionGuardar = async () => {
        const data = {
            celda: linea,
            piezasPorPaquete: piezasPorPaquete,
            ritmoIdeal: ritmoIdeal,
            ritmoEstandar: ritmoEstandar,
            valorRollover: parseInt(valorRollover),
            nombreLinea: nombreLinea,
        };

        if (jsonStatus === 200) {
            fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/capacidadesdefault/Actualizar`, {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(async (response) => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data1 = isJson && (await response.json());
                if (!response.ok) {
                    const error = (data1 && data1.message) || response.status;
                    return Promise.reject(error);
                } else {
                    setOpenD(true);
                }
            });
        } else if (jsonStatus === 400) {
            
            fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/capacidadesdefault/Insertar`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(async (response) => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data1 = isJson && (await response.json());
                if (!response.ok) {
                    const error = (data1 && data1.message) || response.status;
                    return Promise.reject(error);
                } else {
                    setOpenD(true);
                }
            });
        }
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={4} className="capacities-bg">
                    <h3 className="capacities-h3">Seleccionar línea</h3>
                    <List aria-labelledby="nested-list-subheader" className="pd-0">
                        {lines.map((item) => (
                            <ListItem
                                button
                                onClick={() => LoadLineCapacity(item.id)}
                                className="item-capacity"
                                key={item.id}
                            >
                                <ListItemText
                                    primary={item.nombre}
                                    className="item-capacity-text"
                                />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={3} className="capacities-bg">
                    <h3 className="capacities-h3">{verNombreLinea}</h3>
                    <p>PIEZAS POR PAQUETE</p>
                    <Grid item xs={12} className="contenedor-contador">
                        <Fab
                            className="btn-menos"
                            size="small"
                            onClick={() => functionMenos('piezasPaquetes', piezasPorPaquete)}
                        >
                            <RemoveIcon size={1} />
                        </Fab>
                        <TextField
                            disabled
                            className="textfield-value"
                            id="piezasPaquetes"
                            value={piezasPorPaquete}
                        />
                        <Fab
                            className="btn-menos"
                            onClick={() => functionMas('piezasPaquetes', piezasPorPaquete)}
                        >
                            <AddIcon />
                        </Fab>
                    </Grid>
                    <p>RITMO IDEAL</p>
                    <Grid item xs={12} className="contenedor-contador">
                        <Fab
                            className="btn-menos"
                            onClick={() => functionMenos('ritmoIdeal', ritmoIdeal)}
                        >
                            <RemoveIcon />
                        </Fab>
                        <TextField
                            disabled
                            className="textfield-value"
                            id="ritmoIdeal"
                            value={ritmoIdeal}
                        />
                        <Fab
                            className="btn-menos"
                            onClick={() => functionMas('ritmoIdeal', ritmoIdeal)}
                        >
                            <AddIcon />
                        </Fab>
                    </Grid>
                    <p className="second-text">Piezas / Minuto</p>
                    <p>RITMO ESTÁNDAR</p>
                    <Grid item xs={12} className="contenedor-contador">
                        <Fab
                            className="btn-menos"
                            onClick={() => functionMenos('ritmoEstandar', ritmoEstandar)}
                        >
                            <RemoveIcon />
                        </Fab>
                        <TextField
                            disabled
                            className="textfield-value"
                            id="ritmoEstandar"
                            value={ritmoEstandar}
                        />
                        <Fab
                            className="btn-menos"
                            onClick={() => functionMas('ritmoEstandar', ritmoEstandar)}
                        >
                            <AddIcon />
                        </Fab>
                    </Grid>
                    <p className="second-text">Piezas / Minuto</p>
                    <p>VALOR ROLLOVER</p>
                    <Grid item xs={12} className="contenedor-contador contenedor-contador-2">
                        <TextField value={valorRollover} />
                    </Grid>
                    <div className="button-footer">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="button"
                            onClick={() => functionGuardar()}
                        // fullWidth
                        >
                            Guardar
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <ModalS
                openM={openD}
                popupTitle={
                    <div className="icon-big">
                        <Checkbox size={120} color={'#7D7F90'} />
                    </div>
                }
                popupBody={
                    <div className="popup-delete1">
                        <div className="message">Capacidades actualizadas correctamente</div>

                        <div className="popupbpd13">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className="popup-button1"
                                onClick={(e) => setOpenD(false)}
                            >
                                Cerrar
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    );
};
export default SimpleList;
