import React, { useState } from 'react';
//Components
import PopUp from './PopUp';
import { DateRange } from 'react-date-range';
//Style
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
//Context
import { useReportes } from './ReportesContex';

let primColor = '';
if (process.env.REACT_APP_THEME === 'tecnoTheme') primColor = '#253139';
else if (process.env.REACT_APP_THEME === 'colTheme') {
    primColor = '#58ab01';
} else if (process.env.REACT_APP_THEME === 'matteTheme') {
    primColor = '#253139';
} else primColor = '#903BFF';

export default function DateRangePicker() {
    const { setFechaIni, setFechaFin, setOpen } = useReportes();
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);
    const changeRange = (selection) => {
        setRange(selection);
        setFechaIni(selection[0].startDate);
        setFechaFin(selection[0].endDate);
        if (selection[0].startDate !== selection[0].endDate) {
            setOpen(false);
        }
    };

    return (
        <PopUp
            name="nuevo"
            colorButton="default"
            buttonClass="listado-ordenes-button"
            popupSeconButton="Aceptar"
            popupsbclass="popupbp"
            popupTitle={<div></div>}
            popupBody={
                <DateRange
                    editableDateInputs={true}
                    onChange={(item) => changeRange([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={range}
                />
            }
        />
    );
}
