import React, { useState } from "react";
//Component
import PopUp from "./PopUp";
//Icons
import { CirclePlus } from 'tabler-icons-react';
import DoneIcon from '@material-ui/icons/Done';
import { User } from 'tabler-icons-react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
//Material-UI
import { Grid, TextField, Select, InputLabel, Chip, Button, MenuItem } from "@material-ui/core";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
//Context
import { useUsuarios } from "./UsuariosContext";

export default function NuevoUsuario() {
  const { roles, setRoles, ordenaArrObj, setNuevo,
    gpos, deptos, languages, registeruser,
    passError, validaPass } = useUsuarios();
  const [gpo, setGpo] = useState('');
  const [depto, setDepto] = useState('');
  const [language, setLanguage] = useState('');
  const [levels, setLevels] = useState({
    one: true,
    two: false,
    three: false,
  });

  const { one, two, three } = levels;

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMark = (event, name) => {
    event.preventDefault();
    let i = 0, color = 'primary';
    const newRoles = [...roles];
    roles.forEach(rol => {
      if (rol.nombre === name) {
        newRoles.splice(i, 1);
        if (rol.color === "primary")
          color = 'default';
        else
          color = 'primary';
        newRoles.push({
          nombre: rol.nombre,
          color: color
        });
      }
      i++;
    });
    newRoles.sort(ordenaArrObj);
    setRoles(newRoles);
  }
  const passChange = (e) => {
    handleChange('password')(e);
    let pass = e.target.value;
    validaPass(pass);
  }
  const lelvelsChange = (e) => {
    setLevels({ ...levels, [e.target.name]: e.target.checked });
  }
  const createUser = (e) => {
    e.preventDefault();
    let selectedRols = [];
    roles.forEach(rol => {
      if (rol.color === 'primary') {
        selectedRols.push(rol.nombre);
      }
    })
    const data = {
      "usuario": document.getElementById('uxTxtUsername').value,
      "password": document.getElementById('uxTxtPassword').value,
      "email": document.getElementById('uxTxtMail').value,
      "phoneNumber": document.getElementById('uxTxtPhoneNumber').value,
      "id": null,
      "nombre": document.getElementById('uxTxtName').value,
      "apellido": document.getElementById('uxTxtLastName').value,
      "cardId": null,
      "notas": document.getElementById('uxTxtNotes').value,
      "departamento": depto,
      "grupo": gpo,
      "esNivel1": one,
      "esNivel2": two,
      "esNivel3": three,
      "idioma": language,
      "horario": null,
      "roles": selectedRols
    }
    registeruser(data);
    setNuevo(false);
  }
  return (
    <PopUp
      name="nuevo"
      colorButton="primary"
      textButton={<div><CirclePlus size={18} className="icon" /> Nuevo usuario</div>}
      buttonClass="listado-ordenes-button"
      popupPrimButton="Crear nueva orden"
      popupSeconButton="Cancelar"
      popupsbclass="popupbp"
      popupTitle={
        <div className="popuphead">
          <h2 className="popuptitle">Nuevo Usuario</h2>
        </div>
      }
      popupBody={
        <div className="popupbody">
          <Grid container spacing={2}>
            <Grid item md={6}>
              <InputLabel htmlFor="standard-adornment-username">
                Nombre de usuario
              </InputLabel>
              <Input
                type="text"
                id="uxTxtUsername"
                value={values.username}
                endAdornment={
                  <InputAdornment position="end">
                    <User></User>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item md={6}>
              <InputLabel htmlFor="standard-adornment-password">
                Contraseña
              </InputLabel>
              <Input
                id="uxTxtPassword"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                error={passError}
                onChange={e => passChange(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size="small"
                    >
                      {values.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            {passError ? (
              <Grid item md={12}>
                <InputLabel style={{ fontSize: 11, color: 'red' }}>
                  La contraseña debe contener:
                  Al menos 6 caracteres,
                  al menos un carácter no alfanumérico,
                  al menos un número,
                  al menos una mayúscula y una minúscula.
                </InputLabel>
              </Grid>
            ) : (
              <Grid item md={12} style={{ textAlign: 'right' }}>
                <InputLabel style={{ fontSize: 11 }}>
                  Contraseña válida
                </InputLabel>
              </Grid>
            )}
            <Grid item md={6}>
              <TextField
                id="uxTxtName"
                label="NOMBRE"
                placeholder="Escribe el nombre"
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="uxTxtLastName"
                label="APELLIDOS"
                placeholder="Escribe los apellidos"
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <div>
                <InputLabel id="uxListGroup" fullWidth>GRUPO</InputLabel>
                <Select
                  labelId="uxListGroup"
                  fullWidth
                  value={gpo}
                  onChange={e => setGpo(e.target.value)}
                >
                  {gpos.map((item) => (
                    <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{ marginTop: 10 }}>
                <InputLabel id="uxListDepto" fullWidth>DEPARTAMENTO</InputLabel>
                <Select
                  labelId="uxListDepto"
                  fullWidth
                  value={depto}
                  onChange={e => setDepto(e.target.value)}
                >
                  {deptos.map((item) => (
                    <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{ marginTop: 10 }}>
                <InputLabel id="uxListLanguages" fullWidth>IDIOMAS</InputLabel>
                <Select
                  labelId="uxListLanguages"
                  fullWidth
                  value={language}
                  onChange={e => setLanguage(e.target.value)}
                >
                  {languages.map((item) => (
                    <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item md={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">NIVELES</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={one} onChange={lelvelsChange} name="one" />}
                    label="Nivel 1"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={two} onChange={lelvelsChange} name="two" />}
                    label="Nivel 2"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={three} onChange={lelvelsChange} name="three" />}
                    label="Nivel 3"
                  />
                </FormGroup>
                <FormHelperText>Puede marcar más de uno</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <TextField
                id="uxTxtNotes"
                label="NOTAS"
                placeholder="Escribe las notas"
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="uxTxtMail"
                label="CORREO"
                placeholder="Escribe el correo"
                type="mail"
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="uxTxtPhoneNumber"
                label="TELÉFONO"
                placeholder="Escribe el teléfono"
                fullWidth
              />
            </Grid>
          </Grid>
          <h4>Cargos que desempeña</h4>
          {roles.map((rol) => (
            <Chip label={rol.nombre} deleteIcon={<DoneIcon />} onDelete={event => handleMark(event, rol.nombre)} color={rol.color} />
          ))}
          <div id="popupbs">
            <Button
              color="primary"
              className="popup-button-p"
              variant="contained"
              onClick={e => createUser(e)}
            >
              Crear nuevo usuario
            </Button>
          </div>
        </div>
      }
    />
  );
}