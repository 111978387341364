import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import Login from './routes/login.js';
import { ThemeProvider } from '@material-ui/core/styles';
import { themeFactory } from '../src/themes';
import useToken from './useToken.js';
import Menu from './components/Menu/';
import MainView from './components/MainView';
import './App.css';
import { GeneralProvider } from './Context/GeneralContext.jsx';

export default function App() {
    const theme = themeFactory(process.env.REACT_APP_THEME);

    const { token, setToken } = useToken();

    if (!token) {
        return (
            <ThemeProvider theme={theme}>
                <GeneralProvider>
                    <Login setToken={setToken} />
                </GeneralProvider>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <GeneralProvider>
                <Router>
                    <div className="app-container">
                        <Menu />
                        <MainView />
                    </div>
                </Router>
            </GeneralProvider>
        </ThemeProvider>
    );
}
