import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import DateRangeIcon from '@material-ui/icons/DateRange';
import '../../App.css';
//Context
import { useReportes } from './ReportesContex';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        fontFamily: 'WorkSans',
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '0px 0px 0px 0px',
    },
}));

const useStyles1 = makeStyles((theme) => ({
    paper: {
        fontFamily: 'WorkSans',
        position: 'absolute',
        width: 1050,
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '0px 0px 0px 0px',
    },
}));

function PopUp(props) {
    const { open, setOpen } = useReportes();
    const classes = useStyles();
    const classes1 = useStyles1();
    const [modalStyle] = useState(getModalStyle);
    const selcClass = props.popupFlag ? classes1.paper : classes.paper;

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const body = (
        <div style={modalStyle} className={selcClass}>
            <div id="simple-modal-title">{props.popupTitle}</div>
            <div id="simple-modal-description">
                {props.popupBody}
            </div>
        </div>
    );

    return (
        <div>
            <IconButton
                onClick={handleOpen}
            >
                <DateRangeIcon />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}

PopUp.defaultProps = {
    textButton: 'Agregar',
    colorButton: 'primary',
    popupTitle: 'Titulo',
    popupPrimButton: 'Aceptar',
    popupSeconButton: 'Cancelar',
    popupsbclass: 'popupbp',
    isContained: 'contained',
    isActive: true,
};

export default PopUp;
