import React, { useState, useEffect } from 'react';
//Material-UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
//Styles
import '../../App.css';
//Context
import { useLlamadas } from './LlamadasContext';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    fontFamily: 'WorkSans',
    position: 'absolute',
    width: 650,
    height: 700,

    backgroundColor: theme.palette.background.paper,
    //border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: '0px 0px 0px 0px',
  },
}));

const useStyles1 = makeStyles((theme) => ({
  paper: {
    fontFamily: 'WorkSans',
    position: 'absolute',
    width: 1050,
    backgroundColor: theme.palette.background.paper,
    //border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: '0px 0px 0px 0px',
  },
}));

function PopUp(props) {
  const { setStep, nuevo, setNuevo, editar, setEditar, clear } = useLlamadas();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const classes1 = useStyles1();
  const [modalStyle] = React.useState(getModalStyle);
  const selcClass = props.popupFlag ? classes1.paper : classes.paper;

  useEffect(() => {
    switch(props.name){
      case 'nuevo':
        setOpen(nuevo);
      break;
      case 'editar':
        setOpen(editar);
      break;
      default:
      break;
    }
  }, [nuevo, editar]);
  
  const handleOpen = () => {
    switch(props.name){
      case 'nuevo':
        setNuevo(true);
      break;
      case 'editar':
        setEditar(true);
      break;
      default:
      break;
    }
  }

  const handleClose = () => {
    setStep(0);
    switch(props.name){
      case 'nuevo':
        setNuevo(false);
      break;
      case 'editar':
        setEditar(false);
      break;
      default:
      break;
    }
    clear();
  }

  const body = (
    <div style={modalStyle} className={selcClass}>
      <div id="simple-modal-title">
        {props.popupTitle}
      </div>
      <div id="simple-modal-description">
        {props.popupBody}
      </div>
    </div>
  );

  return (
    <div>
      <Button
        variant={props.isContained}
        color={props.colorButton}
        className={props.buttonClass}
        onClick={handleOpen}
        disabled={!props.isActive}
      >
        {props.textButton}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
    </div>
  )
}

PopUp.defaultProps = {
  textButton: "Agregar",
  colorButton: "primary",
  popupTitle: "Titulo",
  popupPrimButton: "Aceptar",
  popupSeconButton: "Cancelar",
  popupsbclass: "popupbp",
  isContained: "contained",
  isActive: true
}

export default PopUp;
