import PropTypes from 'prop-types';

// Material UI
import { Drawer as MUIDrawer } from '@material-ui/core';
import { styled } from '@material-ui/styles';

const drawerWidth = 292;
const closedDrawerWidth = 82;

let primColor,
    colorTextMenu = '';
if (process.env.REACT_APP_THEME === 'tecnoTheme') {
    primColor = '#3F4A51';
    colorTextMenu = '#FFFFFF';
} else if (process.env.REACT_APP_THEME === 'colTheme') {
    primColor = '#3F4A51';
    colorTextMenu = '#FFFFFF';
} else if (process.env.REACT_APP_THEME === 'matteTheme') {
    primColor = '#253139';
    colorTextMenu = '#FFFFFF';
} else if (process.env.REACT_APP_THEME === 'skyTheme') {
    primColor = '#001B69';
    colorTextMenu = '#FFFFFF';
} else {
    primColor = '#5E3F94';
    colorTextMenu = '#C8AFE8';
}

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    background: primColor,
    color: colorTextMenu,
    boxShadow: '2px 2px 20px #8930FD33',
});

const closedMixin = (theme) => ({
    width: closedDrawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    background: primColor,
    color: colorTextMenu,
    boxShadow: '2px 2px 20px #8930FD33',
});

const Drawer = styled(MUIDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    })
);

const StyledDrawer = ({ children, open }) => {
    return (
        <Drawer variant="permanent" open={open}>
            {children}
        </Drawer>
    );
};

StyledDrawer.propTypes = {
    open: PropTypes.bool,
};

export default StyledDrawer;
