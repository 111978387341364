import { set } from 'date-fns/esm';
import { createContext, useState, useMemo, useContext, useEffect } from 'react';

const ReportesContext = createContext();

export function ReportesProvider(props) {
    const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));
    const [selectedD, setSelectedD] = useState(new Date());
    const [fechaIni, setFechaIni] = useState(new Date());
    const [fechaFin, setFechaFin] = useState();
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [posIni, setPosIni] = useState(1);
    const [posFin, setPosFin] = useState(16);
    const [lines, setLines] = useState([]);
    const [skuOrder, setSkuOrder] = useState([]);
    const [corridas, setCorridas] = useState([]);
    const [listOrds, setListOrds] = useState([]);
    const [flag, setFlag] = useState(false);
    const [txtRange, setTxtRange] = useState('');
    const [open, setOpen] = useState(false);

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    const fillTxtRange = () => {
        if (fechaFin !== undefined) {
            setTxtRange(
                `${fechaIni.getDate()}/${
                    fechaIni.getMonth() + 1
                }/${fechaIni.getFullYear()}-${fechaFin.getDate()}/${
                    fechaFin.getMonth() + 1
                }/${fechaFin.getFullYear()}`
            );
        }
    };

    const getLines = async () => {
        const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/lineas`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
        });
        const lines = await data.json();
        if (lines.length !== 0) {
            setLines(lines);
        }
    };

    const transformDate = (base) => {
        let mes = '';
        if (base.getMonth() + 1 < 10) mes = `0${(base.getMonth() + 1).toString()}`;
        else mes = (base.getMonth() + 1).toString();
        let dia = '';
        if (base.getDate() < 10) dia = `0${base.getDate().toString()}`;
        else dia = base.getDate().toString();

        return `${base.getFullYear()}-${mes}-${dia}`;
    };

    const setWeek = async (selectedDate) => {
        setSelectedD(selectedDate);
        const inicio = new Date(selectedDate);
        inicio.setDate(selectedDate.getDate() - selectedDate.getDay() - 15);
        setFechaIni(inicio);
        const fin = new Date(selectedDate);
        // fin.setDate(fin.getDate() + 6);
        setFechaFin(fin);
    };

    const getReports = async () => {
        const inicio = transformDate(fechaIni);
        const fin = transformDate(fechaFin);

        const listOrders = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Ordenes/ListadoOrdenesFecha?start=${inicio}&end=${fin}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                },
            }
        );
        const ordersJS = await listOrders.json();
        const data = [];
        ordersJS.forEach((item) => {
            const point = {
                x: item.ordenProd,
                y: item.producido,
                goals: [
                    {
                        name: 'Plan',
                        value: item.cantidad,
                        strokeWidth: 20,
                        strokeHeight: 0,
                        strokeLineCap: 'round',
                        strokeColor: '#775DD0',
                    },
                ],
            };
            data.push(point);
        });
        setListOrds(data);
        getRepoSku();
        handleClickF();
    };

    const getCorridas = async () => {
        const inicio = transformDate(fechaIni);
        const fin = transformDate(fechaFin);
        const data = {
            fechaIni: inicio,
            fechaFin: fin,
        };

        const corri = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Estadisticas/ReporteCorridas`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );
        const respuesta = await corri.json();
        if (corri.status === 200) {
            let data = [];
            respuesta.forEach((item, index) => {
                const data1 = {
                    corrida: item.corrida,
                    producido: item.producido,
                    piezasMalas: item.piezasMalas,
                    sku: item.sku,
                    ordenProd: item.ordenProd,
                    fechaIniReal: item.fechaIniReal.slice(0, 10),
                    fechaFinReal: item.fechaFinReal.slice(0, 10),
                    id: index,
                };
                data.push(data1);
            });
            setCorridas(data);
        }
    };

    const getRepoSku = async () => {
        const inicio = transformDate(fechaIni);
        const fin = transformDate(fechaFin);

        const skuOrders = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Report/sku?fechaIni=${inicio}&fechaFin=${fin}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                },
            }
        );
        const repoSku = await skuOrders.json();
        const data = [];
        repoSku.forEach((item, index) => {
            const data1 = {
                cantidad: item.cantidad,
                estatus: item.estatus,
                fecha: item.fecha.slice(0, 10),
                ordenProd: item.ordenProd,
                producido: item.producido,
                sku: item.sku,
                cliente: item.cliente,
                id: index,
            };
            data.push(data1);
        });

        setSkuOrder(data);
    };

    const handleClickF = () => {
        const diaIni = parseInt(fechaIni.toISOString().slice(8, 10));
        const diaFin = parseInt(fechaFin.toISOString().slice(8, 10));

        if (diaIni <= 10) {
            setPosIni((fechaIni.getMonth() + 1) * 3 - 3);
        } else if (diaIni <= 20) {
            setPosIni((fechaIni.getMonth() + 1) * 3 - 2);
        } else {
            setPosIni((fechaIni.getMonth() + 1) * 3 - 1);
        }

        if (diaFin <= 10) {
            setPosFin((fechaFin.getMonth() + 1) * 3 - 2);
        } else if (diaFin <= 20) {
            setPosFin((fechaFin.getMonth() + 1) * 3 - 1);
        } else {
            setPosFin((fechaFin.getMonth() + 1) * 3);
        }
    };

    useEffect(() => {
        setWeek(new Date());
        getLines();
    }, []);

    useEffect(() => {
        if (flag === false && fechaFin !== undefined) {
            fillTxtRange();
            getReports();
            getCorridas();

            setFlag(true);
        } else {
            fillTxtRange();
        }
    }, [fechaFin]);

    const reportesValues = useMemo(() => {
        return {
            selectedD,
            setSelectedD,
            selectedIndex,
            setSelectedIndex,
            lines,
            listOrds,
            handleClickF,
            setWeek,
            txtRange,
            fechaIni,
            setFechaIni,
            fechaFin,
            setFechaFin,
            getReports,
            transformDate,
            posIni,
            posFin,
            open,
            setOpen,
            skuOrder,
            getCorridas,
            corridas,
        };
    }, [
        selectedD,
        setSelectedD,
        selectedIndex,
        setSelectedIndex,
        lines,
        listOrds,
        handleClickF,
        setWeek,
        txtRange,
        fechaIni,
        setFechaIni,
        fechaFin,
        setFechaFin,
        getReports,
        transformDate,
        posIni,
        posFin,
        open,
        setOpen,
        skuOrder,
        getCorridas,
        corridas,
    ]);

    return <ReportesContext.Provider value={reportesValues} {...props} />;
}

export function useReportes() {
    const context = useContext(ReportesContext);
    if (!context) {
        throw new Error('useFallas debe estar dentro del proveedor ReportesContext');
    }
    return context;
}
