import React, { useState, useEffect } from 'react';

//Material UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';

import { useReportes } from './ReportesContex';

export default function Menu() {
    const { selectedIndex, setSelectedIndex } = useReportes();

    const handleListItemClick = (event, index) => {
        event.preventDefault();
        setSelectedIndex(index);
    };

    return (
        <div>
            <List component="nav">
                <ListItem
                    button
                    selected={selectedIndex === 1}
                    onClick={(event) => handleListItemClick(event, 1)}
                >
                    <ListItemText primary="Informe de producción" />
                </ListItem>
                <ListItem
                    button
                    selected={selectedIndex === 2}
                    onClick={(event) => handleListItemClick(event, 2)}
                >
                    <ListItemText primary="Informe Corridas" />
                </ListItem>
                {/* <ListItem
                    button
                    selected={selectedIndex === 3}
                    onClick={(event) => handleListItemClick(event, 3)}
                >
                    <ListItemText primary="informe 2" />
                </ListItem> */}
            </List>
        </div>
    );
}
