import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
//Material-UI
import { TextField, IconButton, ListItemText } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { 
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  esES,
} from '@material-ui/data-grid';
//Icons
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
//Context
import { useUsuarios } from "./UsuariosContext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    backgroundColor: '#F2F4F8',
  },
  divider: {
    margin: 4,
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    margin: theme.spacing(1, 0.5, 1.5),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    width: '99%',
  },
}));

//BEGIN SEARCH TOOLBAR
function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
  const classes = useStyles();
  return(
    <div>
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <TextField
        variant="standard"
        fullWidth
        value={props.value}
        onChange={props.onChange}
        placeholder="Buscar..."
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon size="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon size="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  )
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

//END SEARCH TOOLBAR

function TablaUsuarios () {
  const { usuarios, setIsEdit, setIsDel, getTheUser,
          selectionModel, setSelectionModel } = useUsuarios();
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState(usuarios);

  function renderDual(params){
    let first = '';
    let second = '';

    if(params.value !== null){
      const substrings = params.value.split(', ');
      if(substrings.length > 0){
        first = substrings[0];
      }
      if(substrings.length > 1){
        second = substrings[1];
      }
    }

    return <ListItemText primary={first} secondary={second} />
  }

  const tableColumns = [
    { field: 'identity', headerName: 'Nombre y Usuario', renderCell: renderDual, width: 250 },
    { field: 'contact', headerName: 'Contacto', renderCell : renderDual, width: 250 },
    { field: 'strRoles', headerName: 'Roles', width: 250 },
    { field: 'enabled', headerName: 'Habilitado', type: 'boolean', width: 150 }
  ];

  //BEGIN SEARCH TOOLBAR
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = usuarios.filter((row)=>{
      return Object.keys(row).some((field) => {
        if(row[field] !== null){
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setRows(filteredRows);
  };

  useEffect(() => {
    setRows(usuarios);
  }, [usuarios]);
  //END SEARCH TOOLBAR
  
  return (
    <DataGrid 
      components={{ Toolbar: QuickSearchToolbar }}
      componentsProps={{
        toolbar: {
          value: searchText,
          onChange: (event) => requestSearch(event.target.value),
          clearSearch: () => requestSearch(''),
        },
      }}
      disableColumnFilter
      disableDensitySelector
      checkboxSelection 
      columns={tableColumns}
      localeText={
        esES.props
            .MuiDataGrid
            .localeText
      }
      rows={rows} 
      onSelectionModelChange={async (newSelectionModel) => {
        if(newSelectionModel.length > 0){
          if(newSelectionModel.length > 1)
            setIsEdit(false);
          else
            setIsEdit(true);
          setIsDel(true);
          getTheUser(newSelectionModel[0]);
        }
        else{
          setIsDel(false);
          setIsEdit(false);
        }
        setSelectionModel(newSelectionModel);
      }}
      selectionModel={selectionModel}
      hideFooterSelectedRowCount={true}
    />
  )
}

export default TablaUsuarios;