import React from "react";
//Material-UI
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Checkbox,
  makeStyles
} from '@material-ui/core';
//Context
import { useLlamadas } from "./LlamadasContext";

const useStyles = makeStyles(() => ({
  root: {
    width: 580,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 448,
  },
}));

export default function LevantarNotificar() {
  const classes = useStyles();
  const { deptos,  nivel1Checked, setNivel1Checked, nivel2Checked, setNivel2Checked, 
    nivel3Checked, setNivel3Checked, rolesChecked, setRolesChecked, } = useLlamadas();

  const handleRolesToggle = (value) => () => {
    const currentIndex = rolesChecked.indexOf(value);
    const newChecked = [...rolesChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setRolesChecked(newChecked);
  };

  const handleNivel1Toggle = (value) => () => {
    const currentIndex = nivel1Checked.indexOf(value);
    const newChecked = [...nivel1Checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setNivel1Checked(newChecked);
  };

  const handleNivel2Toggle = (value) => () => {
    const currentIndex = nivel2Checked.indexOf(value);
    const newChecked = [...nivel2Checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setNivel2Checked(newChecked);
  };

  const handleNivel3Toggle = (value) => () => {
    const currentIndex = nivel3Checked.indexOf(value);
    const newChecked = [...nivel3Checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setNivel3Checked(newChecked);
  };

  return (
    <div className="popupbody fijo">
      <div className={classes.root}>
        <h3 className="list-header capacidades">¿Quién puede levantar la llamada?</h3>
        <List>
          {deptos.map((item) => {
            const labelId = `checkbox-list-secondary-label-${item.codigo}`;
            return (
              <ListItem key={item.codigo}>
                <ListItemText primary={item.ubicacion} />
                <ListItemSecondaryAction>
                  <ListItemIcon>
                    <Checkbox
                      edge="end"
                      color="primary"
                      onChange={handleRolesToggle(item.codigo)}
                      checked={rolesChecked.indexOf(item.codigo) !== -1}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <h4>¿Cuándo es una llamada nivel 1 a quién se debe notificar?</h4>
        <List>
          {deptos.map((item) => {
            const labelId = `checkbox-list-secondary-label-${item.codigo}`;
            return (
              <ListItem key={item.codigo}>
                <ListItemText primary={item.ubicacion} />
                <ListItemSecondaryAction>
                  <ListItemIcon>
                    <Checkbox
                      edge="end"
                      color="primary"
                      onChange={handleNivel1Toggle(item.codigo)}
                      checked={nivel1Checked.indexOf(item.codigo) !== -1}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <h4>¿Cuándo es una llamada nivel 2 a quién se debe notificar?</h4>
        <List>
          {deptos.map((item) => {
            const labelId = `checkbox-list-secondary-label-${item.codigo}`;
            return (
              <ListItem key={item.codigo}>
                <ListItemText primary={item.ubicacion} />
                <ListItemSecondaryAction>
                  <ListItemIcon>
                    <Checkbox
                      edge="end"
                      color="primary"
                      onChange={handleNivel2Toggle(item.codigo)}
                      checked={nivel2Checked.indexOf(item.codigo) !== -1}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <h4>¿Cuándo es una llamada nivel 3 a quién se debe notificar?</h4>
        <List>
          {deptos.map((item) => {
            const labelId = `checkbox-list-secondary-label-${item.codigo}`;
            return (
              <ListItem key={item.codigo}>
                <ListItemText primary={item.ubicacion} />
                <ListItemSecondaryAction>
                  <ListItemIcon>
                    <Checkbox
                      edge="end"
                      color="primary"
                      onChange={handleNivel3Toggle(item.codigo)}
                      checked={nivel3Checked.indexOf(item.codigo) !== -1}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </div>
    </div>
  )
}