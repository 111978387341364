import React from "react";
//Componentes
import PopUp from './PopUp';
//Material-UI
import { TextField, Button } from "@material-ui/core";
//Context
import { useBeepers } from "./BeepersContext";

export default function EditarComentario() {
  const { comentario, putBeeper, setEditar, theBeeper } = useBeepers();

  const editBeeper = (event) => {
    event.preventDefault();
    const data = {
      "beeper": theBeeper.beeper1,
      "areaAtencion": theBeeper.areaAtencion,
      "nivel1": theBeeper.nivel1,
      "nivel2": theBeeper.nivel2,
      "nivel3": theBeeper.nivel3,
      "usarSonido": theBeeper.usarSonido,
      "comentario": document.getElementById('uxTxtComment').value,
    };
    putBeeper(data);
    setEditar(false);
  }

  return (
    <PopUp
      name="editar"
      colorButton="default"
      buttonClass="actions-edit"
      popupSeconButton="Cancelar"
      popupsbclass="popupbp"
      isContained="contained"
      popupTitle={
        <div className="popuphead">
          <h2 className="popuptitle-nodesc">Editar comentario</h2>
        </div>
      }
      popupBody={
        <div className="popupbody">
          <div style={{ marginBottom: 20 }}>
            <TextField
              id="uxTxtComment"
              label="Comentario"
              multiline
              rows={4}
              defaultValue={comentario}
              fullWidth
              variant="outlined"
            />
          </div>
          <div id="popupbs">
            <Button
              color="primary"
              className="popup-button-p"
              variant="contained"
              onClick={e => editBeeper(e)}
            >
              Guardar
            </Button>
          </div>
        </div>
      }
    />
  );
}