import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { TextField, IconButton } from '@material-ui/core';
import { DataGrid, esES } from '@mui/x-data-grid';
//Icnos
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
//Context
import { useBeepers } from './BeepersContext';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        backgroundColor: '#F2F4F8',
    },
    divider: {
        height: 28,
        margin: 4,
    },
    textField: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        width: '99%',
    },
    table: {
        width: '100%',
        minWidth: 660,
        marginBottom: 40,
    },
}));

//BEGIN SEARCH TOOLBAR
function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
    const classes = useStyles();
    return (
        <div>
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Buscar..."
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon size="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon size="small" />
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

//END SEARCH TOOLBAR

export default function Table() {
    const {
        beepers,
        getTheBeeper,
        setTheBeeper,
        setIsOptionsDisabled,
        selectionModel,
        setSelectionModel,
        putBeeper,
    } = useBeepers();
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);

    const tableColumns = [
        { field: 'beeper1', headerName: 'Beeper', width: 135 },
        {
            field: 'nombreAreaAtencion',
            headerName: 'Area Atención',
            width: 200,
        },
        { field: 'nivel1', headerName: 'Nivel 1', type: 'boolean', width: 120, editable: true },
        { field: 'nivel2', headerName: 'Nivel 2', type: 'boolean', width: 120, editable: true },
        { field: 'nivel3', headerName: 'Nivel 3', type: 'boolean', width: 120, editable: true },
    ];

    //BEGIN SEARCH TOOLBAR
    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = beepers.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
    };

    const handleCommit = (edit) => {
        let data = {
            beeper: 0,
            areaAtencion: 0,
            nivel1: true,
            nivel2: true,
            nivel3: true,
            usarSonido: true,
            comentario: 'string',
        };
        let filteredRows = rows.filter((row) => row.id === edit.id);

        if (edit.field === 'nivel1') {
            data = {
                beeper: filteredRows[0].id,
                areaAtencion: filteredRows[0].areaAtencion,
                nivel1: edit.value,
                nivel2: filteredRows[0].nivel2,
                nivel3: filteredRows[0].nivel3,
                usarSonido: filteredRows[0].usarSonido,
                comentario: filteredRows[0].comentario,
            };
        } else if (edit.field === 'nivel2') {
            data = {
                beeper: filteredRows[0].id,
                areaAtencion: filteredRows[0].areaAtencion,
                nivel1: filteredRows[0].nivel1,
                nivel2: edit.value,
                nivel3: filteredRows[0].nivel3,
                usarSonido: filteredRows[0].usarSonido,
                comentario: filteredRows[0].comentario,
            };
        } else {
            data = {
                beeper: filteredRows[0].id,
                areaAtencion: filteredRows[0].areaAtencion,
                nivel1: filteredRows[0].nivel1,
                nivel2: filteredRows[0].nivel2,
                nivel3: edit.value,
                usarSonido: filteredRows[0].usarSonido,
                comentario: filteredRows[0].comentario,
            };
        }

        putBeeper(data);
    };

    useEffect(() => {
        setRows(beepers);
    }, [beepers]);
    //END SEARCH TOOLBAR

    return (
        <DataGrid
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
                toolbar: {
                    value: searchText,
                    onChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch(''),
                },
            }}
            onCellEditCommit={handleCommit}
            columns={tableColumns}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            onSelectionModelChange={(newSelectionModel) => {
                let ubi;
                rows.map((row, indice) => {
                    if (row.beeper1 === newSelectionModel[0]) {
                        ubi = indice;
                    }
                });

                if (newSelectionModel.length > 0) {
                    getTheBeeper(newSelectionModel[0]);
                    setIsOptionsDisabled(false);
                } else
                    setTheBeeper({
                        beeper1: 0,
                        areaAtencion: 0,
                        nivel1: false,
                        nivel2: false,
                        nivel3: false,
                        usarSonido: false,
                        comentario: '',
                    });
                setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            hideFooterSelectedRowCount={true}
        />
    );
}
