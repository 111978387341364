import React from 'react';
//Material-UI
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//Icons
import { CirclePlus } from 'tabler-icons-react';
import { Trash } from 'tabler-icons-react';
//Components
import Table from './Table';
import Comentarios from './Comentarios';
import NuevoBeeper from './NuevoBeeper';
import BorrarBeeper from './BorrarBeeper';
//Material-UI
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//Context
import { BeepersProvider, useBeepers } from './BeepersContext';

const useStyles = makeStyles({
    icon: {
        position: 'relative',
        top: -0.5,
        right: 10,
    },
    borrar: {
        width: 116,
        height: 44,
        textTransform: 'initial',
        left: 30,
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Beepers() {
    const { setNuevo, isOptionsDisabled, setBorrarBeeper, snackMsg, openSnack, setOpenSnack } =
        useBeepers();
    const classes = useStyles();

    const abrirNuevo = (event) => {
        event.preventDefault();
        setNuevo(true);
    };

    const abrirDelBeeper = (event) => {
        event.preventDefault();
        setBorrarBeeper(true);
    };

    const handleClose = () => {
        setOpenSnack(false);
    };

    return (
        <Grid container direction="column" className="doc">
            <Grid item className="head">
                <Grid container alignItems="center">
                    <Grid item md={8}>
                        <h1 className="titulo">Beepers</h1>
                        <p className="desc">
                            Permite visualizar los beepers que se encuentran asignados a cada área
                            de atención.
                        </p>
                    </Grid>
                    <Grid item md={2} style={{ textAlign: 'center' }}>
                        <Button
                            color="default"
                            variant="contained"
                            className={classes.borrar}
                            disabled={isOptionsDisabled}
                            onClick={(e) => abrirDelBeeper(e)}
                        >
                            <Trash size={18} className={classes.icon} /> Borrar
                        </Button>
                        <BorrarBeeper />
                    </Grid>
                    <Grid item md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            className="listado-ordenes-button"
                            onClick={abrirNuevo}
                        >
                            <CirclePlus size={18} className={classes.icon} /> Añadir Beeper
                        </Button>
                        <NuevoBeeper />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className="body">
                <Grid container spacing={5}>
                    <Grid item md={7}>
                        <div style={{ height: '100%', minHeight: 562 }}>
                            <Table />
                        </div>
                    </Grid>

                    <Grid item md={5}>
                        <Comentarios />
                    </Grid>
                </Grid>
                <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        {snackMsg}
                    </Alert>
                </Snackbar>
            </Grid>
        </Grid>
    );
}

export default () => (
    <BeepersProvider>
        <Beepers></Beepers>
    </BeepersProvider>
);
