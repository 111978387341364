import React, { useState } from 'react';
//Components
import PopUp from './popup';
//Material-UI
import { Grid, TextField, IconButton, Button } from '@material-ui/core';
//Icons
import { FileUpload } from 'tabler-icons-react';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
//Context
import { useOrdProd } from './OrdProdContext';

export default function Importar() {
    const { setName, name, setSelectedFile, importOrders, setImportOrd, setOpenSnack } =
        useOrdProd();
    const [thereIsFile, setThereIsFile] = useState(false);

    const onFileChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            setSelectedFile(files[0]);
            setName(files[0].name);
            setThereIsFile(true);
        } else setName('NINGÚN ARCHIVO SELECCIONADO');
    };

    const importOrd = (event) => {
        event.preventDefault();

        importOrders();
        setImportOrd(false);
        setOpenSnack(true);
    };

    return (
        <PopUp
            name="import"
            colorButton="primary"
            textButton={
                <div>
                    <FileUpload size={18} className="icon" /> Importar Órdenes
                </div>
            }
            buttonClass="listado-ordenes-button"
            popupSeconButton="Cancelar"
            popupsbclass="popupbp"
            popupTitle={
                <div className="popuphead">
                    <h2 className="popuptitle">Carpeta de Importación</h2>
                </div>
            }
            popupBody={
                <div className="popupbody">
                    <p>
                        Por favor especifique la ruta del archivo desde la que el sistema leerá los
                        archivos provenientes del ERP con la información de órdenes de producción.
                    </p>
                    <div className="popuptextfield">
                        <input
                            type="file"
                            id="icon-button-file"
                            accept=".xlsx"
                            style={{ display: 'none' }}
                            onChange={(event) => onFileChange(event)}
                        />
                        <label htmlFor="icon-button-file">
                            <Grid container alignItems="center">
                                <Grid item md={10}>
                                    <TextField
                                        id="standard-read-only-input"
                                        label="Ruta de importación del archivo"
                                        defaultValue={name}
                                        value={name}
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item md={2}>
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="span"
                                    >
                                        <FolderOpenIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </label>
                    </div>

                    <div id="popupbs">
                        <Button
                            color="primary"
                            variant="contained"
                            className="popup-button-p"
                            disabled={!thereIsFile}
                            onClick={(event) => importOrd(event)}
                        >
                            Elegir archivo
                        </Button>
                    </div>
                </div>
            }
        />
    );
}
