import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider, KeyboardDatePicker
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import {
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  esES
} from '@material-ui/data-grid';
//Icnos
import { CirclePlus } from 'tabler-icons-react';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
//Styles
import '../../styles/parametros.css';

function getMonth(number) {
  let month = "";
  switch (number) {
    case "01":
      month = "Enero";
      break;
    case "02":
      month = "Febrero";
      break;
    case "03":
      month = "Marzo";
      break;
    case "04":
      month = "Abril";
      break;
    case "05":
      month = "Mayo";
      break;
    case "06":
      month = "Junio";
      break;
    case "07":
      month = "Julio";
      break;
    case "08":
      month = "Agosto";
      break;
    case "09":
      month = "Septiembre";
      break;
    case "10":
      month = "Octubre";
      break;
    case "11":
      month = "Noviembre";
      break;
    case "12":
      month = "Diciembre";
      break;
    default:
      month = "N/A";
      break;
  }
  return month;
}

const tableColumns = [
  { field: 'nombre', headerName: 'Nombre', width: 350 },
  { field: 'fecha', headerName: 'Fecha', width: 250 },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    backgroundColor: '#F2F4F8',
  },
  divider: {
    height: 28,
    margin: 4,
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    margin: theme.spacing(1, 0.5, 1.5),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    width: '99%',
  },
  table: {
    width: '100%',
    minWidth: 660,
    marginBottom: 40
  }
}));

//BEGIN SEARCH TOOLBAR
function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
  const classes = useStyles();
  return (
    <div>
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Buscar..."
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon size="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon size="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  )
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

//END SEARCH TOOLBAR

export default function Holidays() {
  const classes = useStyles();
  const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));
  const [selectionModel, setSelectionModel] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [holidays, setHolidays] = useState([]);
  const [rows, setRows] = useState(holidays);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = holidays.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  const getHolidays = async () => {
    const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/holidays`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
      },
    })
    const holidaysJS = await data.json();

    let cont = 0;
    let array = [];
    holidaysJS.forEach(element => {
      let fecha = element.fecha;
      let separador = fecha.split("-");
      fecha = `${separador[2][0] + separador[2][1]} de ${getMonth(separador[1])}`;
      const obj = {
        'id': cont,
        'nombre': element.nombre,
        'fecha': fecha,
      };
      array.push(obj);
      cont++;
    });
    setHolidays(array);
  }

  const postHolidays = (data) => {
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/holidays`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(res => res.json())
      .catch(error => console.error('Error: ', error))
      .then(window.location.replace('')); //refrescamos la página
  }

  useEffect(() => {
    getHolidays();
  }, []);

  useEffect(() => {
    setRows(holidays);
  }, [holidays]);

  const createHoliday = (event) => {
    event.preventDefault();
    const strNombre = document.getElementById('name').value;
    if (strNombre !== "") {
      let month = (parseInt(selectedDate.getMonth().toString()) + 1).toString();
      if (parseInt(month) < 10) {
        month = `0${month}`;
      }
      let day = selectedDate.getDate().toString();
      if (parseInt(day) < 10) {
        day = `0${day}`;
      }
      const obj = {
        "nombre": strNombre,
        "descripcion": "Sin descripcion",
        "fecha": `2021-${month}-${day}T17:45:14.517`
      }
      postHolidays(obj);
    }
  }
  return (
    <Grid container className="doc" >
      <Grid item md={12} className="head">
        <h1 className="titulo">Días festivos</h1>
        <p className="desc">Selecciona cuales días festivos aplican en la compañía</p>
      </Grid>
      <Grid item lg={12} className="body">
        <div className="new-hd">
          <Grid container spacing={2}>
            <Grid item lg={4} md={4}>
              <TextField
                id="name"
                label="NOMBRE"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item lg={5} md={4} className="hd-date">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="date"
                  disableToolbar
                  fullWidth
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  label="FECHA"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item lg={3} md={4} className="btn">
              <Button
                variant="contained"
                color="primary"
                className="btn-new"
                fullWidth
                onClick={createHoliday}
              >
                <CirclePlus size={18} className="add-icon" />
                Agregar día festivo
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={classes.table}>
          <DataGrid
            autoHeight
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(''),
              },
            }}
            disableColumnFilter
            disableDensitySelector
            checkboxSelection
            columns={tableColumns}
            localeText={
              esES.props
                  .MuiDataGrid
                  .localeText
            }
            rows={rows}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            hideFooterSelectedRowCount={true}
          />
        </div>
      </Grid>
    </Grid>
  )
}