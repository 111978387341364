import React, { useState, useEffect } from "react";
//Material-UI
import { Grid, TextField, IconButton } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
//Icons
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//Context
import { useLlamadas } from "./LlamadasContext";

export default function Ubicacion() {
  const { tiposUbicacion, sites, areas, lineas, celdas, ubRepeater, setUbRepeater } = useLlamadas();
  const [ubicaciones, setUbicaciones] = useState([]);
  const [tipoUb, setTipoUb] = useState('0');
  const [ubicacion, setUbicacion] = useState(0);
  const [hasTipo, setHasTipo] = useState(false);
  const [ubNombre, setUbNombre] = useState(null);
  const [tpUbNombre, setTpUbNombre] = useState(tiposUbicacion[0].nombre);

  const newUb = (event, id) => {
    event.preventDefault();

    const newRepeater = [...ubRepeater];
    const ub = {
      tipoUbicacion: tipoUb,
      ubicacion: ubicacion,
      tpUbNombre: tpUbNombre,
      ubNombre: ubNombre
    }
    newRepeater.push(ub);
    setUbRepeater(newRepeater);
  }

  const delUb = (event, value) => {
    event.preventDefault();
    const newRepeater = [...ubRepeater];
    newRepeater.pop(value);
    setUbRepeater(newRepeater);
  }

  const handleTipoUbChange = (event) => {
    event.preventDefault();
    setTipoUb(event.target.value);
    setTpUbNombre(tiposUbicacion[event.target.value].nombre);
    if(event.target.value === '0')
      setUbNombre(null);
  }

  const handleUbChange = (event) => {
    event.preventDefault();
    setUbicacion(event.target.value);
    setUbNombre(ubicaciones[event.target.value - 1].nombre);
  }

  useEffect(() => {
    switch (tipoUb) {
      case '0':
        setHasTipo(false);
        setUbicaciones([]);
        setUbicacion(0);
        break;
      case '1':
        setHasTipo(true);
        setUbicaciones(sites);
        break;
      case '2':
        setHasTipo(true);
        setUbicaciones(areas);
        break;
      case '3':
        setHasTipo(true);
        setUbicaciones(lineas);
        break;
      case '4':
        setHasTipo(true)
        setUbicaciones(celdas);
        break;
      default:
        break;
    }
  }, [tipoUb]);

  return (
    <div>
      {ubRepeater.map(item => {
        return (
          <Grid container spacing={3} key={item.id}>
            <Grid item sm={5}>
              <TextField
                label="TIPO DE UBICACIÓN"
                value={ item.tpUbNombre }
                disabled
                fullWidth />
            </Grid>
            <Grid item sm={5}>
              {(item.tipoUbicacion === '0') ? 
                <p></p> :
                 <TextField
                    label="UBICACIÓN"
                    value={ item.ubNombre }
                    disabled
                    fullWidth />}
            </Grid>
            <Grid item sm={2}>
              <IconButton color="primary" onClick={event => delUb(event, item.id)}>
                <DeleteOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        )
      })}
      <Grid container spacing={3}>
        <Grid item sm={5}>
          <InputLabel shrink id="lblTipoUb" fullWidth>
            TIPO DE UBICACION
          </InputLabel>
          <Select
            labelId="lblTipoUb"
            id="uxTxtTipoUb"
            onChange={handleTipoUbChange}
            displayEmpty
            value={tipoUb}
            fullWidth>
            {tiposUbicacion.map(item => (
              <MenuItem
                key={item.codigo.toString()}
                value={item.codigo.toString()}>
                {item.nombre.toString()}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item sm={5}>
          {hasTipo ?
            <div>
              <InputLabel shrink id="lblUb" fullWidth>
                UBICACION
              </InputLabel>
              <Select
                labelId="lblUb"
                id="uxTxtUb"
                onChange={handleUbChange}
                displayEmpty
                value={ubicacion}
                fullWidth>
                {ubicaciones.map(item => (
                  <MenuItem
                    key={item.codigo.toString()}
                    value={item.codigo.toString()}>
                    {item.nombre.toString()}
                  </MenuItem>
                ))}
              </Select>
            </div>
            : <p></p>
          }
        </Grid>
        <Grid item sm={2}>
          <IconButton color="primary" onClick={event => newUb(event)}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )
}