import React from 'react';
//Components
import PopUpS from './popupsmall';
//Material-UI
import { Button } from '@material-ui/core';
//Icons
import { Trash } from 'tabler-icons-react';
//Context
import { useOrdProd } from './OrdProdContext';

export default function BorrarOrd() {
    const { delOrd, selectionModel, isDel, setBorrar } = useOrdProd();

    const delOrds = (event) => {
        event.preventDefault();
        const lon = selectionModel.length;

        selectionModel.map((item, pos) => {
            delOrd(item, lon - (pos + 1));
        });
        setBorrar(false);
    };

    return (
        <PopUpS
            colorButton="default"
            buttonClass="actions-del"
            textButton={
                <div>
                    <Trash size={20} className="icon" /> Borrar
                </div>
            }
            popupSeconButton="Cancelar"
            popupsbclass="btn-del-cnl-orderList"
            isContained="text"
            isActive={isDel}
            popupTitle={
                <div className="icon-big">
                    <Trash size={120} color={'#7D7F90'} />
                </div>
            }
            popupBody={
                <div className="popup-delete">
                    <div className="message">
                        ¿Está Seguro Que Desea Eliminar Las Ordenes Seleccionadas?
                    </div>
                    <div className="popups-button-orderList">
                        <Button
                            color="primary"
                            variant="contained"
                            className="btn-del"
                            onClick={(event) => delOrds(event)}
                        >
                            Borrar
                        </Button>
                    </div>
                </div>
            }
        />
    );
}
