import { createContext, useState, useEffect, useMemo, useContext } from 'react';

const OrdProdContext = createContext();

export function OrdProdProvider(props) {
    const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));
    const [selectionModel, setSelectionModel] = useState([]);
    const [orden, setOrden] = useState([]);
    const [theOrder, setTheOrder] = useState();
    const [product, setProduct] = useState([]);
    const [actions, setActions] = useState(false);
    const [fechaIni, setFechaIni] = useState(new Date());
    const [fechaFin, setFechaFin] = useState(new Date());
    const [name, setName] = useState('SELECCIONE UN ARCHIVO');
    const [selectedFile, setSelectedFile] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [nuevo, setNuevo] = useState(false);
    const [importOrd, setImportOrd] = useState(false);
    const [productos, setProductos] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [borrar, setBorrar] = useState(false);
    const [editar, setEditar] = useState(false);
    const [selectProd, setSelectProd] = useState(false);
    const [selectedProd, setSelectedProd] = useState('Seleccione un producto');
    const [isDel, setIsDel] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const transformDate = (base) => {
        let mes = '';
        if (base.getMonth() + 1 < 10) mes = `0${(base.getMonth() + 1).toString()}`;
        else mes = (base.getMonth() + 1).toString();
        let dia = '';
        if (base.getDate() < 10) dia = `0${base.getDate().toString()}`;
        else dia = base.getDate().toString();

        return `${base.getFullYear()}-${mes}-${dia}`;
    };
    const [newOrd, setNewOrd] = useState({
        id: null,
        ordenProd: '',
        sku: '',
        fecha: `${transformDate(new Date())}T23:59:59`,
        cantidad: 0,
        estatus: 1,
    });

    const getOrders = async (inicio, fin) => {
        const data = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Ordenes/ListadoOrdenesFecha?start=${inicio}&end=${fin}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                },
            }
        );
        const orders = await data.json();
        orders.forEach((item) => {
            const fechaHora = item.fecha.split('T');
            const fechaE = item.fechaEntrega.split('T');
            item.fecha = fechaHora[0];
            item.hora = fechaHora[1];
            item.fechaEntrega = fechaE[0];
            if (item.cantidad - item.cantidadCalendario < 0) {
                item.resta = 0;
            } else {
                item.resta = item.cantidad - item.cantidadCalendario;
            }
        });
        setOrden(orders);
    };

    const getTheOrder = (productionOrder) => {
        if (productionOrder !== '') {
            fetch(
                `${process.env.REACT_APP_SERVICE_ENDPOINT}/Ordenes/GetOrden?orden=${productionOrder}`,
                {
                    method: 'GET',
                    headers: {
                        authorization: `Bearer ${tokenSessionObject.token}`,
                        Accept: 'application/json',
                    },
                }
            )
                .then((res) => res.json())
                .catch((error) => console.error('Error: ', error))
                .then((response) => {
                    setTheOrder(response);
                });
        }
    };

    const getProducts = async () => {
        const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/productos`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
        });
        const products = await data.json();
        setProduct(products);
    };

    const setNewOrder = async (data) => {
        await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/Ordenes/NuevaOrden`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(async (response) => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data1 = isJson && (await response.json());

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data1 && data1.message) || response.status;
                return Promise.reject(error);
            } else {
                // window.location.replace('');
                getOrders(
                    transformDate(fechaIni) + 'T00:00:00',
                    transformDate(fechaFin) + 'T23:59:59'
                );
                // );
            }
        });
    };

    const editOrd = async (data) => {
        fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/Ordenes/EditarOrden`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
            .catch((error) => console.error('Error: ', error))
            .then((response) => {
                getOrders(
                    transformDate(fechaIni) + 'T00:00:00',
                    transformDate(fechaFin) + 'T23:59:59'
                );
            });
    };

    const importOrders = () => {
        let formdata = new FormData();
        formdata.append('', selectedFile, name);

        fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/Ordenes/UploadFileLab`, {
            method: 'POST',
            body: formdata,
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
            redirect: 'follow',
        })
            .then((res) => res.json())
            .catch((error) => console.error('Error: ', error))
            .then((response) => {
                setSnackMsg(response);
                getOrders(
                    transformDate(fechaIni) + 'T00:00:00',
                    transformDate(fechaFin) + 'T23:59:59'
                );
            });
    };

    const delOrd = async (data, pos) => {
        fetch(
            `${
                process.env.REACT_APP_SERVICE_ENDPOINT
            }/Ordenes/EliminarOrden?_orden=${data.toString()}`,
            {
                method: 'DELETE',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                },
            }
        )
            .then((res) => res.json())
            .catch((error) => console.error('Error: ', error))
            .then((response) => {
                if (pos === 0) {
                    getOrders(
                        transformDate(fechaIni) + 'T00:00:00',
                        transformDate(fechaFin) + 'T23:59:59'
                    );
                }
            });
    };

    useEffect(() => {
        getProducts();
        const base = new Date();
        base.setDate(base.getDate() - 30);
        getOrders(`${transformDate(base)}T00:00:00`, `${transformDate(new Date())}T23:59:59`);
    }, []);

    const OrdProdValues = useMemo(() => {
        return {
            orden,
            theOrder,
            product,
            selectionModel,
            setSelectionModel,
            actions,
            setActions,
            snackMsg,
            fechaIni,
            setFechaIni,
            nuevo,
            setNuevo,
            importOrd,
            setImportOrd,
            productos,
            setProductos,
            fechaFin,
            setFechaFin,
            openSnack,
            setOpenSnack,
            name,
            setName,
            setSelectedFile,
            selectProd,
            setSelectProd,
            getTheOrder,
            getOrders,
            transformDate,
            selectedProd,
            setSelectedProd,
            setNewOrder,
            editOrd,
            delOrd,
            importOrders,
            newOrd,
            setNewOrd,
            isEdit,
            setIsEdit,
            isDel,
            setIsDel,
            borrar,
            setBorrar,
            editar,
            setEditar,
        };
    }, [
        orden,
        product,
        theOrder,
        selectionModel,
        name,
        selectedFile,
        selectProd,
        newOrd,
        snackMsg,
        openSnack,
        nuevo,
        importOrd,
        productos,
        fechaIni,
        fechaFin,
        selectedProd,
        isEdit,
        isDel,
        borrar,
        editar,
    ]);

    return <OrdProdContext.Provider value={OrdProdValues} {...props} />;
}

export function useOrdProd() {
    const context = useContext(OrdProdContext);
    if (!context) {
        throw new Error('useOrdProd debe estar dentro del proveedor OrdProdContext');
    }
    return context;
}
