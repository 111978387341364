import React, { useEffect, useRef, useState } from 'react';
//Context
import { useHome } from './HomeContext';

import Chart from 'react-apexcharts';

export default function CircularGraph(props) {
    const circularGraph = useRef(null);
    const { selectedL, setSelectedL, oee } = useHome();
    const time = NumToTime(props.value);

    function NumToTime(num) {
        let hours = Math.floor(num);
        if (hours <= 9) {
            hours = '0' + hours;
        }

        let restoHoras = (num % 1) * 100;
        let minutes = Math.round((restoHoras * 60) / 100);
        if (minutes <= 9) {
            minutes = '0' + minutes;
        }
        return hours + ':' + minutes;
    }
    const blanco = 100 - props.value;
    const series = [props.value, blanco];

    const options = {
        labels: ['OEE', 'blanco'],
        chart: {
            type: 'donut',
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 500,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        tooltip: {
            enabled: false,
        },

        colors: ['#00B884', '#F2F4F8'],
        plotOptions: {
            pie: {
                startAngle: 0,

                expandOnClick: false,

                dataLabels: {
                    offset: 0,
                    minAngleToShowLabel: 10,
                },
                donut: {
                    size: '67%',
                    background: 'transparent',
                    labels: {
                        show: true,
                        name: {
                            show: false,
                        },
                        value: {
                            show: true,
                            fontSize: '16px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            color: undefined,
                            offsetY: 7,
                            formatter: function (val) {
                                return val;
                            },
                        },
                        total: {
                            show: true,
                            showAlways: true,

                            fontSize: '15px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            color: '#373d3f',
                            formatter: function (w) {
                                return w.globals.series[0] + '%';
                            },
                        },
                    },
                },
            },
        },
    };

    return (
        <>
            <div className="graph">
                <div className="graph-tittle">
                    <h3>{props.titulo}</h3>
                </div>
                <div
                    className="graph-body"
                    style={{
                        display: props.showcontent ? 'flex' : 'none',
                    }}
                >
                    <div>
                        <Chart
                            options={options}
                            series={series}
                            type="donut"
                            ref={circularGraph}
                            height={130}
                        />
                    </div>
                </div>
                <div
                    className="graph-body"
                    style={{
                        display: !props.showcontent ? 'flex' : 'none',
                    }}
                >
                    <div className="graph-body-time">
                        <h2 className="graph-body-percen">
                            {' '}
                            {time} {props.tipo}{' '}
                        </h2>
                    </div>
                </div>
            </div>
        </>
    );
}
