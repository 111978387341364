import React, { useEffect, useState } from 'react';
//Components
import Time from './time.jsx';
//Material-UI
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
//Context
import { useShifts } from './ShiftsContext.jsx';

function Horario(props) {
    const {
        codif,
        timePickActivos,
        setSchedules,
        selectedShift,
        selectedLine,
        getSchedule,
        putSchedule,
    } = useShifts();
    const [horaIni, setHoraIni] = useState('12AM');
    const [minIni, setMinIni] = useState('00');
    const [horaFin, setHoraFin] = useState('8AM');
    const [minFin, setMinFin] = useState('00');
    const id = [`${props.id}Ini`, `${props.id}Fin`];

    useEffect(() => {
        reboot();
    }, [props.horaIni, props.horaFin, props.title]);

    const reboot = () => {
        const horaInicio = props.horaIni;
        const horaTermino = props.horaFin;

        if (horaInicio[11] > 0) setHoraIni(codif(parseInt(horaInicio[11] + horaInicio[12])));
        else setHoraIni(codif(parseInt(horaInicio[12])));
        setMinIni(horaInicio[14] + horaInicio[15]);

        if (horaTermino[11] > 0) setHoraFin(codif(parseInt(horaTermino[11] + horaTermino[12])));
        else setHoraFin(codif(parseInt(horaTermino[12])));
        setMinFin(horaTermino[14] + horaTermino[15]);
    };

    const editSchedule = (event) => {
        event.preventDefault();
        let flag = 0;
        let ini = props.horaIni;
        let fin = props.horaFin;
        timePickActivos.forEach((item) => {
            if (item.id === id[0]) {
                //Encontre el inicio
                ini = item.valor;
                flag++;
            } else if (item.id === id[1]) {
                //Encontre el fial
                fin = item.valor;
                flag++;
            }
        });
        if (flag > 0) {
            const nId = parseInt(id[0][1]) + 1;
            putSchedule(nId, ini, fin);
        }
    };

    const cancelBtn = (event) => {
        event.preventDefault();
        setSchedules([]);
        getSchedule(selectedLine, selectedShift + 1);
    };

    return (
        <div className="horario">
            <h3>{props.title}</h3>
            <div className="lblInicio">Inicio</div>
            <Time hour={horaIni} minut={minIni} id={id[0]} />
            <div className="lblInicio">Fin</div>
            <Time hour={horaFin} minut={minFin} id={id[1]} />
            <Grid container spacing={3} className="center">
                <Grid item md={6}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className="btn"
                        onClick={(event) => cancelBtn(event)}
                    >
                        Cancelar
                    </Button>
                </Grid>
                <Grid item md={6}>
                    <Button
                        color="primary"
                        variant="contained"
                        className="btn"
                        onClick={(event) => editSchedule(event)}
                    >
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

Horario.defaultProps = {
    title: 'El titulo',
};

export default Horario;
