import React, { useEffect } from "react";
//Components
import TablaOrd from "./TablaOrd";
import NuevaOrdProd from "./NuevaOrdProd";
import EditarOrd from "./EditarOrd";
import BorrarOrd from "./BorrarOrd";
import Importar from "./Importar";
//Material-UI
import { Grid, Button } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
}
  from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//Context
import { OrdProdProvider, useOrdProd } from './OrdProdContext';
import '../../styles/lista-ordenes.css';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    minHeight: 562,
    width: '100%',
    minWidth: 1070,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function OrdenProduct() {
  const classes = useStyles();
  const { fechaIni, setFechaIni, openSnack, setOpenSnack,
    fechaFin, setFechaFin, snackMsg,
    selectionModel, getOrders, transformDate, } = useOrdProd();

  const handleIniChange = (date) => {
    setFechaIni(date);
  };

  const handleFinChange = (date) => {
    setFechaFin(date);
  };

  const buscarOrdenes = (event) => {
    event.preventDefault();
    getOrders(transformDate(fechaIni) + 'T00:00:00', transformDate(fechaFin) + 'T23:59:59');
  };

  const handleClose = () =>{
    setOpenSnack(false);
  }

  useEffect(() => {
    const base = fechaIni;
    base.setDate(base.getDate() - 30);
    setFechaIni(base);
  }, []);

  return (
    <Grid container className="doc">
      <Grid item md={12} className="head">
        <Grid container alignItems="center" spacing={2}>
          <Grid item md={7}>
            <h1 className="titulo">Lista de órdenes</h1>
            <p className="desc">
              En esta lista de órdenes puedes hacer click en una órden para
              seleccionarla y así editarla o borrarla
            </p>
          </Grid>
          <Grid item md={3}>
            <Importar />
          </Grid>
          <Grid item md={2}>
            <NuevaOrdProd />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} className="body">
        <Grid container alignItems="center" spacing={2}>
          <Grid item md={5}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                name="uxFechaIni"
                label="FECHA INICIAL"
                fullWidth
                autoOk
                value={fechaIni}
                onChange={handleIniChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={5}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                name="uxFechaFin"
                label="FECHA FINAL"
                fullWidth
                autoOk
                value={fechaFin}
                onChange={handleFinChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => buscarOrdenes(event)}
              className="listado-ordenes-button"
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
        <div className="actions">
          <div className="actions-selected">{selectionModel.length} Seleccionados</div>
          <EditarOrd />
          <BorrarOrd />
        </div>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <div className={classes.root}>
              <TablaOrd />
            </div>
          </Grid>
        </Grid>
        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {snackMsg}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}

export default () => (
  <OrdProdProvider>
    <OrdenProduct></OrdenProduct>
  </OrdProdProvider>
);
