import React, { useEffect } from 'react';
//Material-UI
import { 
  Grid,
  makeStyles,
  Divider,
  Chip,} from "@material-ui/core";
//Icons
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
//Context
import { useCuenta } from './CuentaContext';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: 20,
    paddingTop: 8,
    paddingBottom: 5,
  },
}));

export default function Laboral () {
  const { roles, setRoles, theUser, } = useCuenta();
  const classes = useStyles();

  useEffect(() => {
    const rolesDefault = [...theUser.roles];
    const newRoles = [...roles];
    rolesDefault.forEach(rolUser => {
      newRoles.forEach(rol => {
        if(rolUser === rol.nombre){
          rol.color='primary';
        }
        else {
          rol.color='default';
        }
      });
    });
    setRoles(newRoles);
  }, [theUser]);

  const dummy = () => {
    return 1;
  }

  return(
    <div>
      <div className={classes.content}>
        <h3>Información Laboral</h3>
      </div>
      <Divider light/>
      <div className={classes.content}>
        <h4>Cargos que desempeña</h4>
        <Grid container spacing={1}>
          {roles.map((rol) => (
            <Grid item>
              <Chip 
                color={rol.color} 
                label={rol.nombre} 
                onDelete={dummy}
                deleteIcon={
                  rol.color === 'primary' 
                  ? <CheckCircleOutlineIcon />
                  : <p></p> 
                }
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}