import React from 'react';
//Material-UI
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    ListItemIcon,
    Checkbox,
    Grid,
    Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//Context
import { useProductos } from './ProductosContext';

const useStyles = makeStyles((theme) => ({
    small: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        height: '78%',
    },
}));

export default function LineasList() {
    const {
        lines,
        isProdSelected,
        selectedLine,
        handleListLinesClick,
        handleToggle,
        checked,
        cnlLines,
        isSaveLines,
        saveLines,
    } = useProductos();
    const classes = useStyles();
    return (
        <div>
            <div className="header">
                <h3 className="header-lineas">Líneas</h3>
            </div>
            <div className="column">
                {/* <form onSubmit={saveLines}> */}
                <List className={classes.small}>
                    {lines.map((item) => {
                        const labelId = `checkbox-list-label-${item.id - 1}`;
                        return (
                            <div className="Lineas">
                                <ListItem
                                    key={item.id.toString()}
                                    button
                                    disabled={isProdSelected}
                                    selected={selectedLine === item.id}
                                    onClick={(event) => {
                                        handleListLinesClick(event, item.id);
                                    }}
                                >
                                    <ListItemText primary={item.nombre} key={item.id.toString()} />
                                    <ListItemSecondaryAction key={item.id.toString()}>
                                        <ListItemIcon key={item.id.toString()}>
                                            <Checkbox
                                                disabled={isProdSelected}
                                                edge="end"
                                                color="primary"
                                                onChange={handleToggle(item.id - 1)}
                                                checked={checked.indexOf(item.id - 1) !== -1}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </div>
                        );
                    })}
                </List>
                <div className="footer1">
                    <Button
                        variant="outlined"
                        color="primary"
                        className="btn-cancel-linea"
                        disabled={isProdSelected}
                        onClick={cnlLines}
                    >
                        Cancelar
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        className="btn-accept"
                        disabled={isSaveLines}
                        onClick={saveLines}
                    >
                        Guardar
                    </Button>
                </div>
                {/* </form> */}
            </div>
        </div>
    );
}
