import React from 'react';
//Material-UI
import { Grid, IconButton, Button } from '@material-ui/core';
//Icons
import { CirclePlus } from 'tabler-icons-react';
import { CircleMinus } from 'tabler-icons-react';
//Context
import { useProductos } from './ProductosContext';
//Style
import '../../styles/capacitiesDefault.css';

const setColor = (newColor) => {
    document.documentElement.style.setProperty('--color', newColor);
};

export default function CapacidadesList() {
    const {
        pzas,
        ideal,
        std,
        cnlCap,
        isLineSelected,
        isSaveCap,
        saveCapacities,
        increment,
        decrement,
    } = useProductos();

    let primColor = '';
    if (process.env.REACT_APP_THEME === 'tecnoTheme') {
        primColor = '#ff9800';
        setColor(primColor);
    } else if (process.env.REACT_APP_THEME === 'colTheme') {
        primColor = '#58ab01';
        setColor(primColor);
    } else if (process.env.REACT_APP_THEME === 'matteTheme') {
        primColor = '#253139';
        setColor(primColor);
    } else if (process.env.REACT_APP_THEME === 'skyTheme') {
        primColor = '#001B69';
        setColor(primColor);
    } else {
        primColor = '#903bff';
        setColor(primColor);
    }

    return (
        <div>
            <div className="header">
                <h3 className="header-lineas">Capacidades</h3>
            </div>
            <div className="column-capacities">
                <div className="inputNumb">
                    <div className="capacities-bg">
                        <p>PIEZAS POR PAQUETE</p>
                    </div>
                    <div className="numberField">
                        <Grid container spacing={1}>
                            <Grid item sm={4} className="minus">
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    disabled={isLineSelected}
                                    onClick={(event) => decrement(event, 0)}
                                >
                                    <CircleMinus color={'#7D7F90'} />
                                </IconButton>
                            </Grid>
                            <Grid item sm={4} className="valorNum">
                                {pzas}
                            </Grid>
                            <Grid item sm={4} className="plus">
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    disabled={isLineSelected}
                                    onClick={(event) => increment(event, 0)}
                                >
                                    <CirclePlus color={'#7D7F90'} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="helper"></div>
                </div>
                <div className="inputNumb">
                    <div className="capacities-bg">
                        <p>RITMO IDEAL</p>
                    </div>
                    <div className="numberField">
                        <Grid container spacing={1}>
                            <Grid item sm={4} className="minus">
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    disabled={isLineSelected}
                                    onClick={(event) => decrement(event, 1)}
                                >
                                    <CircleMinus color={'#7D7F90'} />
                                </IconButton>
                            </Grid>
                            <Grid item sm={4} className="valorNum">
                                {ideal}
                            </Grid>
                            <Grid item sm={4} className="plus">
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    disabled={isLineSelected}
                                    onClick={(event) => increment(event, 1)}
                                >
                                    <CirclePlus color={'#7D7F90'} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="helper">Piezas / Minuto</div>
                </div>
                <div className="inputNumb">
                    <div className="capacities-bg">
                        <p>RITMO ESTÁNDAR</p>
                    </div>
                    <div className="numberField">
                        <Grid container spacing={1}>
                            <Grid item sm={4} className="minus">
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    disabled={isLineSelected}
                                    onClick={(event) => decrement(event, 2)}
                                >
                                    <CircleMinus color={'#7D7F90'} />
                                </IconButton>
                            </Grid>
                            <Grid item sm={4} className="valorNum">
                                {std}
                            </Grid>
                            <Grid item sm={4} className="plus">
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    disabled={isLineSelected}
                                    onClick={(event) => increment(event, 2)}
                                >
                                    <CirclePlus color={'#7D7F90'} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="helper">Piezas / Minuto</div>
                </div>
                <div className="footer2">
                    <Grid container spacing={1}>
                        <Grid item sm={8}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className="btn-cancel"
                                onClick={cnlCap}
                                disabled={isLineSelected}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item sm={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="btn-accept"
                                disabled={isSaveCap}
                                onClick={saveCapacities}
                            >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}
