import { colTheme } from './colTheme';
import { novaTheme } from './novaTheme';
import { tecnoTheme } from './tecnoTheme';
import { matteTheme } from './matteTheme';
import { skyTheme } from './skyTheme';

const themeOptions = {
    skyTheme: skyTheme,
    matteTheme: matteTheme,
    tecnoTheme: tecnoTheme,
    colTheme: colTheme,
    default: novaTheme,
};

export function themeFactory(theme) {
    return themeOptions[theme] || themeOptions['default'];
}
