import React, { useEffect } from 'react';
//Components
import NuevoProducto from './NuevoProducto';
import Alerta from './Alerta';
import TablaProductos from './TablaProductos';
import LineasList from './Lineas';
import CapacidadesList from './CapacidadesList';
import EditProduct from './EditProduct';
import DeleteProduct from './DeleteProduct';
//import ProductList from './ProductsList';
//Style
import '../../popup.css';
import '../../productos.css';
//Material-UI
import { Grid, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
//Icons
import { AlertTriangle } from 'tabler-icons-react';
//Context
import { ProductosProvider } from './ProductosContext';
import { useProductos } from './ProductosContext';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Productos() {
    const { getProducts, getLines, abrir, deleteProd, alerta, snackbar, setSnackbar, msgSb } =
        useProductos();
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar(false);
    };

    useEffect(() => {
        getProducts();
        getLines();
    }, []);

    return (
        <Grid container direction="column" className="doc">
            <Grid item className="head">
                <Grid container alignItems="center" spacing={2}>
                    <Grid item md={10}>
                        <h1 className="titulo">Productos</h1>
                        <p className="desc">
                            Agregar, editar y borrar productos, así como la información relacionada
                            a ellos.
                        </p>
                    </Grid>
                    <Grid item md={2}>
                        <NuevoProducto />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className="body">
                <Snackbar open={snackbar} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="info">
                        {msgSb}
                    </Alert>
                </Snackbar>
                <Alerta
                    abrir={abrir}
                    colorButton="default"
                    buttonClass=""
                    textButton="Borrar"
                    popupSeconButton="Cancelar"
                    popupsbclass="btn-del-cnl"
                    isContained="text"
                    popupTitle={
                        <div className="icon-big">
                            <AlertTriangle size={120} color={'#7D7F90'} />
                        </div>
                    }
                    popupBody={
                        <div className="popup-delete">
                            <form onSubmit={deleteProd}>
                                <div className="message">
                                    No se pudo guardar la información:
                                    <p style={{ fontSize: 14, fontWeight: 'normal' }}> {alerta} </p>
                                </div>
                            </form>
                        </div>
                    }
                />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                >
                    <Grid item md={6}>
                        <div className="header" style={{ marginTop: 18 }}>
                            <Grid container alignItems="center">
                                <Grid item md={8}>
                                    <h3 className="list-header">Productos</h3>
                                </Grid>
                                <Grid item md={2} style={{ textAlign: 'right' }}>
                                    <EditProduct />
                                </Grid>
                                <Grid item md={2}>
                                    <DeleteProduct />
                                </Grid>
                            </Grid>
                        </div>
                        <div className="column">
                            <TablaProductos />
                        </div>
                    </Grid>
                    <Grid item md={3}>
                        <LineasList />
                    </Grid>
                    <Grid item md={3}>
                        <CapacidadesList />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default () => (
    <ProductosProvider>
        <Productos></Productos>
    </ProductosProvider>
);
