import React, { useEffect } from 'react';
//Context
import { useFallas } from './FallasContext';
//Components
import ModalS from '../../components/ModalS';
//Material-UI
import { TextField, Button } from '@material-ui/core';
//Icons
import { CirclePlus } from 'tabler-icons-react';

export default function NuevaCatego() {
    const {
        text,
        setText,
        createCatego,
        handleOpenC,
        handleCloseC,
        openC,
        updateGridC,
        setUpdateGridC,
        getCatego,
    } = useFallas();

    useEffect(() => {
        if (updateGridC === true) {
            getCatego();
            setUpdateGridC(false);
        }
    }, [updateGridC]);

    return (
        <>
            <Button
                className="popup-button-p"
                variant="contained"
                color="primary"
                onClick={handleOpenC}
            >
                <div>
                    <CirclePlus size={18} />
                </div>
                &nbsp;Agregar categoría
            </Button>

            <ModalS
                openM={openC}
                popupTitle={
                    <div className="popuphead5">
                        <h2 className="popuptitle">Nueva Categoría</h2>
                        <p className="popuptitled1">
                            Agrega un nombre o item nuevo para categorizar las diferentes fallas
                            existentes.
                        </p>
                    </div>
                }
                popupBody={
                    <div className="popupbody2">
                        <div className="popuptextfield1">
                            <TextField
                                id="uxTxtNombreC"
                                name="nombre"
                                label="NOMBRE"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                fullWidth
                            />
                        </div>
                        <div className="popupbottom">
                            <div className="popupbpd10">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className="popup-button"
                                    onClick={handleCloseC}
                                    fullWidth
                                >
                                    Cancelar
                                </Button>
                            </div>
                        </div>
                        <div className="popupbpd9">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className="popup-button"
                                onClick={createCatego}
                                fullWidth
                            >
                                Crear nueva categoría
                            </Button>
                        </div>
                    </div>
                }
            />
        </>
    );
}
