import React, { useState, useEffect } from "react";
//Component
import PopUp from './PopUp'
//Icons
import { Edit } from 'tabler-icons-react';
import DoneIcon from '@material-ui/icons/Done';
import { User } from 'tabler-icons-react';
//Material-UI
import { Grid, TextField, Select, InputLabel, Chip, Button, MenuItem } from "@material-ui/core";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
//Context
import { useUsuarios } from "./UsuariosContext";

export default function NuevoUsuario () {
  const { theUser, isEdit, roles, setRoles, ordenaArrObj,
          gpos, deptos, languages, editUser, setEditar } = useUsuarios();
  const [gpo, setGpo] = useState();
  const [depto, setDepto] = useState();
  const [language, setLanguage] = useState();
  const [levels, setLevels] = React.useState({
    one: false,
    two: false,
    three: false,
  });
  const { one, two, three } = levels;

  useEffect(()=>{
    setGpo(theUser.grupo);
    setDepto(theUser.departamento);
    setLanguage(theUser.idioma);
    const newLvls = {
      one: theUser.esNivel1,
      two: theUser.esNivel2,
      three: theUser.esNivel3
    }
    setLevels(newLvls);
    const rolesDefault = theUser.roles;
    rolesDefault.forEach(rolUser => {
      roles.forEach(rol => {
        if(rolUser === rol.nombre){
          rol.color='primary';
        }
      })
    })
  }, [theUser]);

  const handleMark = (event, name) =>{
    event.preventDefault();
    let i = 0, color = 'primary';
    const newRoles = [...roles];
    roles.forEach(rol => {
      if(rol.nombre === name){
        newRoles.splice(i, 1);
        if(rol.color === "primary")
          color = 'default';
        else 
          color = 'primary';
        newRoles.push({
          nombre: rol.nombre,
          color: color
        });
      }
      i++;
    });
    newRoles.sort(ordenaArrObj);
    setRoles(newRoles);
  }
  const lelvelsChange = (e) => {
    setLevels({ ...levels, [e.target.name]: e.target.checked });
  }
  const putUser = (e) => {
    e.preventDefault();
    let selectedRols = [];
    roles.forEach(rol => {
      if(rol.color === 'primary'){
        selectedRols.push(rol.nombre);
      }
    })
    const data = {
      "usuario": document.getElementById('uxTxtUsername').value,
      "password": 'null',
      "email": document.getElementById('uxTxtMail').value,
      "phoneNumber": document.getElementById('uxTxtPhoneNumber').value,
      "id": null,
      "nombre": document.getElementById('uxTxtName').value,
      "apellido": document.getElementById('uxTxtLastName').value,
      "cardId": null,
      "notas": document.getElementById('uxTxtNotes').value,
      "departamento": depto,
      "grupo": gpo,
      "esNivel1": one,
      "esNivel2": two,
      "esNivel3": three,
      "idioma": language,
      "horario": null,
      "roles": selectedRols
    }
    editUser(data);
    setEditar(false);
  }
  return(
    <PopUp
      name="editar"
      colorButton="default"
      textButton={
        <div>
            <Edit size={20} className="icon" /> Editar
        </div>
      }
      buttonClass="actions-edit"
      popupPrimButton="Crear nueva orden"
      popupSeconButton="Cancelar"
      popupsbclass="popupbp"
      isContained="text"
      isActive={isEdit}
      popupTitle= {
        <div className="popuphead">
          <h2 className="popuptitle">Editar información del usuario</h2>
        </div>
      }
      popupBody={
        <div className="popupbody">
          <Grid container spacing={2}>
            <Grid item md={12}>
              <InputLabel htmlFor="standard-adornment-username">
                Nombre de usuario
              </InputLabel>
              <Input
                type="text"
                id="uxTxtUsername"
                defaultValue={theUser.usuario}
                disabled
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                      <User></User>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item md={6}>
              <TextField 
                id="uxTxtName" 
                label="NOMBRE"
                placeholder="Escribe el nombre" 
                fullWidth 
                defaultValue={theUser.nombre}
              />
            </Grid>
            <Grid item md={6}>
              <TextField 
                id="uxTxtLastName" 
                label="APELLIDOS"
                placeholder="Escribe los apellidos" 
                fullWidth 
                defaultValue={theUser.apellido}
              />
            </Grid>
            <Grid item md={6}>
              <div>
                <InputLabel id="uxListGroup" fullWidth>GRUPO</InputLabel>
                <Select
                  labelId="uxListGroup"
                  fullWidth
                  value={gpo}
                  onChange={ e => setGpo(e.target.value) }
                >
                  {gpos.map((item) => (
                    <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{marginTop: 10}}>
                <InputLabel id="uxListDepto" fullWidth>DEPARTAMENTO</InputLabel>
                <Select
                  labelId="uxListDepto"
                  fullWidth
                  value={depto}
                  onChange={ e => setDepto(e.target.value) }
                >
                  {deptos.map((item) => (
                    <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{marginTop: 10}}>
                <InputLabel id="uxListLanguages" fullWidth>IDIOMAS</InputLabel>
                <Select
                  labelId="uxListLanguages"
                  fullWidth
                  value={language}
                  onChange={ e => setLanguage(e.target.value) }
                >
                  {languages.map((item) => (
                    <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item md={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">NIVELES</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={one} onChange={lelvelsChange} name="one" />}
                    label="Nivel 1"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={two} onChange={lelvelsChange} name="two" />}
                    label="Nivel 2"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={three} onChange={lelvelsChange} name="three" />}
                    label="Nivel 3"
                  />
                </FormGroup>
                <FormHelperText>Puede marcar más de uno</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <TextField 
                id="uxTxtNotes" 
                label="NOTAS"
                placeholder="Escribe las notas" 
                fullWidth 
                defaultValue={theUser.notas}
              />
            </Grid>
            <Grid item md={6}>
              <TextField 
                id="uxTxtMail" 
                label="CORREO"
                placeholder="Escribe el correo" 
                type="mail"
                fullWidth 
                defaultValue={theUser.email}
              />
            </Grid>
            <Grid item md={6}>
              <TextField 
                id="uxTxtPhoneNumber" 
                label="TELÉFONO"
                placeholder="Escribe el teléfono" 
                fullWidth 
                defaultValue={theUser.phoneNumber}
              />
            </Grid>
          </Grid>
          <h4>Cargos que desempeña</h4>
          {roles.map((rol) => (
            <Chip label={rol.nombre} deleteIcon={<DoneIcon />} onDelete={event => handleMark(event, rol.nombre)} color={rol.color} />
          ))}
          <div id="popupbs">
            <Button
              color="primary"
              className="popup-button-p"
              variant="contained"
              onClick={ e => putUser(e) }
            >
              Guardar
            </Button>
          </div>
        </div>
      }
    />
  );
}