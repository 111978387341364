import React, { useState } from 'react';
//Components
import Normal from "./LlamadaNormal";
import Simple from "./LlamadaSimple";
import Ubicacion from "./Ubicacion";
//Material-UI
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
//Styles
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    overflow: 'auto',
    minWidth: 570,
    maxHeight: 331,
    minHeight: 331,
    paddingBottom: 50,
  },
}));

export default function Tipo() {
  const classes = useStyles();
  const [selectedTipo, setSelectedTipo] = useState('normal');
  const handleTipoChange = (event) => {
    setSelectedTipo(event.target.value);
  }
  return (
    <div className="popupbody fijo">
      <div className="popuptextfield">
        <FormControl component="fieldset" fullWidth>
          <RadioGroup aria-label="gender" name="gender1" value={selectedTipo} onChange={handleTipoChange}>
            <Grid container spacing={0}>
              <Grid item sm={6}>
                <FormControlLabel value="normal" control={<Radio />} label="Llamada normal" />
              </Grid>
              <Grid item sm={6}>
                <FormControlLabel value="simple" control={<Radio />} label="Llamada simple" />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </div>
      <div className={classes.root}>
        {(selectedTipo === 'normal')
          ? <Normal />
          : <Simple />
        }
        <h4>¿Desde dónde se puede ser levantada esta llamada?</h4>
        <Ubicacion />
      </div>
    </div>
  );
}