import React, { useState } from 'react';
//Components
import EditarComentario from './EditarComentario';
import BorrarComentario from './BorrarComentario';
//Materila-UI
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Menu, MenuItem } from '@material-ui/core';
//Icons
import MoreVertIcon from '@material-ui/icons/MoreVert';
//Context
import { useBeepers } from './BeepersContext';

const useStyles = makeStyles({
    header: {
        height: 56,
        paddingLeft: 20,
        backgroundColor: '#F2F4F8',
    },
    textHeader: {
        fontSize: 18,
        color: '#13151C',
        fontWeight: 'bold',
    },
    card: {
        backgroundColor: '#ffff',
        paddingBottom: 30,
        paddingRight: 20,
        borderColor: '#00000014',
        borderStyle: 'solid',
        borderWidth: 1,
    },
    content: {
        paddingLeft: 20,
        paddingTop: 8,
        paddingBottom: 5,
    },
});

function Comentarios() {
    const { comentario, setEditar, isOptionsDisabled, setBorrarComent } = useBeepers();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const abrirEditar = (event) => {
        event.preventDefault();
        setEditar(true);
        setAnchorEl(null);
    };

    const abrirBorrar = (event) => {
        event.preventDefault();
        setBorrarComent(true);
        setAnchorEl(null);
    };

    return (
        <div>
            <div className={classes.header}>
                <Grid container>
                    <Grid item md={10}>
                        <h3 className={classes.textHeader}>Comentarios</h3>
                    </Grid>
                    <Grid item md={2}>
                        <IconButton onClick={handleClick} disabled={isOptionsDisabled}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={abrirEditar}>Editar</MenuItem>
                            <MenuItem onClick={abrirBorrar}>Borrar</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.card}>
                <div className={classes.content}>
                    <p style={{ textAlign: 'justify' }}>
                        {comentario === ''
                            ? 'El Beeper seleccionado no tiene comentarios.'
                            : comentario}
                    </p>
                </div>
            </div>
            <EditarComentario />
            <BorrarComentario />
        </div>
    );
}

export default Comentarios;
