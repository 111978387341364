import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
//Context
import { useBeepers } from './BeepersContext';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        fontFamily: 'WorkSans',
        position: 'absolute',
        width: 384,
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '0px 0px 20px 0px',
        height: 570,
    },
}));

function PopUpS(props) {
    const { borrarBeeper, setBorrarBeeper, borrarComent, setBorrarComent, } = useBeepers();
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        switch (props.name) {
            case 'beeper':
                setBorrarBeeper(false);
                break;
            case 'comment':
                setBorrarComent(false);
                break;
            default:
                break;
        }
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <div id="simple-modal-title">{props.popupTitle}</div>
            <div id="simple-modal-description">
                {props.popupBody}
                <div className="popupbottom">
                    <div className={props.popupsbclass}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className="popup-button-s"
                            onClick={handleClose}
                            fullWidth
                        >
                            {props.popupSeconButton}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

    useEffect(() => {
        switch (props.name) {
            case 'beeper':
                setOpen(borrarBeeper)
                break;
            case 'comment':
                setOpen(borrarComent);
                break;
            default:
                break;
        }
    }, [borrarBeeper, borrarComent]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {body}
        </Modal>
    );
}

PopUpS.defaultProps = {
    popupTitle: 'Título',
    popupBody: 'Cuerpo de la ventana emergente',
    popupsbclass: 'btn-del-cnl',
    popupSeconButton: 'Cancelar',
    colorButton: 'primary',
    buttonClass: 'btn-guardar',
    textButton: 'Submit',
    isContained: 'contained',
    isActive: true,
};

export default PopUpS;
