import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

function CustomFooterTotalComponent(props) {
    return (
        <Box sx={{ padding: '10px', display: 'flex' }}>
            <h3 className="footerTable">
                Sumatoria Total : {props.total}, Sumatoria producido: {props.producido}
            </h3>
        </Box>
    );
}

CustomFooterTotalComponent.propTypes = {
    total: PropTypes.number,
};

export { CustomFooterTotalComponent };
