import React, { useEffect, useState } from 'react';
//Context
import { useHome } from './HomeContext';

//Styles
import '../../styles/home.css';

// Material Ui Components
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import esLocale from 'date-fns/locale/es';

export default function SelectFecha(props) {
    const { selectedF, setSelectedF } = useHome();

    return (
        <>
            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Seleccione un día"
                    format="MM/dd/yyyy"
                    value={selectedF}
                    onChange={(e) => setSelectedF(e)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
        </>
    );
}
