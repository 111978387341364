import React, { useState, useEffect } from 'react';
//Context
import { LlamadasProvider, useLlamadas } from './LlamadasContext';
//Components
import NuevoTipoLlamada from './NuevoTipoLlamada';
import EditarTipoLlamada from './EditarTipoLlamada';
//Material-UI
import { DataGrid, esES } from '@material-ui/data-grid';
import { Chip, Grid, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
//Styles
import '../../styles/llamadas.css';

function Alert(props) {
return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Llamadas() {
    const { getInfo, llamadas, GreenChip, YellowChip, RedChip, BlueChip, snackbar,
            setSnackbar, snackbarText, setIsEdit, getTheCallType } = useLlamadas();
    const [selectionModel, setSelectionModel] = useState([]);
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }    
        setSnackbar(false);
      };

    function renderColor(params) {
        switch (params.value) {
            case 'Verde':
                return <div style={ { paddingLeft: 15 } }><GreenChip label="Verde" /></div>;
            case 'Amarillo':
                return <div style={ { paddingLeft: 5 } }><YellowChip label="Amaraillo" /></div>;
            case 'Rojo':
                return <div style={ { paddingLeft: 18 } }><RedChip label="Rojo" /></div>;
            case 'Azul':
                return <div style={ { paddingLeft: 18 } }><BlueChip label="Azul" /></div>;
            default:
                return <Chip label="Gris" />;
            }
        }

        const llamadasColumns = [
            { field: 'tipoLlamada', headerName: 'Código', width: 122 },
            { field: 'colorName', headerName: 'Color', renderCell: renderColor, width: 113 },
            { field: 'areaAtencionName', headerName: 'Área de atención', width: 185 },
            { field: 'nombre', headerName: 'Nombre', width: 200 },
            { field: 'esDowntime', headerName: 'Es Paro', type: 'boolean', width: 130, editable: true },
        { field: 'esSonora', headerName: 'Es Sonora', type: 'boolean', width: 150 },
        { field: 'requiereClasificacion', headerName: 'Clasificar', type: 'boolean', width: 150 },
    ];

    useEffect(() => {
        getInfo('tiposllamada/Listado');
    }, []);

    useEffect(() => {
        setSelectionModel([]);
    }, [llamadas]);

    return (
        <div className="llamadasView">
            <Snackbar open={snackbar} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">{ snackbarText }</Alert>
            </Snackbar>
            <Grid container alignItems="center">
                <Grid item md={9} className="head">
                    <h1 className="titulo">Tipos de llamadas</h1>
                    <p className="desc">
                        Agregar y editartipos de llamadas, así como la información relacionada a
                        ellos.
                    </p>
                </Grid>
                <Grid item md={3}>
                    <NuevoTipoLlamada />
                </Grid>
                <Grid item md={12} className="bodyLlamadas">
                    <div className="actions">
                        <div className="actions-selected">{selectionModel.length} Seleccionados</div>
                        <EditarTipoLlamada />
                    </div>
                    <div style={{ height: 700, width: '100%' }}>
                        <DataGrid
                            columns={llamadasColumns}
                            rows={llamadas}
                            localeText={esES.props.MuiDataGrid.localeText}
                            onSelectionModelChange={async (newSelectionModel) => {
                                if(newSelectionModel.length > 0){
                                    if(newSelectionModel.length > 1)
                                        setIsEdit(false);
                                    else
                                        setIsEdit(true);
                                  getTheCallType(newSelectionModel[0]);
                                }
                                else{
                                  setIsEdit(false);
                                }
                                setSelectionModel(newSelectionModel);
                              }}
                              selectionModel={selectionModel}
                              hideFooterSelectedRowCount={true}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default () => (
    <LlamadasProvider>
        <Llamadas></Llamadas>
    </LlamadasProvider>
);
