import React, { useEffect, useState } from 'react';
//Context
import { useHome } from './HomeContext';
//Component:

//Styles
import '../../styles/home.css';

// Material Ui Components
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { MenuItem } from '@material-ui/core';

export default function SelectList() {
    const { selectedL, setSelectedL } = useHome();

    return (
        <>
            <div>
                <FormControl variant="filled" className="select">
                    <InputLabel id="demo-simple-select-label">Selecione una vista</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedL}
                        onChange={(event) => setSelectedL(event.target.value)}
                    >
                        <MenuItem value={1}>Plan</MenuItem>
                        <MenuItem value={2}>Real</MenuItem>
                        <MenuItem value={3}>Plan vs Real</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </>
    );
}
