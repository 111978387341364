import { createContext, useState, useEffect, useMemo, useContext } from "react";

const UsuariosContext = createContext();

export function UsuariosProvider (props) {
  const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));
  const [usuarios, setUsuarios] = useState([]);
  const [roles, setRoles] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [lineas, setLineas] = useState([]);
  const [isDel, setIsDel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [passError, setPassError] = useState(true);
  const [deptos, setDeptos] = useState([]);
  const [gpos, setGpos] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [nuevo, setNuevo] = useState(false);
  const [editar, setEditar] = useState(false);
  const [borrar, setBorrar] = useState(false);
  const [theUser, setTheUser] = useState({
    "usuario": '',
    "password": '',
    "email": '',
    "phoneNumber": '',
    "id": null,
    "nombre": '',
    "apellido": '',
    "cardId": null,
    "notas": '',
    "departamento": 0,
    "grupo": 0,
    "esNivel1": false,
    "esNivel2": false,
    "esNivel3": false,
    "idioma": 0,
    "horario": null,
    "roles": []
  });

  const getInfo = async(dto) => {
    const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/${ dto }`,{
      method: 'GET',
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
      },
    })
    if(data !== null){
      const obj = await data.json();
    
      switch(dto){
        case 'usuarios/GetUsuarios':
          obj.forEach(element => {
            let strRoles  = '';
            //Adding the id field for the DataGrid
            element.id = element.usuario;
            //Converting the roles array to str
            if(element.roles !== null){
              element.roles.forEach(rol => strRoles += (`${rol}, `));
              strRoles = strRoles.slice(0, -2); 
              element.strRoles = strRoles;
            }
            //Unifying th username and name
            element.identity = `${element.nombre}, ${element.usuario}`;
            //Unifying the contact info
            element.contact = `${element.email}, ${element.phoneNumber}`;
          });
          setUsuarios(obj);
        break;
        case 'roles':
          const cargos = [];
          obj.roles.forEach(cargo => {
            const data = {
              nombre: cargo.toString(),
              color: 'default' 
            }
            cargos.push(data);
          });
          cargos.sort(ordenaArrObj);
          setRoles(cargos);
        break;
        case 'turnos':
          const shifts = [];
          obj.forEach((item) => {
            const strSchedule = `${ lineas[item.linea - 1] } - Turno ${ item.turno }`;
            shifts.push(strSchedule);
          })
          setTurnos(shifts);
        break;
        case 'lineas':
          const names = [];
          obj.forEach((item) => names.push(item.nombre));
          setLineas(names);
        break;
        default:
          console.error(obj);
        break;
      }
    }
  }

  const getTheUser = (userName) => {
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/usuarios/GetUsuario?username=${userName}`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
      },
    }).then(res => res.json())
      .catch(error => console.error('Error: ', error))
      .then(response => {
        setTheUser(response);
      });
  }

  const getMicroService = (name) => {
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/MicroServiciosController?categoria=${name}`,{
      method: 'GET',
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
      },
    }).then(res => res.json())
      .catch(error => console.error('Error: ', error))
      .then(response => {
        response.forEach(item => {
          item.id = item.codigo;
        })
        switch(name){
          case 'Departamentos':
            setDeptos(response);
          break;
          case 'GruposUsuarios':
            setGpos(response);
          break;
          case 'Idiomas':
            setLanguages(response);
          break;
          default:
          break;
        }
      });
  }

  const registeruser = (data) => {
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/usuarios/Registrar`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(res => res.json())
      .catch(error => console.error('Error: ', error))
      .then(dataTable());
  }

  const editUser = (data) => {
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/usuarios/Actualizar`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(res => res.json())
      .catch(error => console.error('Error: ', error))
      .then(dataTable());
  }

  const delUser = (username) => {
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/usuarios/eliminar/${username}`, {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(res => res.json())
      .catch(error => console.error('Error: ', error))
      .then(dataTable());
  }

  const ordenaArrObj = (x, y) => {
    if(x.nombre < y.nombre) { return -1 }
    if(x.nombre > y.nombre) { return 1 }
    return 0;
  }

  const buscaMinus = (pass) => {
    for(let i = 0; i < pass.length; i++){
      let ascii = pass.charCodeAt(i);
      if(ascii > 96 && ascii < 123){
        return true;
      }
    }
    return false;
  }

  const buscaMayus = (pass) => {
    for(let i = 0; i < pass.length; i++){
      let ascii = pass.charCodeAt(i);
      if(ascii > 64 && ascii < 91){
        return true;
      }
    }
    return false;
  }

  const buscaEspecial = (pass) => {
    //33-47; 58-64; 91-96; 123-126
    for(let i = 0; i < pass.length; i++){
      let ascii = pass.charCodeAt(i);
      if(ascii > 32 && ascii < 48){
        return true;
      } else if(ascii > 57 && ascii < 65){
        return true;
      } else if(ascii > 90 && ascii < 97){
        return true;
      } else if(ascii > 122 && ascii < 127){
        return true;
      }
    }
    return false;
  }

  const buscaNumero = (pass) => {
    for(let i = 0; i < pass.length; i++){
      let ascii = pass.charCodeAt(i);
      if(ascii > 47 && ascii < 58){
        return true;
      }
    }
    return false;
  }

  const validaPass = (pass) => {
    if(
      pass.length > 5 &&
      buscaMinus(pass) &&
      buscaMayus(pass) &&
      buscaEspecial(pass) &&
      buscaNumero(pass)
    )
      { setPassError(false) }
    else
      { setPassError(true) }
  }

  const dataTable = async() =>{
    await sleep(3500);
    getInfo('usuarios/GetUsuarios');
  }
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(()=>{
    getInfo('roles');
    getMicroService('Departamentos');
    getMicroService('GruposUsuarios');
    getMicroService('Idiomas');
    getInfo('usuarios/GetUsuarios');
  }, [])

  const UsuariosValues = useMemo(() => {                                       
    return({
      usuarios, roles, turnos, theUser,
      deptos, gpos, languages, 
      nuevo, setNuevo, editar, setEditar, borrar, setBorrar,
      isDel, setIsDel, isEdit, setIsEdit, passError, setPassError,
      selectionModel, setSelectionModel, validaPass,
      getInfo, setRoles, ordenaArrObj, getTheUser,
      registeruser, editUser, delUser
    })
  }, [usuarios, roles, turnos, nuevo, editar, borrar,
     theUser, selectionModel, passError, deptos, gpos, languages]);

  return <UsuariosContext.Provider value={UsuariosValues} {...props} />;
}

export function useUsuarios(){
  const context = useContext(UsuariosContext);
  if(!context){
    throw new Error('useUsuarios debe estar dentro del proveedor UsuariosContext');
  }
  return context;
}
