import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
//Context
import { useUsuarios } from './UsuariosContext';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        fontFamily: 'WorkSans',
        position: 'absolute',
        width: 384,
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '0px 0px 20px 0px',
        height: 570,
    },
}));

function PopUpS(props) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const { borrar, setBorrar } = useUsuarios();

    const handleOpen = () => {
        setBorrar(true);
    };

    const handleClose = () => {
        setBorrar(false);
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <div id="simple-modal-title">{props.popupTitle}</div>
            <div id="simple-modal-description">
                {props.popupBody}
                <div className="popupbottom">
                    <div className={props.popupsbclass}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className="popup-button-s"
                            onClick={handleClose}
                            fullWidth
                        >
                            {props.popupSeconButton}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <Button
                variant={props.isContained}
                color={props.colorButton}
                className={props.buttonClass}
                disabled={!props.isActive}
                onClick={handleOpen}
            >
                {props.textButton}
            </Button>
            <Modal
                open={borrar}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}

PopUpS.defaultProps = {
    popupTitle: 'Título',
    popupBody: 'Cuerpo de la ventana emergente',
    popupsbclass: 'btn-del-cnl',
    popupSeconButton: 'Cancelar',
    colorButton: 'primary',
    buttonClass: 'btn-guardar',
    textButton: 'Submit',
    isContained: 'contained',
    isActive: true,
};

export default PopUpS;
