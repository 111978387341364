import React from 'react';
//Components
import PopUp from './PopUp';
//Material-UI
import {
  Button,
  Grid, TextField,
} from '@material-ui/core'
//Material-UI
import { useCuenta } from './CuentaContext';

export default function EditContaco () {
  const { theUser, putUser } = useCuenta();

  const update = (e) => {
    e.preventDefault();
    const userEdited = {...theUser};
    userEdited.email = document.getElementById('uxTxtMail').value;
    userEdited.phoneNumber = document.getElementById('uxTxtPhoneNumber').value;
    putUser(userEdited);
  }

  return(
    <PopUp
      name="editar"
      colorButton="default"
      buttonClass="actions-edit"
      popupSeconButton="Cancelar"
      popupsbclass="popupbp"
      isContained="text"
      popupTitle={
        <div className="popuphead">
            <h2 className="popuptitle-nodesc">Editar información de Contacto</h2>
        </div>
      }
      popupBody={
        <div className="popupbody">
          <Grid container spacing={3} style={{marginBottom: 10}}>
            <Grid item md={6}>
              <TextField id="uxTxtPhoneNumber" label="Teléfono" defaultValue={theUser.phoneNumber} fullWidth />
            </Grid>
            <Grid item md={6}>
              <TextField id="uxTxtMail" label="Correo" defaultValue={theUser.email} fullWidth />
            </Grid>
          </Grid>
          <div id="popupbs">
            <Button
              color="primary"
              className="popup-button-p"
              variant="contained"
              onClick={ e => update(e) }
            >
              Guardar
            </Button>
          </div>
        </div>
      }
    />
  )
}