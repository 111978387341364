import React from 'react';
import { useEffect } from 'react';
//Context
import { HomeProvider, useHome } from './HomeContext';
//Styles
import '../../styles/home.css';
//Components
import SelectFecha from './SelectFecha';
import SelectList from './SelectList';
import CircularGraph from './CircularGraph';
import SelectLine from './SelectLine';
import GanttGraph from './GanttGraph';

function Home() {
    const { oee, mtbf, mttr } = useHome();

    return (
        <div className="homeView">
            <div className="homeView-head">
                <div className="homeView-head-titulo">
                    <h1 className="HomeView-head-titulo-h1">Panel principal</h1>
                </div>
                <div className="homeView-head-list">
                    <div className="homeView-head-selectFecha">
                        <SelectFecha />
                    </div>
                    <div className="homeView-head-selectList">
                        <SelectList />
                    </div>
                </div>
            </div>

            <div className="homeView-body">
                <div className="homeView-body-selectLine">
                    <SelectLine />
                </div>
                <div className="homeView-body-circularGraph">
                    <CircularGraph value={oee} tipo={'%'} titulo={'OEE'} showcontent={1} />
                    <CircularGraph value={mtbf} tipo={'h'} titulo={'MTBF'} showcontent={0} />
                    <CircularGraph value={mttr} tipo={'h'} titulo={'MTTR'} showcontent={0} />
                </div>
                <div className="homeView-body-GanttGraph">
                    <GanttGraph />
                </div>
            </div>
        </div>
    );
}

export default () => (
    <HomeProvider>
        <Home></Home>
    </HomeProvider>
);
