import React, { useState } from 'react';
import {
  InputLabel,
  Input,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
//Icons
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
//Context
import { useCuenta } from './CuentaContext';


function Password(props) {
  const { passError, validaPass, comparePass, modifiable, setModifiable } = useCuenta();
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const passChange = (e) => {
    handleChange('password')(e);
    let pass = e.target.value;
    if (props.id === 'uxTxtPassNueva')
      validaPass(pass);
    if (props.id === 'uxTxtPassRepetir') {
      comparePass(pass);
      if (pass === '')
        setModifiable(true);
      else
        setModifiable(false);
    }
  }

  return (
    <div>
      <InputLabel htmlFor="standard-adornment-password">
        {props.label}
      </InputLabel>
      <Input
        id={props.id}
        type={values.showPassword ? 'text' : 'password'}
        value={values.password}
        error={passError && props.error}
        fullWidth
        disabled={(props.id === 'uxTxtPassNueva' && !modifiable)}
        onChange={e => passChange(e)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              size="small"
            >
              {values.showPassword ? (
                <Visibility />
              ) : (
                <VisibilityOff />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
    </div>
  );
}

Password.defaultProps = {
  id: 'uxTxtPassword',
  label: 'Nueva Contraseña',
  error: false,
}

export default Password;