import React from 'react';
//Components
import PopUpS from './PopUpSmall';
//Material-UI
import { Button } from '@material-ui/core';
//Icons
import { CircleX } from 'tabler-icons-react';
//Context
import { useUsuarios } from './UsuariosContext';

export default function BorrarUsuario() {
    const { isDel, delUser, selectionModel, setBorrar } = useUsuarios();

    const delUsers = (event) => {
        event.preventDefault();
        selectionModel.map((item) => delUser(item));
        setBorrar(false);
    };

    return (
        <PopUpS
            colorButton="default"
            buttonClass="actions-del"
            textButton={
                <div>
                    <CircleX size={20} className="icon" /> Deshabilitar
                </div>
            }
            popupSeconButton="Cancelar"
            popupsbclass="btn-del-cnl-orderList"
            isContained="text"
            isActive={isDel}
            popupTitle={
                <div className="icon-big">
                    <CircleX size={120} color={'#7D7F90'} />
                </div>
            }
            popupBody={
                <div className="popup-delete">
                    <div className="big-message">
                        ¿Está Seguro Que Desea Deshabilitar Los Usuarios Seleccionados?
                    </div>
                    <div className="popups-button-orderList">
                        <Button
                            color="primary"
                            variant="contained"
                            className="btn-del"
                            onClick={(event) => delUsers(event)}
                        >
                            Deshabilitar
                        </Button>
                    </div>
                </div>
            }
        />
    );
}
