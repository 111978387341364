import React, { useState, useEffect } from 'react';
//Context
import { useReportes } from './ReportesContex';
//Components
import DateRangePicker from './DateRangePicker';
//Material UI
import { Button, TextField } from '@material-ui/core';
import { Eye } from 'tabler-icons-react';
import '../../styles/reportes.css';

export default function Botones() {
    const { txtRange, getReports, getCorridas } = useReportes();

    return (
        <div className="graphReport">
            <div className="datepeaker-body">
                <TextField
                    label="Rango fechas"
                    value={txtRange}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <DateRangePicker />
            </div>

            <div className="graphReport-div">
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="graphReport-button"
                    onClick={() => {
                        getReports();
                        getCorridas();
                    }}
                >
                    <div className="graphReport-button-icon">
                        <Eye size={20} />
                    </div>
                    Ver Reporte
                </Button>
            </div>
        </div>
    );
}
