import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
//Contexto
import { useReportes } from './ReportesContex';

import {
    DataGrid,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbar,
    esES,
} from '@material-ui/data-grid';
//Icons
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { TextField, IconButton, Chip } from '@material-ui/core';
import { CustomFooterTotalComponent } from './CustomFooter';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        backgroundColor: '#F2F4F8',
    },
    divider: {
        margin: 4,
    },
    textField: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        width: '99%',
    },
}));

//BEGIN SEARCH TOOLBAR
function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
    const classes = useStyles();
    return (
        <div>
            <div>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </div>
            <TextField
                variant="standard"
                fullWidth
                value={props.value}
                onChange={props.onChange}
                placeholder="Buscar..."
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon size="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon size="small" />
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

//END SEARCH TOOLBAR

export default function SkuOrden() {
    const { skuOrder } = useReportes();
    const [rows, setRows] = useState(skuOrder);
    const [total, setTotal] = useState();
    const [producido, setProducido] = useState();
    const [searchText, setSearchText] = useState('');

    //BEGIN SEARCH TOOLBAR
    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = skuOrder.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
    };

    const YellowChip = withStyles({
        root: { backgroundColor: '#f8eab7', color: '#000' },
        clickable: {},
    })((props) => <Chip color="default" {...props} />);

    const OrangeChip = withStyles({
        root: { backgroundColor: '#fca63a', color: '#000' },
        clickable: {},
    })((props) => <Chip color="default" {...props} />);

    const PurpleChip = withStyles({
        root: { backgroundColor: '#E7D4FF', color: '#000' },
        clickable: {},
    })((props) => <Chip color="default" {...props} />);

    const BlueChip = withStyles({
        root: { backgroundColor: '#AEE8FC', color: '#000' },
        clickable: {},
    })((props) => <Chip color="default" {...props} />);

    const RedChip = withStyles({
        root: { backgroundColor: '#FCCACD', color: '#000' },
        clickable: {},
    })((props) => <Chip color="default" {...props} />);

    const GreenChip = withStyles({
        root: { backgroundColor: '#95FCDE', color: '#000' },
        clickable: {},
    })((props) => <Chip color="default" {...props} />);

    const GrayChip = withStyles({
        root: { backgroundColor: '#DBE0EA', color: '#000' },
        clickable: {},
    });

    const PinkChip = withStyles({
        root: { backgroundColor: '#FFAED2', color: '#000' },
        clickable: {},
    })((props) => <Chip color="default" {...props} />);

    const columns = [
        {
            field: 'sku',
            headerName: 'sku',
            width: 150,
            editable: false,
            align: 'center',
        },
        {
            field: 'ordenProd',
            headerName: 'Orden',
            width: 200,
            editable: false,
        },
        {
            field: 'fecha',
            headerName: 'Fecha',
            width: 180,
            editable: false,
            align: 'center',
        },
        {
            field: 'cantidad',
            headerName: 'Total',
            width: 200,
            editable: false,
            align: 'center',
        },
        {
            field: 'producido',
            headerName: 'Producido',
            width: 180,
            editable: false,
            align: 'center',
        },
        {
            field: 'estatus',
            headerName: 'Estatus',
            width: 180,
            renderCell: renderStatus,
            editable: false,
            align: 'center',
        },
        {
            field: 'cliente',
            headerName: 'Cliente',
            width: 300,
            editable: false,
            align: 'center',
        },
    ];

    function renderStatus(params) {
        switch (params.value) {
            case 1:
                return <YellowChip label="Pendiente" />;
            case 2:
                return <PurpleChip label="En Proceso" />;
            case 3:
                return <BlueChip label="Completada" />;
            case 4:
                return <RedChip label="Cerrada" />;
            case 5:
                return <RedChip label="Cancelada" />;
            case 6:
                return <PurpleChip label="Programada" />;
            case 7:
                return <GreenChip label="Entregada" />;
            case 8:
                return <PinkChip label="En almacen" />;
            case 9:
                return <OrangeChip label="Incompleta" />;
            default:
                return <GrayChip label="Pendiente" />;
        }
    }

    useEffect(() => {
        setRows(skuOrder);
    }, [skuOrder]);

    return (
        <div style={{ height: 700, width: '100%', marginLeft: '30px' }}>
            <DataGrid
                components={{ Toolbar: QuickSearchToolbar }}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (event) => requestSearch(event.target.value),
                        clearSearch: () => requestSearch(''),
                    },
                }}
                rows={rows}
                disableDensitySelector
                localeText={esES.props.MuiDataGrid.localeText}
                columns={columns}
                checkboxSelection={false}
                components={{
                    Toolbar: GridToolbar,
                    Footer: CustomFooterTotalComponent,
                }}
                componentsProps={{
                    footer: { total, producido },
                }}
                pageSize={100}
                disableSelectionOnClick
                onStateChange={(state) => {
                    let producido = 0;
                    let total = 0;

                    if (state.state.filter.items.length !== 0) {
                        if (state.state.filter.items[0].value !== undefined) {
                            const visibleRows = state.state.visibleRows.visibleRowsLookup;

                            for (const [id, value] of Object.entries(visibleRows)) {
                                if (value === true) {
                                    producido = producido + skuOrder[parseInt(id)].producido;
                                    total = total + skuOrder[parseInt(id)].cantidad;
                                }
                            }
                        } else {
                            skuOrder.map((sOrder) => {
                                producido = producido + sOrder.producido;
                                total = total + sOrder.cantidad;
                            });
                        }
                    } else {
                        skuOrder.map((sOrder) => {
                            producido = producido + sOrder.producido;
                            total = total + sOrder.cantidad;
                        });
                    }

                    setTotal(total);
                    setProducido(producido);
                }}
            />
        </div>
    );
}
