import React, { useState } from 'react';

// Material UI
import { Divider, List } from '@material-ui/core';

//Components
import RecursiveListNode from './RecursiveListNode';
import { routeList, cerrarSesion } from '../../routes/routeList';

// Styles
import LogoN from '../../logos/LogoN.svg';
import LogoNovaLight from '../../logos/LogoNovaLight.svg';
import '../../styles/menu.css';
import StyledDrawer from './StyledDrawer';
import { getLogo } from '../../logos';

const Menu = () => {
    const [selectedItem, setSelectedItem] = useState();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [openItem, setOpenItem] = useState();
    const logo = drawerOpen ? getLogo(process.env.REACT_APP_THEME) : LogoN;
    const showSecondaryLogo =
        (process.env.REACT_APP_THEME === 'tecnoTheme' && drawerOpen) ||
        (process.env.REACT_APP_THEME === 'colTheme' && drawerOpen) ||
        (process.env.REACT_APP_THEME === 'matteTheme' && drawerOpen) ||
        (process.env.REACT_APP_THEME === 'skyTheme' && drawerOpen);

    return (
        <div className="menuView">
            <StyledDrawer open={drawerOpen}>
                <div
                    className={`menu-logo__wrapper ${drawerOpen ? '' : 'closed'}`}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                >
                    <img src={logo} className="logo-pp" alt="logo-pp" />
                </div>
                <Divider />
                <List component="div" disablePadding>
                    {routeList.map((item, idx) => (
                        <RecursiveListNode
                            node={item}
                            key={idx}
                            drawerOpen={drawerOpen}
                            openItem={openItem}
                            setDrawerOpen={setDrawerOpen}
                            setOpenItem={setOpenItem}
                            selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                        />
                    ))}
                </List>

                <div className="menu-logo-nova__wrapper">
                    {showSecondaryLogo && (
                        <div>
                            <img src={LogoNovaLight} className="logo-nova" alt="logo-nova" />
                        </div>
                    )}
                </div>
                <Divider />
                <div className="LogOut">
                    <Divider />
                    <List component="div" disablePadding>
                        {cerrarSesion.map((item, idx) => (
                            <RecursiveListNode
                                node={item}
                                key={idx}
                                openItem={openItem}
                                setOpenItem={setOpenItem}
                                selectedItem={selectedItem}
                                setSelectedItem={setSelectedItem}
                            />
                        ))}
                    </List>
                </div>
            </StyledDrawer>
        </div>
    );
};

export default Menu;
