import React, { useEffect, useState } from 'react';
//Context
import { useHome } from './HomeContext';
//Styles
import '../../styles/home.css';
//material UI
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

export default function SelectLine() {
    const { lines, lineSelect, setLineSelect } = useHome();

    return (
        <div>
            <FormControl className="selectLine">
                <InputLabel id="demo-simple-select-label">Seleccione una Línea</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={lineSelect}
                    onChange={(e) => setLineSelect(e.target.value)}
                >
                    {/* <MenuItem value={0} key={0}>
                        Todas las Lineas
                    </MenuItem> */}
                    {lines.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                            {item.nombre}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
