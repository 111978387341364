import React from 'react';
//Components
import PopUpS from './popupsmall';
//Material-UI
import { Button } from '@material-ui/core';
//Icons
import { Trash } from 'tabler-icons-react';
//Context
import { useBeepers } from './BeepersContext';

export default function BorrarBeeper() {
  const { delBeeper, theBeeper, setBorrarBeeper } = useBeepers();

  const eliminarBeeper = (event) => {
    event.preventDefault();
    delBeeper(theBeeper.beeper1)
    setBorrarBeeper(false);
  };

  return (
    <PopUpS
      name="beeper"
      popupSeconButton="Cancelar"
      popupsbclass="btn-del-cnl-orderList"
      popupTitle={
        <div className="icon-big">
          <Trash size={120} color={'#7D7F90'} />
        </div>
      }
      popupBody={
        <div className="popup-delete">
          <div className="big-message">
            ¿Está Seguro Que Desea Borrar El Beeper Seleccionado?
          </div>
          <div className="popups-button-orderList">
            <Button
              color="primary"
              variant="contained"
              className="btn-del"
              onClick={(event) => eliminarBeeper(event)}
            >
              Borrar
            </Button>
          </div>
        </div>
      }
    />
  );
}
