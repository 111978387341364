import React from 'react';
//Components
import PopUp from './popup';
//Material-UI
import { TextField, Button } from '@material-ui/core';
//Icons
import { CirclePlus } from 'tabler-icons-react';
//Context
import { useProductos } from './ProductosContext';

export default function NuevoProducto() {
    const { postNewProd, handleinputChange, setNuevo } = useProductos();
    const createOrd = (e) => {
        e.preventDefault();
        postNewProd(e);
    };
    return (
        <PopUp
            name="nuevo"
            buttonClass="productos-button"
            colorButton="primary"
            textButton={
                <div>
                    <CirclePlus size={18} className="icon" /> Nuevo Producto
                </div>
            }
            popupSeconButton="Cancelar"
            popupTitle={
                <div className="popuphead">
                    <h2 className="popuptitle">Nuevo Producto</h2>
                    <p className="popuptitled">
                        Crear un nuevo producto definiendo su nombre y código SKU y añádelo al
                        catálogo
                    </p>
                </div>
            }
            popupBody={
                <div className="popupbody">
                    <div className="popuptextfield">
                        <TextField
                            id="uxTxtOrdenProd"
                            name="nombre"
                            label="NOMBRE DEL PRODUCTO"
                            fullWidth
                            onChange={handleinputChange}
                        />
                    </div>
                    <div className="popuptextfield">
                        <TextField
                            id="uxTxtProducto"
                            name="sku"
                            label="CLAVE DEL PRODUCTO SKU"
                            fullWidth
                            onChange={handleinputChange}
                        />
                    </div>
                    <div className="popuptextfield">
                        <TextField
                            id="uxTxtPzasPaq"
                            name="piezasPorPaquete"
                            label="PIEZAS/PAQUETE"
                            fullWidth
                            onChange={handleinputChange}
                        />
                    </div>
                    <div className="popuptextfield">
                        <TextField
                            id="uxTxtPzasTar"
                            name="paquetesPorTarima"
                            label="PAQUETES/TARIMA"
                            fullWidth
                            onChange={handleinputChange}
                        />
                    </div>
                    <div id="popupbs">
                        <Button
                            color="primary"
                            variant="contained"
                            className="popup-button-p"
                            onClick={(e) => createOrd(e)}
                        >
                            Crear nuevo Producto
                        </Button>
                    </div>
                </div>
            }
        />
    );
}
