import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import '../App.css';
import '../popup.css';

function getModalStyle() {
    const top = 52;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        fontFamily: 'WorkSans',
        position: 'absolute',
        width: 588,

        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '0px 0px 0px 0px',
    },
}));

const useStyles1 = makeStyles((theme) => ({
    paper: {
        fontFamily: 'WorkSans',
        position: 'absolute',
        width: 1300,
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '0px 0px 0px 0px',
    },
}));

function ModalR(props) {
    const classes = useStyles();
    const classes1 = useStyles1();
    const [modalStyle] = React.useState(getModalStyle);
    const selcClass = props.popupFlag ? classes1.paper : classes.paper;

    const body = (
        <div style={modalStyle} className={selcClass}>
            <div id="simple-modal-title">{props.popupTitle}</div>
            <div id="simple-modal-description">{props.popupBody}</div>
        </div>
    );

    return (
        <div>
            <Modal
                open={props.open}
                // onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}

ModalR.defaultProps = {
    textButton: 'Agregar',
    colorButton: 'primary',
    popupTitle: 'Titulo',
    popupPrimButton: 'Aceptar',
    popupSeconButton: 'Cancelar',
    popupsbclass: 'popupbp',
    isContained: 'contained',
};

export default ModalR;
