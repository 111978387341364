import React from 'react';
//Components
import PopUpS from './popupsmall';
//Material-UI
import { Button } from '@material-ui/core';
//Icons
import { Trash } from 'tabler-icons-react';
//Context
import { useBeepers } from './BeepersContext';

export default function BorrarComentario() {
  const { putBeeper, setBorrarComent, theBeeper, } = useBeepers();

  const editComent = (event) => {
    event.preventDefault();
    const data = {
      "beeper": theBeeper.beeper1,
      "areaAtencion": theBeeper.areaAtencion,
      "nivel1": theBeeper.nivel1,
      "nivel2": theBeeper.nivel2,
      "nivel3": theBeeper.nivel3,
      "usarSonido": theBeeper.usarSonido,
      "comentario": "",
    };
    putBeeper(data);
    setBorrarComent(false);
  }

  return (
    <PopUpS
      name="comment"
      popupSeconButton="Cancelar"
      popupsbclass="btn-del-cnl-orderList"
      popupTitle={
        <div className="icon-big">
          <Trash size={120} color={'#7D7F90'} />
        </div>
      }
      popupBody={
        <div className="popup-delete">
          <div className="big-message">
            ¿Está Seguro Que Desea Borrar El Comentario de este Beeper?
          </div>
          <div className="popups-button-orderList">
            <Button
              color="primary"
              variant="contained"
              className="btn-del"
              onClick={(event) => editComent(event)}
            >
              Borrar
            </Button>
          </div>
        </div>
      }
    />
  );
}
