import { createContext, useMemo, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LogOut from '../routes/LogOut';

const GeneralContext = createContext();

export function GeneralProvider(props) {
    const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));

    const [infoUser, setInfoUser] = useState([]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (JSON.parse(sessionStorage.getItem('token'))) {
                const token = tokenSessionObject.expiracion;
                const diff = new Date(token) - new Date(Date.now());

                if (diff <= 0) {
                    sessionStorage.clear();
                    window.location.replace('/');
                }
            }
        }, 15020);
        return () => clearInterval(interval);
    }, [infoUser]);

    const generalValues = useMemo(() => {
        return {
            infoUser,
            setInfoUser,
        };
    }, [infoUser, setInfoUser]);

    return <GeneralContext.Provider value={generalValues} {...props} />;
}

export function useGeneral() {
    const context = useContext(GeneralContext);
    if (!context) {
        throw new Error('useGeneral debe estar dentro del proveedor GeneralContext');
    }
    return context;
}
