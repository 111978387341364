import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//Material-UI
import { TextField, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    DataGrid,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    esES,
} from '@material-ui/data-grid';
//Icons
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
//Context
import { useProductos } from './ProductosContext';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        backgroundColor: '#F2F4F8',
    },
    divider: {
        margin: 4,
    },
    textField: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        width: '99%',
    },
}));

//BEGIN SEARCH TOOLBAR
function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
    const classes = useStyles();
    return (
        <div>
            <div>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </div>
            <TextField
                variant="standard"
                fullWidth
                value={props.value}
                onChange={props.onChange}
                placeholder="Buscar..."
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon size="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon size="small" />
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

//END SEARCH TOOLBAR

function TablaProductos() {
    const {
        product,
        setSelectedIndex,
        handleListItemClick,
        setActions,
        getTheProduct,
        setSelectionModel,
        editProduct,
        setEditProduct,
        searchText,
        setSearchText,
        setIsProdSelected,
        setChecked,
    } = useProductos();

    const [rows, setRows] = useState(product);
    const [seleccionFila, setSeleccionFila] = useState([]);
    const tableColumns = [
        { field: 'sku', headerName: 'Código', width: 200 },
        { field: 'nombre', headerName: 'Nombre', width: 400 },
        { field: 'piezasPorPaquete', headerName: 'Pzas/paquete', width: 175, align: 'center' },
        { field: 'paquetesPorTarima', headerName: 'Paq/tarima', width: 175, align: 'center' },
    ];

    //BEGIN SEARCH TOOLBAR
    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = product.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
        setIsProdSelected(true);
        setChecked([]);
    };

    useEffect(() => {
        let data = [];
        product.map((prod) => {
            data.push({
                sku: prod.sku,
                nombre: prod.nombre,
                tipo: prod.tipo,
                unidadInv: prod.unidadInv,
                piezasPorPaquete: prod.piezasPorPaquete,
                paquetesPorTarima: prod.paquetesPorTarima,
                cantPorPieza: prod.paquetesPorTarima,
                id: prod.sku,
            });
        });

        setRows(data);
    }, [product]);
    //END SEARCH TOOLBAR

    return (
        <DataGrid
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
                toolbar: {
                    value: searchText,
                    onChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch(''),
                },
            }}
            disableColumnFilter
            disableDensitySelector
            columns={tableColumns}
            localeText={esES.props.MuiDataGrid.localeText}
            rows={rows}
            selectionModel={seleccionFila}
            onSelectionModelChange={(newSelectionModel) => {
                console.log(newSelectionModel);
                if (newSelectionModel.length > 0 && newSelectionModel[0] !== seleccionFila[0]) {
                    setActions(true);
                    const sku = newSelectionModel[0];
                    setSelectedIndex(sku);
                    handleListItemClick(sku);
                    const selectProd = product.filter((prod) => prod.sku === sku);
                    console.log(selectProd);
                    setEditProduct({
                        eNombre: selectProd[0].nombre,
                        EPP: selectProd[0].piezasPorPaquete,
                        EPT: selectProd[0].paquetesPorTarima,
                        sku: selectProd[0].sku,
                    });
                    setSeleccionFila(newSelectionModel);
                } else {
                    setActions(false);
                    setSeleccionFila([]);
                    setIsProdSelected(true);
                    setChecked([]);
                }
            }}
            hideFooterSelectedRowCount={true}
        />
    );
}

export default TablaProductos;
