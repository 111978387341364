import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { ListItem, List, ListItemIcon, ListItemText, Grid } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FormControlLabel } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//Components & Hooks
import { useHistory } from 'react-router-dom';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let primColor,
    iconColor,
    iconColorDes = '';
if (process.env.REACT_APP_THEME === 'tecnoTheme') {
    primColor = '#253139';
    iconColor = '#EB7D1E';
    iconColorDes = '#FFFFFF';
} else if (process.env.REACT_APP_THEME === 'colTheme') {
    primColor = '#253139';
    iconColor = '#58ab01';
    iconColorDes = '#FFFFFF';
} else if (process.env.REACT_APP_THEME === 'matteTheme') {
    primColor = '#253139';
    iconColor = '#EB7D1E';
    iconColorDes = '#FFFFFF';
} else if (process.env.REACT_APP_THEME === 'skyTheme') {
    primColor = '#001B69';
    iconColor = '#3f4a51';
    iconColorDes = '#FFFFFF';
} else {
    primColor = '#472A7A';
    iconColor = '#FFC800';
    iconColorDes = '#C8AFE8';
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: '12px',
    },
    listItem: {
        paddingLeft: '30px',
        '&:hover': {
            backgroundColor: primColor,
        },
    },
    root: {
        paddingTop: '16px',
        paddingBottom: '16px',
        '&$selected': {
            backgroundColor: primColor,
            '&:hover': {
                backgroundColor: primColor,
            },
        },
    },
    selected: {},
    listIcon: {
        minWidth: '32px',
        height: '32px',
        marginRight: (props) => (props.drawerOpen ? '4px' : '22px'),
        transition: 'all 0.2s ease-in-out',
    },
    listText: {
        color: '#FFF',
    },
    paper: {
        fontFamily: 'WorkSans',
        position: 'absolute',
        width: 720,
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '0px 0px 0px 0px',
    },
}));

const RecursiveListNode = ({
    isChild,
    node,
    drawerOpen,
    openItem,
    selectedItem,
    setDrawerOpen,
    setOpenItem,
    setSelectedItem,
}) => {
    const { children, icon, id, path, text } = node;
    const isSelected = id === selectedItem;
    const isOpen = openItem === id;

    const [value, setValue] = useState('');

    const history = useHistory();
    const classes = useStyles({ drawerOpen });
    const IconComponent = icon;
    const [open, setOpen] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    let flag = true;

    const handleOpen = (id) => {
        setOpenItem(id);
        setSelectedItem(id);
        setDrawerOpen(true);
    };

    const handleClose = () => {
        flag = !flag;
        setOpen(false);
    };

    const handleClickList = (id) => {
        if (id !== 'parametros-ritmo') {
            if (!isChild) {
                setOpenItem('');
            }
            setSelectedItem(id);
            history.push(path);
        } else {
            if (flag === true) {
                setOpen(true);
            } else flag = !flag;
        }
    };

    const handleSnackClose = () => {
        setOpenSnack(false);
        setOpen(false);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));
    const getRitmo = async () => {
        const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/ritmo`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
        });
        if (data !== null) {
            const obj = await data.json();
            setValue(obj.sValor);
        }
    };

    const putRitm = () => {
        fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/ritmo?nEstado=${value}`, {
            method: 'PUT',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
        })
            .then((res) => res.json())
            .catch((error) => console.error('Error: ', error))
            .then((response) => {
                setSnackMsg(response);
                setOpenSnack(true);
            });
    };

    const stdRythm = (event) => {
        event.preventDefault();
        putRitm();
        setOpen(false);
    };

    const [modalStyle] = React.useState(getModalStyle);

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <Grid container>
                <Grid item md={12} className="popuphead" style={{ paddingBottom: 100 }}>
                    <h2 className="popuptitle">Ritmo Base</h2>
                    <p className="popuptitled">
                        Configuración de ritmo base para realizar el cálculo OEEE
                    </p>
                </Grid>
                <Grid item md={12} className="popupbody">
                    Especifique que tasa debe usar el sistema para evaluar los cálculos de OEE. El
                    ritmo seleccionado surte efecto al momento de iniciar una corrida de producción.
                    <Grid container style={{ marginTop: 25, marginBottom: 50 }}>
                        <Grid item md={12}>
                            <RadioGroup
                                aria-label="Especifique la tasa"
                                value={value}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value="ideal"
                                    control={<Radio color="primary" />}
                                    label={<b>Ritmo ideal</b>}
                                />
                                <p>
                                    <b>Es la velocidad máxima de la máquina según el fabricante.</b>
                                    <br></br>
                                    Este es el valor comúnmente utilizado para el cálculo de OEE,
                                    suele resultar en valores de OEE más bajos ya que normalmente es
                                    un parámetro más exigente, es decir, un ritmo más veloz.
                                </p>
                                <FormControlLabel
                                    value="estandar"
                                    control={<Radio color="primary" />}
                                    label={<b>Ritmo Estandar</b>}
                                />
                                <p>
                                    <b>Es la velocidad normal de operación de la máquina.</b>
                                    <br></br>
                                    Este valor es el utilizado para estimar la duración de las
                                    corridas en el calendario, y es una velocidad que se alcanza
                                    comúnmente, suele resultar en valores de OEE más altos.
                                </p>
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <div id="popupbs">
                        <Button
                            color="primary"
                            variant="contained"
                            className="popup-button-p"
                            onClick={(e) => stdRythm(e)}
                        >
                            Establecer ritmo
                        </Button>
                    </div>
                </Grid>
                <Grid item md={12} className="popupbottom">
                    <div className="popupbp">
                        <Button
                            variant="outlined"
                            color="primary"
                            className="popup-button-s"
                            fullWidth
                            onClick={handleClose}
                        >
                            Cancelar
                        </Button>
                    </div>
                </Grid>
                <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity="success">
                        {snackMsg}
                    </Alert>
                </Snackbar>
            </Grid>
        </div>
    );

    useEffect(() => {
        if (id === 'parametros-ritmo') {
            if (value === '') {
                getRitmo();
            }
        }
    }, []);

    if (!children) {
        return (
            <ListItem
                button
                onClick={() => handleClickList(id)}
                className={classes.listItem}
                classes={{ root: classes.root, selected: classes.selected }}
                selected={isSelected}
            >
                <ListItemIcon className={classes.listIcon}>
                    <IconComponent color={isSelected ? iconColor : iconColorDes} />
                </ListItemIcon>
                <ListItemText primary={text} className={isSelected ? classes.listText : ''} />
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
            </ListItem>
        );
    }
    return (
        <div className={isOpen ? 'menu-item__collapse-wrapper' : undefined}>
            <ListItem
                button
                onClick={() => handleOpen(id)}
                className={classes.listItem}
                classes={{ root: classes.root, selected: classes.selected }}
                selected={isSelected}
            >
                <ListItemIcon className={classes.listIcon}>
                    <IconComponent color={isOpen || isSelected ? iconColor : iconColorDes} />
                </ListItemIcon>
                <ListItemText
                    primary={text}
                    className={isOpen || isSelected ? classes.listText : ''}
                />
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.nested}>
                    {children.map((item, idx) => (
                        <RecursiveListNode
                            node={item}
                            key={idx + id}
                            selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                            isChild={true}
                        />
                    ))}
                </List>
            </Collapse>
        </div>
    );
};

RecursiveListNode.propTypes = {
    node: PropTypes.shape({
        children: PropTypes.array,
        icon: PropTypes.func,
        id: PropTypes.string,
        path: PropTypes.string,
        text: PropTypes.string,
    }),
    selectedItem: PropTypes.string,
    setSelectedItem: PropTypes.func,
    openItem: PropTypes.string,
    drawerOpen: PropTypes.bool,
    setDrawerOpen: PropTypes.func,
    setOpenItem: PropTypes.func,
    isChild: PropTypes.bool,
};

export default RecursiveListNode;
/*
    This Component intention is to use recursion to render a collection of list item,
    If the node has a children, it will create a <Collapse> item from Material UI
    the data structure of this component is listed in the proptypes.
*/
