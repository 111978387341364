import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//Material-UI
import { TextField, IconButton, Chip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    DataGrid,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    esES,
} from '@material-ui/data-grid';
//Icons
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
//Context
import { useOrdProd } from './OrdProdContext';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        backgroundColor: '#F2F4F8',
    },
    divider: {
        margin: 4,
    },
    textField: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        width: '99%',
    },
}));

//BEGIN SEARCH TOOLBAR
function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
    const classes = useStyles();
    return (
        <div>
            <div>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </div>
            <TextField
                variant="standard"
                fullWidth
                value={props.value}
                onChange={props.onChange}
                placeholder="Buscar..."
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon size="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon size="small" />
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

//END SEARCH TOOLBAR

function TablaOrd() {
    const {
        orden,
        getTheOrder,
        setActions,
        selectionModel,
        setSelectionModel,
        setIsEdit,
        setIsDel,
    } = useOrdProd();
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState(orden);

    const YellowChip = withStyles({
        root: { backgroundColor: '#f8eab7', color: '#000' },
        clickable: {},
    })((props) => <Chip color="default" {...props} />);

    const OrangeChip = withStyles({
        root: { backgroundColor: '#fca63a', color: '#000' },
        clickable: {},
    })((props) => <Chip color="default" {...props} />);

    const PurpleChip = withStyles({
        root: { backgroundColor: '#E7D4FF', color: '#000' },
        clickable: {},
    })((props) => <Chip color="default" {...props} />);

    const BlueChip = withStyles({
        root: { backgroundColor: '#AEE8FC', color: '#000' },
        clickable: {},
    })((props) => <Chip color="default" {...props} />);

    const RedChip = withStyles({
        root: { backgroundColor: '#FCCACD', color: '#000' },
        clickable: {},
    })((props) => <Chip color="default" {...props} />);

    const GreenChip = withStyles({
        root: { backgroundColor: '#95FCDE', color: '#000' },
        clickable: {},
    })((props) => <Chip color="default" {...props} />);

    const GrayChip = withStyles({
        root: { backgroundColor: '#DBE0EA', color: '#000' },
        clickable: {},
    });

    const PinkChip = withStyles({
        root: { backgroundColor: '#FFAED2', color: '#000' },
        clickable: {},
    })((props) => <Chip color="default" {...props} />);

    function renderStatus(params) {
        switch (params.value) {
            case 1:
                return <YellowChip label="Pendiente" />;
            case 2:
                return <PurpleChip label="En Proceso" />;
            case 3:
                return <BlueChip label="Completada" />;
            case 4:
                return <RedChip label="Cerrada" />;
            case 5:
                return <RedChip label="Cancelada" />;
            case 6:
                return <PurpleChip label="Programada" />;
            case 7:
                return <GreenChip label="Entregada" />;
            case 8:
                return <PinkChip label="En almacen" />;
            case 9:
                return <OrangeChip label="Incompleta" />;
            default:
                return <GrayChip label="Pendiente" />;
        }
    }

    const tableColumns = [
        { field: 'id', headerName: 'Código', width: 150 },
        {
            field: 'estatus',
            headerName: 'Estatus',
            renderCell: renderStatus,
            align: 'center',
            width: 150,
        },
        { field: 'sku', headerName: 'SKU', width: 200 },
        { field: 'skuNombre', headerName: 'Nombre Producto', width: 250 },
        { field: 'cantidad', headerName: 'Total', align: 'center', width: 135 },
        { field: 'producido', headerName: 'Producido', align: 'center', width: 145 },
        {
            field: 'cantidadCalendario',
            headerName: 'Cantidad en Calendario',
            align: 'center',
            width: 230,
        },
        { field: 'resta', headerName: 'Restan', align: 'center', width: 135 },
        { field: 'fecha', headerName: 'Fecha Creación', align: 'center', width: 180 },
        { field: 'cliente', headerName: 'Cliente', width: 330 },
        { field: 'fechaEntrega', headerName: 'Fecha Entrega', align: 'center', width: 200 },
    ];

    //BEGIN SEARCH TOOLBAR
    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = orden.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
    };

    useEffect(() => {
        setRows(orden);
    }, [orden]);
    //END SEARCH TOOLBAR

    return (
        <DataGrid
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
                toolbar: {
                    value: searchText,
                    onChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch(''),
                },
            }}
            disableColumnFilter
            disableDensitySelector
            checkboxSelection
            columns={tableColumns}
            localeText={esES.props.MuiDataGrid.localeText}
            rows={rows}
            onSelectionModelChange={async (newSelectionModel) => {
                if (newSelectionModel.length > 0) {
                    if (newSelectionModel.length > 1) setIsEdit(false);
                    else setIsEdit(true);
                    setIsDel(true);
                    getTheOrder(newSelectionModel[0]);
                } else {
                    setIsDel(false);
                    setIsEdit(false);
                }
                setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            hideFooterSelectedRowCount={true}
        />
    );
}

export default TablaOrd;
