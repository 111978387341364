import React, { useState, useEffect } from "react";
//Components
import PopUp from "./PopUp";
import GeneralEdit from "./GeneralEdit";
import TipoEdit from "./TipoEdit";
//Material-UI
import { Button, Grid } from "@material-ui/core";
import { IconButton } from '@material-ui/core';
//Icons
import { X } from 'tabler-icons-react';
import { Edit } from 'tabler-icons-react';
//Context
import { useLlamadas } from './LlamadasContext';
import LevantarNotificarEdit from "./LevantarNotificarEdit";


function EditarTipoLlamada() {
  const { step, setStep, setEditar, isEdit, putCallType, theCallType, setNewCallType, clear } = useLlamadas();
  const [primaryButton, setPrimaryButton] = useState('Siguiente');
  const [secondaryBtn, setSecondaryBtn] = useState('Cancelar');
  const increment = (e) => {
    e.preventDefault();
    if (step < 2)
      setStep(step + 1);
    else if (step > 1)
      putCallType();
  };
  const secondAction = () => {
    if (step > 0)
      setStep(step - 1);
    else{
      setEditar(false);
      clear();
    }
  }
  const handleClose = (event) => {
    event.preventDefault();
    setEditar(false);
    setStep(0);
    clear();
  }
  useEffect(() => {
    if (step > 0)
      setSecondaryBtn('Atrás');
    else
      setSecondaryBtn('Cancelar');
    if (step > 1)
      setPrimaryButton('Finalizar');
    else
      setPrimaryButton('Siguiente');
  }, [step]);

  useEffect(() => {
    setNewCallType(theCallType);
  }, [theCallType]);

  return (
    <PopUp
      name="editar"
      buttonClass="actions-edit"
      colorButton="default"
      isContained="text"
      isActive = { isEdit }
      textButton={
        <div>
          <div><Edit size={18} className="icon" /> Editar </div>
        </div>
      }
      popupTitle={ 
        <div className="popupheadCalltype">
          <h2 className="popuptitle">
            Editar Tipo de Llamada
          </h2>
          <IconButton className="exClose" onClick={(handleClose)}>
            <X />
          </IconButton>
        </div>
      }
      popupBody={
        <div>
          {
            (step === 0)
              ? <GeneralEdit /> :
              (step === 1)
                ? <TipoEdit /> :
                (step === 2)
                  ? <LevantarNotificarEdit />
                  : <h1>Error</h1>
          }
          <Grid
            container
            spacing={1}
            className='popupButtons'
          >
            <Grid item md={10}>
              <Button
                variant="outlined"
                color="primary"
                className="popup-button-s"
                onClick={secondAction}
              >
                {secondaryBtn}
              </Button>
            </Grid>
            <Grid item md={2}>
              <Button
                className="popup-button-s"
                variant="contained"
                color="primary"
                onClick={e => increment(e)}
              >
                {primaryButton}
              </Button>
            </Grid>
          </Grid>
        </div>
      }
    />
  )
}

export default EditarTipoLlamada;