import React, { useEffect } from 'react';
//Material UI
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Button } from '@material-ui/core';
//Components
import Turno from './shift.jsx';
import Schedule from './schedule.jsx';
//Styles
import '../../styles/parametros.css';
import { makeStyles } from '@material-ui/styles';
//Context
import { ShiftsProvider, useShifts } from './ShiftsContext';

let primColor = '';
if (process.env.REACT_APP_THEME === 'tecnoTheme') primColor = '#253139';
else if (process.env.REACT_APP_THEME === 'colTheme') {
    primColor = '#58ab01';
} else if (process.env.REACT_APP_THEME === 'matteTheme') {
    primColor = '#253139';
} else primColor = '#903BFF';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 485,
    },
    horizontal: {
        height: 483,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 23,
        backgroundColor: '#F2F4F8',
        overflow: 'auto',
        width: '100%',
        minWidth: 500,
        marginTop: 25,
        marginBottom: 25,
    },
}));

const listStyles = makeStyles(() => ({
    root: {
        '&$selected': {
            backgroundColor: primColor,
            color: '#FFFF',
            '&:hover': {
                backgroundColor: primColor,
            },
        },
    },
    selected: {},
}));

export default () => (
    <ShiftsProvider>
        <Turnos></Turnos>
    </ShiftsProvider>
);

function Turnos() {
    const {
        btnShifts,
        setBtnShifts,
        lines,
        selectedLine,
        setSelectedLine,
        setFirst,
        selectedShift,
        setSelectedShift,
        lineName,
        setLineName,
        schedules,
        setSchedules,
        getLines,
        getShifts,
        getSchedule,
        timePickActivos,
        putShifts,
    } = useShifts();

    useEffect(() => {
        getLines();
    }, []);

    const handleListLinesClick = (event, element) => {
        event.preventDefault();
        setSelectedLine(element.id);
        setLineName(element.nombre);
        getShifts(element.id);
        setSchedules([]);
        setSelectedShift(-1);
    };

    const handleListShiftsClick = (event, id) => {
        event.preventDefault();
        setSelectedShift(id);
        getSchedule(selectedLine, id + 1);
    };

    const editShift = (event) => {
        event.preventDefault();
        timePickActivos.forEach((item) => {
            if (item.id in [1, 2, 3]) {
                putShifts(selectedLine, selectedShift + 1, item.valor);
            }
        });
    };

    const cancelBtn = (event) => {
        event.preventDefault();
        setFirst('00');
        getShifts(selectedLine);
        setBtnShifts(true);
    };

    const classes = useStyles();
    const classes2 = listStyles();

    return (
        <Grid container className="doc">
            <Grid item md={12} className="head">
                <h1 className="titulo">Turnos</h1>
                <p className="desc">
                    Especifica la hora oficial en la cual inicia un turno, tambien elige la duración
                    del mismo
                </p>
            </Grid>
            <Grid item md={12} className="body">
                <Grid container spacing={2} alignItems="stretch">
                    <Grid item md={3}>
                        <div className="header-section">Seleccionar Línea</div>
                        <div className="linesList">
                            <List className={classes.root}>
                                {lines.map((item) => {
                                    return (
                                        <div>
                                            <ListItem
                                                classes={{
                                                    root: classes2.root,
                                                    selected: classes2.selected,
                                                }}
                                                key={item.id.toString()}
                                                button
                                                selected={selectedLine === item.id}
                                                onClick={(event) =>
                                                    handleListLinesClick(event, item)
                                                }
                                            >
                                                <ListItemText primary={item.nombre} />
                                            </ListItem>
                                            <Divider light />
                                        </div>
                                    );
                                })}
                            </List>
                        </div>
                    </Grid>
                    <Grid item md={9}>
                        <div className="header-section center">{lineName}</div>
                        <Grid container spacing={3}>
                            <Grid item md={4}>
                                <div className="shifts">
                                    <List>
                                        <ListItem
                                            key={'1'}
                                            button
                                            selected={selectedShift === 0}
                                            onClick={(event) => handleListShiftsClick(event, 0)}
                                        >
                                            <Turno title="Primer turno" id={1} />
                                        </ListItem>
                                        <Divider light />
                                        <ListItem
                                            key={'2'}
                                            button
                                            selected={selectedShift === 1}
                                            onClick={(event) => handleListShiftsClick(event, 1)}
                                        >
                                            <Turno title="Segundo turno" id={2} />
                                        </ListItem>
                                        <Divider light />
                                        <ListItem
                                            key={'3'}
                                            button
                                            selected={selectedShift === 2}
                                            onClick={(event) => handleListShiftsClick(event, 2)}
                                        >
                                            <Turno title="Tercer turno" id={3} />
                                        </ListItem>
                                    </List>
                                    <Grid container spacing={2} className="center">
                                        <Grid item md={6}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                className="btn"
                                                onClick={(event) => cancelBtn(event)}
                                            >
                                                Cancelar
                                            </Button>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                className="btn"
                                                disabled={btnShifts}
                                                onClick={(event) => editShift(event)}
                                            >
                                                Guardar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item md={8}>
                                <Grid container spacing={2} className={classes.horizontal}>
                                    {schedules.map((item, i) => {
                                        const id = `${selectedShift}${i}`;
                                        return (
                                            <Grid item md={6}>
                                                <Schedule
                                                    title={`Horario ${i + 1}`}
                                                    horaIni={item.inicio}
                                                    horaFin={item.fin}
                                                    id={id}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
