import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import '../../App.css';
import { useOrdProd } from './OrdProdContext';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        fontFamily: 'WorkSans',
        position: 'absolute',
        width: 588,

        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '0px 0px 0px 0px',
    },
}));

const useStyles1 = makeStyles((theme) => ({
    paper: {
        fontFamily: 'WorkSans',
        position: 'absolute',
        width: 1050,
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '0px 0px 0px 0px',
    },
}));

function PopUp(props) {
    const { importOrd, setImportOrd, nuevo, productos, setNuevo, setProductos, editar, setEditar } =
        useOrdProd();
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const classes1 = useStyles1();
    const [modalStyle] = React.useState(getModalStyle);
    const selcClass = props.popupFlag ? classes1.paper : classes.paper;

    useEffect(() => {
        switch (props.name) {
            case 'import':
                setOpen(importOrd);
                break;
            case 'nuevo':
                setOpen(nuevo);
                break;
            case 'productos':
                setOpen(productos);
                break;
            case 'editar':
                setOpen(editar);
                break;
            default:
                break;
        }
    }, [importOrd, nuevo, productos, editar]);

    const handleOpen = () => {
        switch (props.name) {
            case 'import':
                setImportOrd(true);
                setProductos(false);
                setNuevo(false);
                setEditar(false);
                break;
            case 'nuevo':
                setNuevo(true);
                setImportOrd(false);
                setProductos(false);
                setEditar(false);
                break;
            case 'productos':
                setProductos(true);
                setNuevo(true);
                setImportOrd(false);
                setEditar(false);
                break;
            case 'editar':
                setEditar(true);
                setImportOrd(false);
                setProductos(false);
                setNuevo(false);
                break;
            default:
                break;
        }
    };

    const handleClose = () => {
        switch (props.name) {
            case 'import':
                setImportOrd(false);
                break;
            case 'nuevo':
                setNuevo(false);
                break;
            case 'productos':
                setProductos(false);
                break;
            case 'editar':
                setEditar(false);
                break;
            default:
                break;
        }
    };

    const body = (
        <div style={modalStyle} className={selcClass}>
            <div id="simple-modal-title">{props.popupTitle}</div>
            <div id="simple-modal-description">
                {props.popupBody}
                <div className="popupbottom">
                    <div className={props.popupsbclass}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className="popup-button-s"
                            onClick={handleClose}
                            fullWidth
                        >
                            {props.popupSeconButton}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <Button
                variant={props.isContained}
                color={props.colorButton}
                className={props.buttonClass}
                onClick={handleOpen}
                disabled={!props.isActive}
            >
                {props.textButton}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}

PopUp.defaultProps = {
    textButton: 'Agregar',
    colorButton: 'primary',
    popupTitle: 'Titulo',
    popupPrimButton: 'Aceptar',
    popupSeconButton: 'Cancelar',
    popupsbclass: 'popupbp',
    isContained: 'contained',
    isActive: true,
};

export default PopUp;
