import React, { createContext, useState, useEffect, useMemo, useContext } from 'react';

const CuentaContext = createContext();

export function CuentaProvider(props) {
  const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));
  const [userName, setUserName] = useState('');
  const [roles, setRoles] = useState([]);
  const [pass, setPass] = useState(false);
  const [editar, setEditar] = useState(false);
  const [passError, setPassError] = useState(true);
  const [nueva, setNueva] = useState('');
  const [equal, setEqual] = useState(false);
  const [modifiable, setModifiable] = useState(true);
  const [snackMsg, setSnackMsg] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [theUser, setTheUser] = useState({
    "usuario": ' ',
    "password": '',
    "email": '',
    "phoneNumber": '',
    "id": null,
    "nombre": ' ',
    "apellido": ' ',
    "cardId": null,
    "notas": '',
    "departamento": 0,
    "grupo": 0,
    "esNivel1": false,
    "esNivel2": false,
    "esNivel3": false,
    "idioma": 0,
    "horario": null,
    "roles": []
  });

  const ordenaArrObj = (x, y) => {
    if (x.nombre < y.nombre) { return -1 }
    if (x.nombre > y.nombre) { return 1 }
    return 0;
  }

  const getInfo = async (dto) => {
    const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/${dto}`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
      },
    })
    if (data !== null) {
      const obj = await data.json();

      switch (dto) {
        case 'roles':
          const cargos = [];
          obj.roles.forEach(cargo => {
            const data = {
              nombre: cargo.toString(),
              color: 'default'
            }
            cargos.push(data);
          });
          cargos.sort(ordenaArrObj);
          setRoles(cargos);
          break;
        default:
          break;
      }
    }
  }

  const getTheUser = (userName) => {
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/usuarios/GetUsuario?username=${userName}`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
      },
    }).then(res => res.json())
      .catch(error => console.error('Error: ', error))
      .then(response => {
        setTheUser(response);
      });
  }

  const getUserName = () => {
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/usuarios/ReturnUsuario`, {
      method: 'GET',
      headers: {
        authorization: `${tokenSessionObject.token}`,
        Accept: 'application/json',
      },
    }).then(res => res.json())
      .catch(error => console.error('Error: ', error))
      .then(response => {
        setUserName(response);
      });
  }

  const putUser = (data) => {
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/usuarios/Actualizar`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(res => res.json())
      .catch(error => console.error('Error: ', error))
      .then((response) => {
        setSnackMsg(response);
        setOpenSnack(true);
        reload();
      });
  }

  const changePass = (data) => {
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/usuarios/cambiarcontrasena`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(res => res.json())
      .catch(error => console.error('Error: ', error))
      .then((response) => {
        setSnackMsg(response);
        setOpenSnack(true);
        reload();
      });
  }

  const buscaMinus = (pass) => {
    for (let i = 0; i < pass.length; i++) {
      let ascii = pass.charCodeAt(i);
      if (ascii > 96 && ascii < 123) {
        return true;
      }
    }
    return false;
  }

  const buscaMayus = (pass) => {
    for (let i = 0; i < pass.length; i++) {
      let ascii = pass.charCodeAt(i);
      if (ascii > 64 && ascii < 91) {
        return true;
      }
    }
    return false;
  }

  const buscaEspecial = (pass) => {
    //33-47; 58-64; 91-96; 123-126
    for (let i = 0; i < pass.length; i++) {
      let ascii = pass.charCodeAt(i);
      if (ascii > 32 && ascii < 48) {
        return true;
      } else if (ascii > 57 && ascii < 65) {
        return true;
      } else if (ascii > 90 && ascii < 97) {
        return true;
      } else if (ascii > 122 && ascii < 127) {
        return true;
      }
    }
    return false;
  }

  const buscaNumero = (pass) => {
    for (let i = 0; i < pass.length; i++) {
      let ascii = pass.charCodeAt(i);
      if (ascii > 47 && ascii < 58) {
        return true;
      }
    }
    return false;
  }

  const validaPass = (pass) => {
    setNueva(pass);
    if (
      pass.length > 5 &&
      buscaMinus(pass) &&
      buscaMayus(pass) &&
      buscaEspecial(pass) &&
      buscaNumero(pass)
    ) {
      setPassError(false);
    }
    else { setPassError(true) }
  }

  const comparePass = (pass) => {
    if (nueva === pass)
      setEqual(true);
    else
      setEqual(false);
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const reload = async () => {
    setEditar(false);
    setPass(false);
    await sleep(1500);
    getTheUser(userName);
  }

  useEffect(() => {
    getUserName();
    getInfo('roles');
  }, []);

  useEffect(() => {
    if (userName !== '')
      getTheUser(userName);
  }, [userName]);

  const CuentaValues = useMemo(() => {
    return ({
      theUser, getTheUser,
      roles, setRoles,
      passError, setPassError,
      pass, setPass, userName,
      modifiable, setModifiable,
      editar, setEditar, equal,
      snackMsg, setSnackMsg,
      openSnack, setOpenSnack,
      ordenaArrObj, validaPass,
      putUser, changePass, comparePass,
    })
  }, [theUser, roles, pass, editar, passError, equal, modifiable, userName, snackMsg, openSnack]);

  return <CuentaContext.Provider value={CuentaValues} {...props} />;
}

export function useCuenta() {
  const context = useContext(CuentaContext);
  if (!context) {
    throw new Error('useCuenta debe estar dentro del proveedor CuentaContext');
  }
  return context;
}