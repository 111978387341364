import React from 'react';
//Components
import PopUp from './PopUp';
//MaterialUI
import {
  Grid,
  InputLabel,
  Button,
} from '@material-ui/core';
//Context
import { useCuenta } from './CuentaContext';
import Password from './Password';

export default function CambiarPass() {
  const { passError, userName, equal, changePass } = useCuenta();

  const update = (event) => {
    event.preventDefault();
    const data = {
      "usuario": userName,
      "actual": document.getElementById('uxTxtPassActual').value,
      "nueva": document.getElementById('uxTxtPassNueva').value,
    }
    changePass(data);
  }

  return (
    <PopUp
      name="pass"
      colorButton="default"
      buttonClass="actions-edit"
      popupSeconButton="Cancelar"
      popupsbclass="popupbp"
      isContained="text"
      popupTitle={
        <div className="popuphead">
          <h2 className="popuptitle-nodesc">Cambiar la contraseña</h2>
        </div>
      }
      popupBody={
        <div className="popupbody">
          <Grid container direction="column" spacing={2} style={{ marginBottom: 10 }}>
            <Grid item>
              <Password id="uxTxtPassActual" label="Contraseña Actual" />
            </Grid>
            <Grid item>
              <Password id="uxTxtPassNueva" label="Nueva Contraseña" error={true} />
            </Grid>
            {passError ? (
              <Grid item>
                <InputLabel style={{ fontSize: 11, color: 'red' }}>
                  La contraseña debe contener:
                  Al menos 6 caracteres,
                  al menos un carácter no alfanumérico,
                  al menos un número,
                  al menos una mayúscula y una minúscula.
                </InputLabel>
              </Grid>
            ) : (
              <Grid item style={{ textAlign: 'right' }}>
                <InputLabel style={{ fontSize: 11 }}>
                  Contraseña válida
                </InputLabel>
              </Grid>
            )}
            <Grid item>
              <Password id="uxTxtPassRepetir" label="Repetir Contraseña" />
            </Grid>
            {!equal ? (
              <Grid item>
                <InputLabel style={{ fontSize: 11, color: 'red' }}>
                  Las contraseñas deben coincidir
                </InputLabel>
              </Grid>
            ) : (
              <Grid item style={{ textAlign: 'right' }}>
                <InputLabel style={{ fontSize: 11 }}>
                  Las contraseñas coinciden
                </InputLabel>
              </Grid>
            )}
          </Grid>
          <div id="popupbs">
            <Button
              color="primary"
              className="popup-button-p"
              variant="contained"
              onClick={e => update(e)}
              disabled={!equal || passError}
            >
              Guardar
            </Button>
          </div>
        </div>
      }
    />
  );
}