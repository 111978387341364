import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//Material-UI
import {
    Grid,
    makeStyles,
    TextField,
    IconButton,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import {
    DataGrid,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    esES,
} from '@material-ui/data-grid';
//Icnos
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
//styles
import '../../styles/inventarios.css';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '60vh',
        // minHeight: 270,
        width: '100%',
        minWidth: 900,
    },
}));

//BEGIN SEARCH TOOLBAR
function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
    const classes = useStyles();
    return (
        <div>
            <div>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </div>
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Buscar..."
                className={classes.textField}
                fullWidth
                InputProps={{
                    startAdornment: <SearchIcon size="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon size="small" />
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

//END SEARCH TOOLBAR

export default function Inventarios() {
    const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));
    const classes = useStyles();
    const [selectionModel, setSelectionModel] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [prods, setProds] = useState([]);
    const [rows, setRows] = useState(prods);
    const [almacenes, setAlmacenes] = useState([]);
    const [almacen, setAlmacen] = useState(1);

    const tableColumns = [
        { field: 'sku', headerName: 'SKU', width: 150 },
        { field: 'nombre', headerName: 'Nombre', width: 350 },
        { field: 'existe', headerName: 'Cantidad', width: 150 },
        { field: 'unidadInv', headerName: 'Medida', width: 150 },
    ];

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = prods.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
    };

    const handleChange = (event) => {
        setAlmacen(event.target.value);
    };

    const getAlmacenes = async () => {
        const data = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Almacen/GetAlmacenes?almacen=0`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                },
            }
        );
        const obj = await data.json();
        obj.forEach((item) => {
            item.id = item.almacen;
        });
        setAlmacenes(obj);
    };

    const getProds = async (alm) => {
        setRows([]);
        const data = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Almacen/GetExistencias?almacen=${alm}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                },
            }
        );

        const response = await data.json();
        if (data.status === 200) {
            response.forEach((item) => {
                item.id = item.sku;
                if (item.unidadInv === null) item.unidadInv = '';
            });
            setRows(response);
        } else {
        }
    };

    useEffect(() => {
        getAlmacenes();
        getProds(1);
    }, []);

    useEffect(() => getProds(almacen), [almacen]);

    return (
        <Grid container direction="column" className="doc">
            <Grid item className="head">
                <Grid container alignItems="center" spacing={2}>
                    <Grid item md={10}>
                        <h1 className="titulo">Inventarios</h1>
                        <p className="desc">
                            Consultar un listado de almacenes disponibles, con su respectiva
                            cantidad de productos. Así como poder verificar el estado actual de cada
                            almacén para contar con una trazabilidad de la producción.
                        </p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className="body">
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <div className="almacenesList">
                            <InputLabel id="uxListProd">Almacenes</InputLabel>
                            <Select
                                labelId="uxListProd"
                                name="sku"
                                value={almacen}
                                onChange={handleChange}
                                fullWidth
                            >
                                {almacenes.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.nombreAlmacen}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className={classes.root}>
                            <DataGrid
                                components={{ Toolbar: QuickSearchToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        value: searchText,
                                        onChange: (event) => requestSearch(event.target.value),
                                        clearSearch: () => requestSearch(''),
                                    },
                                }}
                                disableColumnFilter
                                disableDensitySelector
                                checkboxSelection
                                columns={tableColumns}
                                localeText={esES.props.MuiDataGrid.localeText}
                                rows={rows}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                selectionModel={selectionModel}
                                hideFooterSelectedRowCount={true}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
