import React from 'react';

import SimpleList from '../../components/List.jsx';

import '../../styles/capacidades.css';

const setColor = (newColor) => {
    document.documentElement.style.setProperty('--color', newColor);
};

export default function Capacidades() {
    let primColor = '';
    if (process.env.REACT_APP_THEME === 'tecnoTheme') {
        primColor = '#ff9800';
        setColor(primColor);
    } else if (process.env.REACT_APP_THEME === 'colTheme') {
        primColor = '#58ab01';
        setColor(primColor);
    } else if (process.env.REACT_APP_THEME === 'matteTheme') {
        primColor = '#253139';
        setColor(primColor);
    } else if (process.env.REACT_APP_THEME === 'skyTheme') {
        primColor = '#001B69';
        setColor(primColor);
    } else {
        primColor = '#903bff';
        setColor(primColor);
    }
    return (
        <div className="capacidadesView">
            <div></div>

            <div className="capacidadesView-head">
                <h1 className="titulo">Capacidades</h1>
                <p className="desc">
                    Visualiza la capacidad default de todas las líneas y edita sus caracteristicas.
                </p>
            </div>
            <div className="capacidadesView-body">
                <SimpleList />
            </div>
        </div>
    );
}
