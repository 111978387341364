import React, { useState, useEffect } from 'react';
//Matrial-UI
import Grid from '@material-ui/core/Grid';
//Components
import Time from './time.jsx';
//Styles
import '../../styles/parametros.css';
//Context
import { useShifts } from './ShiftsContext.jsx';

function Shift(props) {
  const {
    first, second, third,
    selectedLine, codif
  } = useShifts();
  const [hIni, setHIni] = useState("--");
  const [mIni, setMIni] = useState("--");

  useEffect(() => {
    let minuto = "--";
    if (selectedLine > 0) {
      if (props.title === "Primer turno") {
        if (first[11] > 0)
          setHIni(codif(parseInt(first[11] + first[12])))
        else
          setHIni(codif(parseInt(first[12])))
        minuto = `${first[14]}${first[15]}`;
      }
      else if (props.title === "Segundo turno") {
        if (second[11] > 0)
          setHIni(codif(parseInt(second[11] + second[12])))
        else
          setHIni(codif(parseInt(second[12])))
        minuto = `${second[14]}${second[15]}`;
      }
      else if (props.title === "Tercer turno") {
        if (third[11] > 0)
          setHIni(codif(parseInt(third[11] + third[12])))
        else
          setHIni(codif(parseInt(third[12])))
        minuto = `${third[14]}${third[15]}`;
      }
    }
    setMIni(minuto);
  }, [selectedLine, first, second, third])

  return (
    <Grid container justifyContent="space-around" alignItems="center" className="shift">
      <Grid item md className="schedule">
        {props.title}
        <div className="lblInicio">Inicio</div>
        <Time hour={hIni} minut={mIni} id={props.id} />
      </Grid>
    </Grid>
  );
}

Shift.defaultProps = {
  title: "El turno",
}

export default Shift;