import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import '../popup.css';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, FormControlLabel, FormGroup, Grid, ListItemText } from '@material-ui/core';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { FileDownload, HandMove } from 'tabler-icons-react';
import * as jsonToExcel from 'xlsx';
import '../styles/calendar.css';

import {
    DataGrid,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    esES,
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { Trash } from 'tabler-icons-react';
import List from '@material-ui/core/List';
import SearchIcon from '@material-ui/icons/Search';
import { CalendarMinus, CalendarEvent, CalendarStats, Search } from 'tabler-icons-react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import ModalR from '../components/ModalR.jsx';
import ModalS from '../components/ModalS.jsx';

const setColor = (newColor) => {
    document.documentElement.style.setProperty('--color', newColor);
};

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

function QuickSearchToolbar(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </div>
            <TextField
                variant="standard"
                fullWidth
                value={props.value}
                onChange={props.onChange}
                placeholder="Buscar..."
                size="small"
                // className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon size="medium" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="medium"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon size="medium" />
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
        width: '300px',
    },
    typography: {
        padding: theme.spacing(2),
        paddingTop: '0px',
        paddingLeft: '30px',
        width: '300px',
    },
}));

const useStyles2 = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        margin: '20px 20px 0px 0px',
    },
}));

const useStyles3 = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingLeft: '40px',
        paddingRight: '79px',
        paddingTop: '26px',
        fontFamily: 'WorkSans',
    },
    paper: {
        padding: theme.spacing(0),
        color: theme.palette.text.secondary,
        boxShadow: 'none',
    },
}));

const useStyles6 = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingLeft: '40px',
        paddingRight: '79px',
        paddingTop: '26px',
        fontFamily: 'WorkSans',
    },
    paper: {
        padding: theme.spacing(0),

        color: theme.palette.text.secondary,
        backgroundColor: '#f9fafc',
        boxShadow: 'none',
    },
}));

const useStyles4 = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const useStyles5 = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingLeft: '40px',
        paddingRight: '79px',
        paddingTop: '26px',
        fontFamily: 'WorkSans',
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        boxShadow: 'none',
    },
}));

const useStyles7 = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
}));

const useStyles8 = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
        },
        '& .MuiGrid-grid-xs-3': {
            flexGrow: 1,
            maxWidth: '35%',
        },
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CalendarProduc = () => {
    const [lines, setLines] = useState([]);
    const [value, setValue] = useState('');
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [open, setOpen] = useState(false);
    const [openPoper, setOpenPoper] = useState(false);
    const [openD, setOpenD] = useState(false);
    const [openP, setOpenP] = useState(false);
    const [openP1, setOpenP1] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [openM, setOpenM] = useState(false);
    const [openW, setOpenW] = useState(false);
    const [showContent, setShowContent] = useState(true);
    const [calendarWeeks, setCalendarWeeks] = useState('timeGridWeek');
    const [lineSelect, setSelectLine] = useState(1);
    const [cantidadV, setCantidadV] = useState(0);
    const [ordenV, setOrdenV] = useState('');
    const [skuV, setSkuV] = useState('');
    const [duracionV, setDuracionV] = useState('');
    const [producidasV, setProducidasV] = useState('');
    const [enCalendarioV, setEncalendarioV] = useState('');
    const [restanV, setRestanV] = useState('');
    const [productoV, setProductoV] = useState(' ');
    const [tSetupV, setTSetupV] = useState('');
    const [metaV, setMetaV] = useState('');
    const [fechaIni, setFechaIni] = useState(0);
    const [fechaFin, setFechaFin] = useState(0);
    const [descServ, setDescServ] = useState('');
    const [selectedForm, setSelectedForm] = useState('orden');
    const [rows, setRows] = useState([]);
    const [openS, setOpenS] = React.useState(false);
    const [resW, setResW] = React.useState();
    const [fechaFinO, setFechaFinO] = useState('');
    const [orden, setOrden] = useState([]);
    const [arg, setArg] = useState([]);
    const [delOrdOrServ, setDelOrdOrServ] = useState([]);
    const [infOrder, setinfOrder] = useState([]);
    const [infEvent, setinfEvent] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [checked, setChecked] = useState(false);

    const calendarComponentRef = React.createRef();
    const popUpSelectOrder = React.createRef();
    const dialogDRef = React.createRef();
    const dialogRef = React.createRef();
    const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));
    const classes1 = useStyles1();
    const classes2 = useStyles2();
    const classes3 = useStyles3();
    const classes4 = useStyles4();
    const classes5 = useStyles5();
    const classes6 = useStyles6();
    const classes7 = useStyles7();
    const classes8 = useStyles8();
    const [searchText, setSearchText] = useState('');
    const [openT, setOpenT] = React.useState(false);

    const [fechaFinE, setFechaFinE] = React.useState(true);
    const [anchorEle, setAnchorEl] = React.useState(null);
    let primColor = '';
    if (process.env.REACT_APP_THEME === 'tecnoTheme') {
        primColor = '#ff9800';
        setColor(primColor);
    } else if (process.env.REACT_APP_THEME === 'colTheme') {
        primColor = '#58ab01';
        setColor(primColor);
    } else if (process.env.REACT_APP_THEME === 'matteTheme') {
        primColor = '#253139';
        setColor(primColor);
    } else if (process.env.REACT_APP_THEME === 'skyTheme') {
        primColor = '#001B69';
        setColor(primColor);
    } else {
        primColor = '#903bff';
        setColor(primColor);
    }

    const handleChangeCheck = (event) => {
        setChecked(event.target.checked);
        setFechaFinE(!fechaFinE);
    };

    const ExportToExcel = () => {
        const data = [];
        calendarEvents.map((calendarEvent, index) => {
            if (calendarEvent.tipo === 1) {
                data[index] = {
                    Orden: calendarEvent.title,
                    'Fecha Inicio': calendarEvent.start,
                    'Fecha Final': calendarEvent.end,
                    Cantidad: calendarEvent.cantidad,
                    'Fecha posible de entrega': calendarEvent.fechaEntrega,
                    Cliente: calendarEvent.cliente,
                    Meta: calendarEvent.meta,
                    sku: calendarEvent.sku,
                    'sku Nombre': calendarEvent.producto,
                };
            }
        });

        const worksheet = jsonToExcel.utils.json_to_sheet(data);
        const workbook = jsonToExcel.utils.book_new();
        jsonToExcel.utils.book_append_sheet(workbook, worksheet, 'Hoja1');
        jsonToExcel.writeFile(workbook, 'Ordenes_Programadas.xlsx');
    };

    const funcionEventos = (ordenesF) => {
        let newArray = [];
        let titulo = '';
        let sku = '';
        let skuNombre = '';
        ordenesF.map((item) => {
            const fechaEntrega = item.fechaEntrega.split('T');
            if (item.tipo === 2) {
                titulo = item.descripcion;
            } else {
                titulo = item.ordenProd;
                sku = item.sku;
                skuNombre = item.nombreSku;
            }
            newArray = [
                ...newArray,
                {
                    title: titulo,
                    start: item.fechaIni,
                    end: item.fechaFin,
                    backgroundColor: functionColor(item.tipo),
                    corrida: item.corrida,
                    descripcion: item.descripcion,
                    tipo: item.tipo,
                    tiempoSetup: item.tiempoSetup,
                    cantidad: item.cantidad,
                    fechaEntrega: fechaEntrega[0],
                    cliente: item.cliente,
                    meta: item.meta,
                    sku: sku,
                    producto: skuNombre,
                },
            ];
            return newArray;
        }, []);
        setCalendarEvents([]);
        setCalendarEvents(newArray);
        handleCloseP();
        setShowContent(true);
        setSelectedForm('orden');
    };
    const functionColor = (tipo) => {
        let color = '';
        switch (tipo) {
            case 0:
                color = '#FADD72';
                break;
            case 1:
                color = '#2CCB9E';
                break;
            case 2:
                color = '#903BFF';
                break;
            case 3:
                color = 'orange';
                break;
        }
        return color;
    };

    const columns = [
        {
            field: 'ordenProd',
            headerName: 'Orden',
            width: 120,
            editable: false,
        },
        {
            field: 'sku',
            headerName: 'SKU',
            width: 200,
            editable: false,
        },
        {
            field: 'fecha',
            headerName: 'Fecha',
            width: 160,
            editable: false,
        },
        {
            field: 'cantidad',
            headerName: 'Total',
            width: 110,
            align: 'center',
            editable: true,
        },
        {
            field: 'cantidadCalendario',
            headerName: 'En Calendario',
            align: 'center',
            width: 170,
            editable: true,
        },
        {
            field: 'restan',
            headerName: 'Restan',
            width: 130,
            align: 'center',
            editable: true,
        },
        {
            field: 'cliente',
            headerName: 'Cliente',
            width: 330,
            editable: true,
        },
    ];

    const getLines = async () => {
        const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/lineas`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
        });
        const lines = await data.json();

        setLines(lines);
    };

    const SearchOrder = async (searchOrder) => {
        setOpenPoper(false);
        setValue(searchOrder);
        let date = calendarComponentRef.current.getApi();
        const data = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Calendario/ordenEspecifica?orden=${searchOrder}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                },
            }
        );
        const SearchOrder = await data.json();
        if (data.status === 200 && SearchOrder.length > 0) {
            if (SearchOrder[0].linea !== null || SearchOrder[0].linea !== '') {
                setSelectLine(SearchOrder[0].linea);
            }
            date.gotoDate(SearchOrder[0].fechaIni);
            funcionEventos(SearchOrder);
        }
    };

    const handleClickT = () => {
        setOpenT(true);
    };

    const handleCloseT = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenT(false);
    };

    const handleDateUpdateEvents = async (line) => {
        let linSel = 0;
        if (line != null) {
            linSel = line;
        } else {
            linSel = lineSelect;
        }

        let _start = new Date(
            calendarComponentRef.current.getApi().currentDataManager.data.dateProfile.renderRange.start
        )
            .toISOString()
            .slice(0, 10);
        let _end = new Date(
            calendarComponentRef.current.getApi().currentDataManager.data.dateProfile.renderRange.end
        )
            .toISOString()
            .slice(0, 10);
        const data = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Calendario/ordenesFecha?linea=${linSel}&start=${_start}&end=${_end}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );

        const ordenes = await data.json();
        if (data.status !== 400) {
            funcionEventos(ordenes);
        }
    };

    const orderSin = async () => {
        const data = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Calendario/ordenesSinProgramar`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );
        const filas = await data.json();

        if (filas.length !== 0) {
            const data = [];
            handleClickT();
            filas.map((fila, index) => {
                data[index] = {
                    cantidad: fila.cantidad,
                    cantidadCalendario: fila.cantidadCalendario,
                    estatus: fila.estatus,
                    fecha: fila.fecha,
                    id: fila.id,
                    ordenProd: fila.ordenProd,
                    sku: fila.sku,
                    skuNombre: fila.skuNombre,
                    restan: fila.cantidad - fila.cantidadCalendario,
                    cliente: fila.cliente,
                    producido: fila.producido,
                };
            });

            setOrden(data);
            setRows(data);
        }

        // setRows(filasPrueba);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseD = () => {
        setOpenD(false);
    };

    const handleDrop = async (arg, flag) => {
        setArg(arg);
        const fechaSelec = new Date(arg.event.startStr);
        const fechaOld = new Date(arg.oldEvent.startStr);
        const fechaActual = new Date(Date.now());
        const diff = fechaSelec - fechaActual;
        const diffOld = fechaOld - fechaActual;

        if (diff <= 0) {
            arg.revert();
        } else {
            if (diffOld <= 0) {
                setOpenW(true);
            } else {
                let orPro = 0;
                if (arg.event.extendedProps.tipo === 2) {
                    orPro = null;
                } else {
                    orPro = arg.event.title;
                }

                const delOrOSer = {
                    corrida: arg.event.extendedProps.corrida,
                    linea: lineSelect,
                    ordenProd: orPro,
                    tipo: arg.event.extendedProps.tipo,
                    fechaIni: arg.event.startStr.slice(0, 16),
                    fechaFin: arg.event.endStr.slice(0, 16),
                    tiempoSetup: arg.event.extendedProps.tiempoSetup,
                    meta: 0,
                    descripcion: arg.event.extendedProps.descripcion,
                    cantidad: arg.event.extendedProps.cantidad,
                };

                fetch(
                    `${process.env.REACT_APP_SERVICE_ENDPOINT}/Calendario/editarOrdenProgramada`,
                    {
                        method: 'PUT',
                        body: JSON.stringify(delOrOSer),
                        headers: {
                            authorization: `Bearer ${tokenSessionObject.token}`,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    }
                ).then(async (response) => {
                    const isJson = response.headers
                        .get('content-type')
                        ?.includes('application/json');
                    const data1 = isJson && (await response.json());

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data1 && data1.message) || response.status;
                        return Promise.reject(error);
                    } else {
                        // if (flag == 0) {
                        //     handleDateUpdateEvents();
                        // }
                    }
                });
            }
        }
    };

    const getOrderSku = async (orden) => {
        const data = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Ordenes/GetOrden?orden=${orden}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );

        const ordenSku = await data.json();

        return ordenSku;
    };

    const handleClick = (arg) => {
        let titulo = '';
        setAnchorEl(arg.el);
        if (arg.event.extendedProps.tipo === 2) {
            titulo = `Descripcion: ${arg.event.extendedProps.descripcion}`;
            const OrdenSel = [
                titulo,
                // 'linea:' + lineSelect,
                // `Total: ${arg.event.extendedProps.cantidad}`,
                // `Corrida: ${arg.event.extendedProps.corrida}`,
                // `Meta: ${arg.event.extendedProps.meta}`,
                `Fecha: ${arg.event.startStr.slice(0, 10)}`,
                `Hora de inicio: ${arg.event.startStr.slice(11, 16)}`,
                `Hora de finalizacion: ${arg.event.endStr.slice(11, 16)}`,
                // `Tiempo Setup: ${arg.event.extendedProps.tiempoSetup}`,
            ];
            setDelOrdOrServ(arg.event);
            setinfOrder(OrdenSel);
            setOpenPoper(openPoper ? false : true);
        } else {
            titulo = `Orden: ${arg.event.title}`;

            const OrdenSel = [
                titulo,
                `Cliente: ${arg.event.extendedProps.cliente}`,
                `Producto: ${arg.event.extendedProps.sku}`,
                // 'linea:' + lineSelect,
                `Total: ${arg.event.extendedProps.cantidad}`,
                // `Corrida: ${arg.event.extendedProps.corrida}`,
                `Meta: ${arg.event.extendedProps.meta}`,
                `Fecha de inicio: ${arg.event.startStr.slice(0, 10)}`,
                `Hora de inicio: ${arg.event.startStr.slice(11, 16)}`,
                `Hora de finalización: ${arg.event.endStr.slice(11, 16)}`,
                `Fecha pactada de entrega: ${arg.event.extendedProps.fechaEntrega}`,
                // `Tiempo Setup: ${arg.event.extendedProps.tiempoSetup}`,
            ];

            setDelOrdOrServ(arg.event);
            setinfOrder(OrdenSel);
            setOpenPoper(openPoper ? false : true);
        }
    };

    const handleSelect = (info) => {
        setOpenPoper(false);
        const fechaSelec = new Date(info.startStr);
        const fechaActual = new Date(Date.now());
        const diff = fechaSelec - fechaActual;
        if (diff <= 0) {
            setMensaje('La fecha y hora seleccionada debe ser mayor a la fecha y hora actual !!!');
            setOpenS(true);
        } else {
            const fecha = `Esta seguro que desea crear un evento para la fecha: ${info.startStr.slice(
                8,
                10
            )}/${info.startStr.slice(5, 7)}/${info.startStr.slice(
                0,
                4
            )} en el horario: ${info.startStr.slice(11, 16)} ?`;

            // orderSin();
            setinfEvent(fecha);
            setFechaIni(info.startStr.slice(0, 16));

            const hora = parseInt(info.startStr.slice(12, 13), 10) + 1;

            const fechaFinServicio =
                info.startStr.slice(0, 12) + hora + info.startStr.slice(13, 16);

            setFechaFin(fechaFinServicio);
            setOpenD(true);
        }
    };

    const handleDataSelect = (info) => {
        if (info.length > 1) {
            const selectionSet = new Set(selectedRows);
            const result = info.filter((s) => !selectionSet.has(s));

            setSelectedRows(result);
        } else {
            setSelectedRows(info);
        }
    };

    const showformOrden = (event) => {
        if (event.target.value === 'orden') {
            setShowContent(true);
            setSelectedForm('orden');
        } else {
            setShowContent(false);
            setSelectedForm('servicio');
        }
    };

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
        setMensaje('');
    };

    const getFechafin = async (props) => {
        let diff = 0;
        if (props[0].estatus === 9) {
            diff = props[0].cantidad - props[0].producido;
        } else {
            diff = props[0].cantidad - props[0].cantidadCalendario;
        }
        const data = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Calendario/CalcularFechaFin?orden=${props[0].ordenProd}&linea=${lineSelect}&meta=${diff}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );
        const CalFechaFin = await data.json();

        const data1 = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/productos/GetProductoSku?sku=${props[0].sku}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );
        const producto = await data1.json();

        setDuracionV(CalFechaFin);
        const fecIni = new Date(fechaIni);
        const fechaFinComp = new Date(fecIni.getTime() + CalFechaFin * 60000);
        let dia = fechaFinComp.getDate();
        let mes = fechaFinComp.getMonth() + 1;
        let hora = fechaFinComp.getHours();
        let min = fechaFinComp.getMinutes();
        if (dia <= 9) {
            dia = '0' + dia;
        }
        if (mes <= 9) {
            mes = '0' + mes;
        }
        if (hora <= 9) {
            hora = '0' + hora;
        }
        if (min <= 9) {
            min = '0' + min;
        }

        setFechaFinO(fechaFinComp.getFullYear() + '-' + mes + '-' + dia + 'T' + hora + ':' + min);
        setCantidadV(props[0].cantidad);
        if (props[0].estatus === 9) {
            setMetaV(props[0].cantidad - props[0].producido);
            setRestanV(props[0].cantidad - props[0].producido);
        } else {
            setMetaV(props[0].cantidad - props[0].cantidadCalendario);
            setRestanV(props[0].cantidad - props[0].cantidadCalendario);
        }

        setOrdenV(props[0].ordenProd);
        setSkuV(props[0].sku);
        setProducidasV(props[0].producido);
        setProductoV(producto.nombre);
        setTSetupV(0);
        setEncalendarioV(props[0].cantidadCalendario);

        setOpenP1(false);
        setSelectedRows([]);
    };

    const getLineaOptima = async (props) => {
        const data = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Calendario/LineaRecomendada?sku=${props[0].sku}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );
        const lineaRec = await data.json();
        let comLineRec = 0;
        if (lineaRec.length > 0) {
            lineaRec.forEach((lineaR) => {
                if (lineaR.linea === lineSelect) {
                    comLineRec++;
                }
            });
        }
        if (comLineRec !== 0) {
            getFechafin(props);
        } else {
            alert('Esta orden no se puede realizar en la linea seleccionada');
            setSelectedRows([]);
        }
    };

    const handleSelOrder = () => {
        requestSearch('');
        if (selectedRows.length > 0) {
            const datos = orden.filter((ord) => ord.id == selectedRows);

            getLineaOptima(datos);
        } else {
            alert('Debe seleccionar una orden de la lista');
        }
    };

    const putOrder = async () => {
        const data = {
            linea: lineSelect,
            ordenProd: ordenV,
            tipo: 1,
            fechaIni: fechaIni,
            fechaFin: fechaFinO,
            tiempoSetup: tSetupV,
            meta: metaV,
            descripcion: '',
        };

        fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/Calendario/programarOrden`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(async (response) => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data1 = isJson && (await response.json());

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data1 && data1.message) || response.status;
                return Promise.reject(error);
            } else {
                // window.location.replace('');
                handleDateUpdateEvents();
                setOpenP(false);
                setOrdenV('');
                setSkuV('');
                setCantidadV('');
                setDuracionV('');
                setProductoV(' ');
                setFechaFinO('');
                setTSetupV('');
                setMetaV('');
                setProducidasV('');
                setEncalendarioV();
                setRestanV('');
                orderSin('');
            }
        });
    };

    const handleResW = (res) => {
        setResW(res);
        setOpenW(false);
    };

    const postNewOrd = (info) => {
        info.preventDefault();
        putOrder();
    };

    const postNewService = (info) => {
        info.preventDefault();

        const servicio = {
            linea: lineSelect,
            ordenProd: null,
            tipo: 2,
            fechaIni: `${fechaIni}:00.000Z`,
            fechaFin: `${fechaFin}:00.000Z`,
            tiempoSetup: 0,
            meta: 0,
            descripcion: descServ,
        };

        setNewService(servicio);
    };

    const setNewService = async (data) => {
        fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/Calendario/AsignarLineaServicio`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(async (response) => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data1 = isJson && (await response.json());

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data1 && data1.message) || response.status;
                return Promise.reject(error);
            } else {
                // window.location.replace('');
                handleDateUpdateEvents();
                setDescServ('');
                orderSin();
            }
        });
    };

    const deleteOrder = async (props) => {
        await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Calendario/EliminarOrdenCalendario?corrida=${props[0]}`,
            {
                method: 'PUT',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((res) => res.json())
            .catch((error) => console.error('Error: ', error))
            .then((response) => {
                // window.location.replace(''); //refrescamos la página
                handleDateUpdateEvents();
                setOpenPoper(false);
                setOpenM(false);
                orderSin();
            });
    };

    const handleCloseM = () => {
        setOpenM(false);
        setOpenPoper(false);
    };

    const handleOpenM = () => {
        const fechaSelec = new Date(delOrdOrServ.startStr);
        const fechaActual = new Date(Date.now());
        const diff = fechaSelec - fechaActual;
        // no permite borrar corridas anteriores a la fecha actual
        // if (diff <= 0) {
        //     setMensaje('No puede eliminar una Orden/Servicio con fecha anterior a la fecha actual');
        //     setOpenS(true);
        // } else {
        //     setOpenM(true);
        // }
        setOpenM(true);
    };

    const deleteService = async (props) => {
        await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Calendario/EliminarOrdenCalendario?corrida=${props[0]}`,
            {
                method: 'PUT',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((res) => res.json())
            .catch((error) => console.error('Error: ', error))
            .then((response) => {
                // window.location.replace(''); //refrescamos la página
                handleDateUpdateEvents();
                setOpenPoper(false);
                setOpenM(false);
                orderSin();
            });
    };

    const deleteOrderOrService = async () => {
        if (delOrdOrServ.extendedProps.tipo === 2) {
            deleteService([
                delOrdOrServ.extendedProps.corrida,
                delOrdOrServ.extendedProps.descripcion,
            ]);
        } else {
            deleteOrder([delOrdOrServ.extendedProps.corrida, delOrdOrServ.title]);
        }
    };

    const requestSearch = async (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = orden.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });
        setRows(filteredRows);
    };

    const handlePopUpP = () => {
        setOpenP(true);
        setOpenD(false);
    };

    const getfechaFinMeta = async (meta) => {
        const data = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Calendario/CalcularFechaFin?orden=${ordenV}&linea=${lineSelect}&meta=${meta}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );
        const CalFechaFinMeta = await data.json();

        setDuracionV(CalFechaFinMeta);
        const fecIni = new Date(fechaIni);

        const fechaFinComp = new Date(fecIni.getTime() + CalFechaFinMeta * 60000);
        let dia = fechaFinComp.getDate();
        let mes = fechaFinComp.getMonth() + 1;
        let hora = fechaFinComp.getHours();
        let min = fechaFinComp.getMinutes();
        if (dia <= 9) {
            dia = '0' + dia;
        }
        if (mes <= 9) {
            mes = '0' + mes;
        }
        if (hora <= 9) {
            hora = '0' + hora;
        }
        if (min <= 9) {
            min = '0' + min;
        }

        setFechaFinO(fechaFinComp.getFullYear() + '-' + mes + '-' + dia + 'T' + hora + ':' + min);
    };

    const handleMeta = (e) => {
        if (e.target.value === '') {
            setMetaV(e.target.value);
        } else if (e.target.value <= restanV && e.target.value >= 0) {
            setMetaV(e.target.value);
            getfechaFinMeta(e.target.value);
        }
    };
    const handleCloseP = () => {
        setOpenP(false);
        setOpenP(false);
        setOrdenV('');
        setSkuV('');
        setCantidadV('');
        setDuracionV('');
        setProductoV(' ');
        setFechaFinO('');
        setTSetupV('');
        setMetaV('');
        setProducidasV('');
        setEncalendarioV('');
        setRestanV('');
    };
    const handleCloseP1 = () => {
        requestSearch('');
        setOpenP1(false);
    };

    const handlePopUpP1 = () => {
        setOpenP1(true);
    };

    useEffect(() => {
        getLines();
        orderSin();
        handleDateUpdateEvents();
        // calendarApi = calendarComponentRef.current.getApi();
    }, []);

    useEffect(() => {
        if (resW === 0) {
            arg.revert();
        } else if (resW === 1) {
            let orPro = 0;
            if (arg.event.extendedProps.tipo === 2) {
                orPro = null;
            } else {
                orPro = arg.event.title;
            }

            const delOrOSer = {
                corrida: arg.event.extendedProps.corrida,
                linea: lineSelect,
                ordenProd: orPro,
                tipo: arg.event.extendedProps.tipo,
                fechaIni: arg.event.startStr.slice(0, 16),
                fechaFin: arg.event.endStr.slice(0, 16),
                tiempoSetup: arg.event.extendedProps.tiempoSetup,
                meta: 0,
                descripcion: arg.event.extendedProps.descripcion,
                cantidad: arg.event.extendedProps.cantidad,
            };

            fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/Calendario/editarOrdenProgramada`, {
                method: 'PUT',
                body: JSON.stringify(delOrOSer),
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(async (response) => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data1 = isJson && (await response.json());

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data1 && data1.message) || response.status;
                    return Promise.reject(error);
                }
            });
        }
        setResW();
    }, [resW]);

    return (
        <div className={classes6.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper className={classes6.paper}>
                        <h1 className="titulo">Calendario de producción</h1>
                        <p className="desc">
                            Descripción breve de la pantalla o vista en la que se encuentra el
                            usuario.
                        </p>
                    </Paper>
                </Grid>
            </Grid>
            <div className={classes4.root}>
                <Snackbar
                    open={openT}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    onClose={handleCloseT}
                >
                    <Alert onClose={handleCloseT} severity="warning">
                        Existen ordenes sin programar!!!
                    </Alert>
                </Snackbar>
            </div>
            <div>
                <Dialog
                    open={open}
                    ref={dialogRef}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {'Informacion de la orden:'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {infOrder.map((info, index) => (
                                <li key={index}>{info}</li>
                            ))}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="popup-button-p" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div className={classes1.root}>
                <Popper
                    open={openPoper}
                    className="popperconf"
                    anchorEl={anchorEle}
                    placement="left"
                    transition
                    disablePortal={false}
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'scrollParent',
                        },
                    }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={500}>
                            <Paper>
                                <div className="popperhead">
                                    <b>Detalles de la Orden:</b>
                                    <Button onClick={handleOpenM}>
                                        <div>
                                            <Trash size={20} />
                                        </div>
                                        Borrar
                                    </Button>
                                    <ModalS
                                        openM={openM}
                                        popupTitle={
                                            <div className="icon-big">
                                                <Trash size={120} color={'#7D7F90'} />
                                            </div>
                                        }
                                        popupBody={
                                            <div className="popup-delete1">
                                                <div className="message">
                                                    ¿Está Seguro Que Desea Eliminar la Orden de la
                                                    linea: {lines[lineSelect - 1].nombre}?
                                                </div>

                                                <div className="popupbottom">
                                                    <div className="popupbpd4">
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            className="popup-button"
                                                            onClick={handleCloseM}
                                                            fullWidth
                                                        >
                                                            Cancelar
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="popupbpd5">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        className="popup-button"
                                                        onClick={deleteOrderOrService}
                                                        fullWidth
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                                <Typography className={classes1.typography}>
                                    <div className={classes2.root}>
                                        <List component="nav">
                                            {infOrder.map((info, index) => (
                                                <ListItemText key={index} primary={info} />
                                            ))}
                                        </List>
                                    </div>
                                </Typography>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </div>

            <div>
                <Dialog
                    open={openD}
                    ref={dialogDRef}
                    TransitionComponent={Transition}
                    keepMounted
                    popupsbclass="popupbp"
                    onClose={handleCloseD}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle className="popuphead2" id="alert-dialog-slide-title">
                        <h3 className="popuptitled">Crear Evento</h3>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className="popupbody3" id="alert-dialog-description">
                            {infEvent}
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Paper className={classes5.paper}>
                            <ModalR
                                open={openP}
                                popupsbclass="popupbp"
                                popupFlag={false}
                                popupTitle={
                                    <div className="popuphead3">
                                        <h3 className="popuptitle">Nuevo evento</h3>
                                        <Grid container className="container-controls">
                                            <Grid item xs={4} className="container-controls-tittle">
                                                <InputLabel id="select-line" className="label-line">
                                                    Seleccionar opción
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Select
                                                    labelId="select-line"
                                                    className="select-line"
                                                    onChange={showformOrden}
                                                    value={selectedForm}
                                                >
                                                    <MenuItem value="orden" selected={true}>
                                                        Orden
                                                    </MenuItem>
                                                    <MenuItem value="servicio">Servicio</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </div>
                                }
                                popupBody={
                                    <div className="popupbody">
                                        <Grid container className="container-controls">
                                            <Grid
                                                item
                                                xs={8}
                                                style={{
                                                    display: showContent ? 'block' : 'none',
                                                }}
                                            >
                                                <form onSubmit={(info) => postNewOrd(info)}>
                                                    <div className="popuptextfield">
                                                        <TextField
                                                            id="fechaIni"
                                                            name="fechaIni"
                                                            label="FECHA INICIAL"
                                                            type="datetime-local"
                                                            value={fechaIni}
                                                            onChange={(e) =>
                                                                setFechaIni(e.target.value)
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            fullWidth
                                                        />
                                                    </div>
                                                    <div className="popuptextfield">
                                                        <Grid
                                                            container
                                                            className="container-controls"
                                                        >
                                                            <Grid item xs={8}>
                                                                <TextField
                                                                    fullWidth
                                                                    id="fechaFinO"
                                                                    name="fechaFinO"
                                                                    label="FECHA FINAL"
                                                                    type="datetime-local"
                                                                    value={fechaFinO}
                                                                    onChange={(e) =>
                                                                        setFechaFinO(e.target.value)
                                                                    }
                                                                    disabled={fechaFinE}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={3}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={checked}
                                                                            onChange={
                                                                                handleChangeCheck
                                                                            }
                                                                            label="Modificar"
                                                                            color="primary"
                                                                            inputProps={{
                                                                                'aria-label':
                                                                                    'secondary checkbox',
                                                                            }}
                                                                        />
                                                                    }
                                                                    label="Modificar"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="popuptextfield">
                                                        <Paper className={classes3.paper}>
                                                            <ModalR
                                                                ref={popUpSelectOrder}
                                                                colorButton="primary"
                                                                open={openP1}
                                                                buttonClass="popup-button"
                                                                popupsbclass="popupbp"
                                                                popupFlag={true}
                                                                popupTitle={
                                                                    <div className="popuphead4">
                                                                        <h2 className="popuptitle">
                                                                            Buscar Orden
                                                                        </h2>
                                                                        <p className="popuptitled">
                                                                            Seleccione una orden
                                                                        </p>
                                                                    </div>
                                                                }
                                                                popupBody={
                                                                    <div className="popupbody1">
                                                                        <div>
                                                                            <div
                                                                                style={{
                                                                                    height: 570,
                                                                                    width: 1220,
                                                                                }}
                                                                            >
                                                                                <DataGrid
                                                                                    components={{
                                                                                        Toolbar:
                                                                                            QuickSearchToolbar,
                                                                                    }}
                                                                                    componentsProps={{
                                                                                        toolbar: {
                                                                                            value: searchText,
                                                                                            onChange:
                                                                                                (
                                                                                                    event
                                                                                                ) =>
                                                                                                    requestSearch(
                                                                                                        event
                                                                                                            .target
                                                                                                            .value
                                                                                                    ),

                                                                                            clearSearch:
                                                                                                () =>
                                                                                                    requestSearch(
                                                                                                        ''
                                                                                                    ),
                                                                                        },
                                                                                    }}
                                                                                    localeText={
                                                                                        esES.props
                                                                                            .MuiDataGrid
                                                                                            .localeText
                                                                                    }
                                                                                    rows={rows}
                                                                                    disableColumnFilter
                                                                                    hideFooterRowCount={
                                                                                        true
                                                                                    }
                                                                                    hideFooterSelectedRowCount={
                                                                                        true
                                                                                    }
                                                                                    disableDensitySelector
                                                                                    columns={
                                                                                        columns
                                                                                    }
                                                                                    pageSize={25}
                                                                                    checkboxSelection
                                                                                    selectionModel={
                                                                                        selectedRows
                                                                                    }
                                                                                    onSelectionModelChange={(
                                                                                        info
                                                                                    ) => {
                                                                                        handleDataSelect(
                                                                                            info
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="popupbottom1">
                                                                            <div className="popupbpd7">
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    color="primary"
                                                                                    className="popup-button-s"
                                                                                    onClick={
                                                                                        handleCloseP1
                                                                                    }
                                                                                    fullWidth
                                                                                >
                                                                                    cancelar
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="popupbpd6">
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                className="popup-button"
                                                                                onClick={
                                                                                    handleSelOrder
                                                                                }
                                                                                type="submit"
                                                                                fullWidth
                                                                            >
                                                                                Seleccionar Orden
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            />

                                                            <div>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className="popup-button"
                                                                    onClick={handlePopUpP1}
                                                                    fullWidth
                                                                >
                                                                    <div>
                                                                        <Search
                                                                            size={18}
                                                                            className="icon"
                                                                        />
                                                                    </div>
                                                                    Buscar Orden
                                                                </Button>
                                                            </div>
                                                        </Paper>
                                                    </div>
                                                    <div className="popuptextfield">
                                                        <div className="container-body">
                                                            <h3 className="container-body-tittle">
                                                                Detalles de la orden
                                                            </h3>
                                                            <div className={classes7.root}>
                                                                <Grid
                                                                    container
                                                                    className="container-controls"
                                                                >
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            id="orden"
                                                                            disabled
                                                                            label="Número Orden"
                                                                            value={ordenV}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            id="sku"
                                                                            disabled
                                                                            label="SKU"
                                                                            value={skuV}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <br />

                                                                    <Grid item xs={3}>
                                                                        <TextField
                                                                            id="total"
                                                                            disabled
                                                                            label="Total"
                                                                            value={cantidadV}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                                                        />
                                                                    </Grid>

                                                                    <br />

                                                                    <Grid item xs={3}>
                                                                        <TextField
                                                                            id="producidas"
                                                                            disabled
                                                                            label="Producidas"
                                                                            value={producidasV}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <br />
                                                                    <Grid item xs={3}>
                                                                        <TextField
                                                                            id="enCalendario"
                                                                            disabled
                                                                            label="En Calendario"
                                                                            value={enCalendarioV}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <br />
                                                                    <Grid item xs={3}>
                                                                        <TextField
                                                                            id="restan"
                                                                            disabled
                                                                            label="Restan"
                                                                            value={restanV}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </div>
                                                        <div className={classes8.root}>
                                                            <Grid
                                                                container
                                                                className="container-controls"
                                                            >
                                                                <Grid item xs={3}>
                                                                    <TextField
                                                                        id="meta"
                                                                        label="Meta"
                                                                        type="number"
                                                                        value={metaV}
                                                                        onChange={(e) =>
                                                                            handleMeta(e)
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <br />
                                                                <Grid item xs={3}>
                                                                    <TextField
                                                                        id="duracion"
                                                                        disabled
                                                                        label="Duración/minutos"
                                                                        value={duracionV}
                                                                        InputProps={{
                                                                            readOnly: true,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <br />

                                                                <Grid item xs={3}>
                                                                    <TextField
                                                                        id="tSetup"
                                                                        label="Tiempo Setup"
                                                                        type="number"
                                                                        value={tSetupV}
                                                                        onChange={(e) =>
                                                                            setTSetupV(
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </div>

                                                    <div className="popupbottom">
                                                        <div className="popupbpd2">
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                className="popup-button-s"
                                                                onClick={handleCloseP}
                                                                fullWidth
                                                            >
                                                                cancelar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="popupbpd1">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className="popup-button"
                                                            onClick={postNewOrd}
                                                            fullWidth
                                                        >
                                                            Programar orden
                                                        </Button>
                                                    </div>
                                                </form>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={8}
                                                style={{
                                                    display: showContent ? 'none' : 'block',
                                                }}
                                            >
                                                <form onSubmit={(info) => postNewService(info)}>
                                                    <div className="popuptextfield">
                                                        <TextField
                                                            id="servicio"
                                                            name="ordenProd"
                                                            label="DESCRIPCIÓN"
                                                            required
                                                            value={descServ}
                                                            onChange={(e) =>
                                                                setDescServ(e.target.value)
                                                            }
                                                            fullWidth
                                                        />
                                                    </div>
                                                    <div className="popuptextfield">
                                                        <TextField
                                                            id="fechaIni"
                                                            name="fechaIni"
                                                            label="FECHA INICIAL"
                                                            type="datetime-local"
                                                            value={fechaIni}
                                                            onChange={(e) =>
                                                                setFechaIni(e.target.value)
                                                            }
                                                            // defaultValue={fechaIni}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            fullWidth
                                                        />
                                                    </div>
                                                    <div className="popuptextfield">
                                                        <TextField
                                                            id="fechaFin"
                                                            name="fechaFin"
                                                            value={fechaFin}
                                                            onChange={(e) =>
                                                                setFechaFin(e.target.value)
                                                            }
                                                            label="FECHA FINAL"
                                                            type="datetime-local"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            fullWidth
                                                        />
                                                    </div>

                                                    <div className="popupbottom">
                                                        <div className="popupbpd3">
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                className="popup-button-s"
                                                                onClick={handleCloseP}
                                                                fullWidth
                                                            >
                                                                cancelar
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="popupbpd12">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className="popup-button"
                                                            type="submit"
                                                            fullWidth
                                                        >
                                                            Crear nuevo servicio
                                                        </Button>
                                                    </div>
                                                </form>
                                            </Grid>
                                        </Grid>
                                    </div>
                                }
                            />
                        </Paper>
                        <div>
                            <div className="popupbottom">
                                <div className="popupbpd">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className="popup-button-s"
                                        onClick={handleCloseD}
                                        fullWidth
                                    >
                                        cancelar
                                    </Button>
                                </div>
                            </div>
                            <div className="popupbpd8">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="popup-button"
                                    onClick={handlePopUpP}
                                    fullWidth
                                >
                                    Sí
                                </Button>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
            <div className="body-calendar">
                <Grid container className="container-controls">
                    <Grid item xs={6}>
                        <InputLabel id="selectLine" color="primary" className="label-line">
                            Seleccione una Línea
                        </InputLabel>
                        <Select
                            labelId="selectLine"
                            id="Line Select"
                            value={lineSelect}
                            className="select-line"
                        >
                            {lines.map((item, index) => (
                                <MenuItem
                                    value={item.id}
                                    key={item.id}
                                    onClick={() => {
                                        setSelectLine(item.id);
                                        handleDateUpdateEvents(item.id);
                                    }}
                                >
                                    {item.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="standard-basic"
                            label="BUSCAR ORDEN..."
                            className="search-product"
                            value={value}
                            onChange={(e) => SearchOrder(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <br />

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        initialView={calendarWeeks}
                        contentHeight={620}
                        // progressiveEventRendering={true}
                        allDaySlot={false}
                        // id="calendrio"
                        locale={esLocale}
                        nowIndicator={true}
                        eventOverlap={false}
                        themeSystem={'bootstrap5'}
                        ref={calendarComponentRef}
                        editable={true}
                        selectable={true}
                        eventClick={(info) => handleClick(info)}
                        select={(info) => handleSelect(info)}
                        eventDrop={(info) => handleDrop(info, 0)}
                        eventResize={(info) => handleDrop(info, 1)}
                        events={calendarEvents}
                        slotMinTime={'06:00:00'}
                        slotMaxTime={'18:00:00'}
                        // hiddenDays={[0]} // esconder el domingo
                        businessHours={{
                            // days of week. an array of zero-based day of week integers (0=Sunday)
                            daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // dias habiles en el calendario

                            startTime: '06:00', // a start time (10am in this example)
                            endTime: '18:00', // an end time (6pm in this example)
                        }}
                        slotDuration="00:30"
                        headerToolbar={{
                            right: 'espacio excel title prevYear prev next nextYear',
                            center: '',
                            left: 'espacio dayGridMonth espacio espacio espacio timeGridWeek espacio espacio  timeGridDay espacio espacio ',
                        }}
                        slotLabelFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            omitZeroMinute: false,
                            meridiem: 'short',
                        }}
                        dayHeaderFormat={{
                            weekday: 'long',
                        }}
                        customButtons={{
                            excel: {
                                text: [
                                    <FileDownload size={25} strokeWidth={1.5} color={'black'} />,
                                ],
                                click: function () {
                                    ExportToExcel();
                                },
                            },
                            prev: {
                                click: function () {
                                    setOpenPoper(false);

                                    calendarComponentRef.current.getApi().prev();
                                    handleDateUpdateEvents();
                                },
                            },
                            next: {
                                click: function () {
                                    setOpenPoper(false);

                                    calendarComponentRef.current.getApi().next();
                                    handleDateUpdateEvents();
                                },
                            },

                            prevYear: {
                                click: function () {
                                    setOpenPoper(false);

                                    calendarComponentRef.current.getApi().prevYear();
                                    handleDateUpdateEvents();
                                },
                            },
                            nextYear: {
                                click: function () {
                                    setOpenPoper(false);

                                    calendarComponentRef.current.getApi().nextYear();
                                    handleDateUpdateEvents();
                                },
                            },

                            dayGridMonth: {
                                text: [
                                    <div className="iconCalendar">
                                        {calendarWeeks === 'dayGridMonth' && (
                                            <div style={{ color: primColor, display: 'flex' }}>
                                                <CalendarEvent
                                                    size={25}
                                                    strokeWidth={1.5}
                                                    color={primColor}
                                                />
                                                &nbsp;Mes
                                            </div>
                                        )}
                                        {calendarWeeks !== 'dayGridMonth' && (
                                            <div style={{ color: '#5f606f', display: 'flex' }}>
                                                <CalendarEvent
                                                    size={25}
                                                    strokeWidth={1.5}
                                                    color={'#5f606f'}
                                                />
                                                &nbsp;Mes
                                            </div>
                                        )}
                                    </div>,
                                ],
                                click: function () {
                                    setOpenPoper(false);
                                    setCalendarWeeks('dayGridMonth');
                                    calendarComponentRef.current
                                        .getApi()
                                        .changeView('dayGridMonth');
                                    handleDateUpdateEvents();
                                },
                            },
                            timeGridWeek: {
                                className: 'buttonCalendar',
                                text: [
                                    <div className="iconCalendar">
                                        {calendarWeeks === 'timeGridWeek' && (
                                            <div style={{ color: primColor, display: 'flex' }}>
                                                <CalendarMinus
                                                    size={25}
                                                    strokeWidth={1.5}
                                                    color={primColor}
                                                />
                                                &nbsp;Semana
                                            </div>
                                        )}
                                        {calendarWeeks !== 'timeGridWeek' && (
                                            <div style={{ color: '#5f606f', display: 'flex' }}>
                                                <CalendarMinus
                                                    size={25}
                                                    strokeWidth={1.5}
                                                    color={'#5f606f'}
                                                />
                                                &nbsp;Semana
                                            </div>
                                        )}
                                    </div>,
                                ],
                                click: function () {
                                    setOpenPoper(false);
                                    setCalendarWeeks('timeGridWeek');
                                    calendarComponentRef.current
                                        .getApi()
                                        .changeView('timeGridWeek');
                                    handleDateUpdateEvents();
                                },
                            },
                            timeGridDay: {
                                className: 'buttonCalendar',
                                text: [
                                    <div className="iconCalendar">
                                        {calendarWeeks === 'timeGridDay' && (
                                            <div style={{ color: primColor, display: 'flex' }}>
                                                <CalendarStats
                                                    size={25}
                                                    strokeWidth={1.5}
                                                    color={primColor}
                                                />
                                                &nbsp;Día
                                            </div>
                                        )}
                                        {calendarWeeks !== 'timeGridDay' && (
                                            <div style={{ color: '#5f606f', display: 'flex' }}>
                                                <CalendarStats
                                                    size={25}
                                                    strokeWidth={1.5}
                                                    color={'#5f606f'}
                                                />
                                                &nbsp;Día
                                            </div>
                                        )}
                                    </div>,
                                ],
                                click: function () {
                                    setOpenPoper(false);
                                    setCalendarWeeks('timeGridDay');
                                    calendarComponentRef.current.getApi().changeView('timeGridDay');
                                    handleDateUpdateEvents();
                                },
                            },
                        }}
                        // eventLimitText={'más'}
                        // eventLimitClick={'popover'}
                        // eventLimit={true}
                    />
                </Grid>
            </div>
            <div>
                <Snackbar open={openS} autoHideDuration={5000} onClose={handleCloseS}>
                    <Alert onClose={handleCloseS} severity="error">
                        {mensaje}
                    </Alert>
                </Snackbar>
            </div>
            <div>
                <ModalS
                    openM={openW}
                    popupTitle={
                        <div className="icon-big">
                            <HandMove size={120} color={'#7D7F90'} />
                        </div>
                    }
                    popupBody={
                        <div className="popup-mover">
                            <div className="message1">
                                ¿Está Seguro Que Desea Mover La Orden/Servicio De Una fecha Anterior
                                a una Fecha Posterior a La Feha Actual?
                            </div>

                            <div className="popupbpd-mover-cancelar">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className="popup-button"
                                    onClick={(e) => handleResW(0)}
                                    fullWidth
                                >
                                    Cancelar
                                </Button>
                            </div>

                            <div className="popupbpd-mover-si">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className="popup-button"
                                    onClick={(e) => handleResW(1)}
                                    fullWidth
                                >
                                    Sí
                                </Button>
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    );
};
export default CalendarProduc;
