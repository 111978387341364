import React, { useEffect, useState } from 'react';
//Material-UI
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
//Context
import { useShifts } from './ShiftsContext';

function Time(props) {
  const { setTPvalue, decod } = useShifts();
  const [hour, setHour] = useState([]);
  const [minut, setMinut] = useState([]);
  const [selectedHour, setSelectedHour] = useState("--");
  const [selectedMinut, setSelectedMinut] = useState("--");

  function generateHour() {
    let aux = [];
    for (let i = 0; i < 24; i++) {
      if (i === 0) {
        aux[i] = "12AM";
      }
      else if (i < 12 && i > 0) {
        aux[i] = `${i.toString()}AM`;
      }
      else if (i === 12) {
        aux[i] = `${i.toString()}PM`;
      }
      else if (i > 12 && i < 24) {
        const format = i - 12;
        aux[i] = `${format.toString()}PM`;
      }
    }
    aux[25] = "--";
    return aux;
  }

  function generateMinuts() {
    let aux = [];
    for (let i = 0; i < 60; i++) {
      if (i < 10) {
        aux[i] = `0${i.toString()}`;
      }
      else {
        aux[i] = i.toString();
      }
    }
    aux[60] = "--";
    return aux;
  }

  async function fillHourAndMinuts() {
    const hours = await generateHour();
    const minuts = await generateMinuts();
    await setHour(hours);
    await setMinut(minuts);
    await setSelectedHour(props.hour);
    await setSelectedMinut(props.minut);
  }

  const handleHourChange = (event) => {
    setSelectedHour(event.target.value)
    setTPvalue(`1900-01-01T${decod(event.target.value)}:${selectedMinut}:00`, props.id)
  };

  const handleMinutChange = (event) => {
    setSelectedMinut(event.target.value);
    setTPvalue(`1900-01-01T${decod(selectedHour)}:${event.target.value}:00`, props.id)
  };

  useEffect(() => {
    fillHourAndMinuts();
  }, [props.hour, props.minut]);
  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item md={6}>
        <FormControl className="timepicker" fullWidth>
          <InputLabel shrink id="lblHour">
            HORA
          </InputLabel>
          <Select
            labelId="lblHour"
            id="hour"
            onChange={handleHourChange}
            displayEmpty
            value={selectedHour}
          >
            {hour.map(item => (
              <MenuItem value={item.toString()}>{item.toString()}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={6}>
        <FormControl className="timepicker" fullWidth>
          <InputLabel shrink id="lblMinut">
            MINUTO
          </InputLabel>
          <Select
            labelId="lblMinutoInicio"
            id="minutoInicio"
            onChange={handleMinutChange}
            displayEmpty
            value={selectedMinut}
          >
            {minut.map(item => (
              <MenuItem value={item.toString()}>{item.toString()}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

Time.defaultProps = {
  minut: "00",
  hour: "12AM",
}

export default Time;