import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

function CustomFooterTotalComponent1(props) {
    return (
        <Box sx={{ padding: '10px', display: 'flex' }}>
            <h3 className="footerTable">
                Sumatoria Piezas Buenas: {props.producido}, Sumatoria Piezas Malas : {props.malas}
            </h3>
        </Box>
    );
}

CustomFooterTotalComponent1.propTypes = {
    producido: PropTypes.number,
};

export { CustomFooterTotalComponent1 };
