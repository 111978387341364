import LogoNovaLight from './LogoNovaLight.svg';
import LogoTB from './LogoTB.svg';
import LogoCol from './LogoCol.svg';
import LogoMT from './LogoMT.png';
import LogoSKB from './LogoSKB.png';

const logoMap = {
    skyTheme: LogoSKB,
    matteTheme: LogoMT,
    tecnoTheme: LogoTB,
    novaTheme: LogoNovaLight,
    colTheme: LogoCol,
};

export const getLogo = (theme) => {
    return logoMap[theme] || logoMap.novaTheme;
};
