import React from 'react';
//Components
import NormalEdit from "./LlamadaNormalEdit";
import UbicacionEdit from "./UbicacionEdit";
//Styles
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    overflow: 'auto',
    minWidth: 570,
    maxHeight: 398,
    minHeight: 398,
    paddingBottom: 50,
  },
}));

export default function TipoEdit() {
  const classes = useStyles();
  
  return (
    <div className="popupbody fijo">
      <div className={classes.root}>
        <NormalEdit />
        <h4>¿Desde dónde se puede ser levantada esta llamada?</h4>
        <UbicacionEdit />
      </div>
    </div>
  );
}