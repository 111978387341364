import React, { useState } from 'react';
//Icons
import MoreVertIcon from '@material-ui/icons/MoreVert';
//Material-UI
import {
  IconButton, 
  Menu,
  MenuItem
} from '@material-ui/core';
//Context
import { useCuenta } from './CuentaContext';
import CambiarPass from './CambiarPass';
import EditContaco from './EditContacto';

function DotsMenu (props) {
  const [anchorEl, setAnchorEl] = useState();
  const { setPass, setEditar } = useCuenta();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  }
  const handleOpen = (e) => {
    e.preventDefault();
    switch (props.option) {
      case 'Cambiar contraseña':
        setPass(true);
        setEditar(false);
        handleClose();
      break;
      case 'Editar':
        setEditar(true);
        setPass(false);
        handleClose();
      break;
      default:
      break;
    }
  };

  return(
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={event => handleOpen(event)}>
          {props.option}
        </MenuItem>
      </Menu>
      {props.option === 'Cambiar contraseña' ? <CambiarPass /> : <EditContaco />}
    </div>
  )
}

DotsMenu.defaultProps = {
  option: 'Editar',
};

export default DotsMenu;