import React from 'react';

import { Switch, Route } from 'react-router-dom';
import { routeList, cerrarSesion } from '../../routes/routeList.js';

const MainView = () => {
    return (
        <Switch>
            {routeList.map((route) => {
                if (!route.children) {
                    return (
                        <Route exact path={route.path} key={route.id} component={route.component} />
                    );
                } else {
                    return route.children.map((subroute) => (
                        <Route
                            exact
                            path={subroute.path}
                            key={subroute.id}
                            component={subroute.component}
                        />
                    ));
                }
            })}
            {cerrarSesion.map((route) => {
                if (!route.children) {
                    return (
                        <Route exact path={route.path} key={route.id} component={route.component} />
                    );
                } else {
                    return route.children.map((subroute) => (
                        <Route
                            exact
                            path={subroute.path}
                            key={subroute.id}
                            component={subroute.component}
                        />
                    ));
                }
            })}
        </Switch>
    );
};

export default MainView;
