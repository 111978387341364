import React from 'react';
//Components
import PopUp from './popup';
//Material-UI
import { TextField, Button } from '@material-ui/core';
//Icons
import { Edit } from 'tabler-icons-react';
//Context
import { useProductos } from './ProductosContext';

export default function EditProduct() {
    const {
        theProd,
        prodName,
        selectedIndex,
        putProd,
        actions,
        setEditar,
        editProduct,
        setEditProduct,
    } = useProductos();
    return (
        <PopUp
            name="editar"
            colorButton="default"
            textButton={
                <div>
                    <Edit size={20} className="icon" /> Editar
                </div>
            }
            popupSeconButton="Cancelar"
            popupsbclass="popupbps"
            isContained="text"
            isActive={actions}
            popupTitle={
                <div className="popuphead">
                    <h2 className="popuptitle">Editar producto</h2>
                </div>
            }
            popupBody={
                <div className="popupbody">
                    <div className="popuptextfield">
                        <TextField
                            id="edNombre"
                            label="NOMBRE DEL PRODUCTO"
                            fullWidth
                            onChange={(e) => {
                                setEditProduct({
                                    eNombre: e.target.value,
                                    EPP: editProduct.EPP,
                                    EPT: editProduct.EPT,
                                    sku: editProduct.sku,
                                });
                            }}
                            value={editProduct.eNombre}
                        />
                    </div>
                    <div className="popuptextfield">
                        <TextField
                            id="edSku"
                            label="CLAVE DEL PRODUCTO SKU"
                            fullWidth
                            disabled
                            value={editProduct.sku}
                        />
                    </div>
                    <div className="popuptextfield">
                        <TextField
                            id="edPzas"
                            label="PIEZAS/PAQUETE"
                            fullWidth
                            value={editProduct.EPP}
                            onChange={(e) => {
                                setEditProduct({
                                    eNombre: editProduct.eNombre,
                                    EPP: e.target.value,
                                    EPT: editProduct.EPT,
                                    sku: editProduct.sku,
                                });
                            }}
                        />
                    </div>
                    <div className="popuptextfield">
                        <TextField
                            id="edPaq"
                            name="paquetesPorTarima"
                            label="PAQUETES/TARIMA"
                            fullWidth
                            value={editProduct.EPT}
                            onChange={(e) => {
                                setEditProduct({
                                    eNombre: editProduct.eNombre,
                                    EPP: editProduct.EPP,
                                    EPT: e.target.value,
                                    sku: editProduct.sku,
                                });
                            }}
                        />
                    </div>
                    <div id="popupbs">
                        <Button
                            color="primary"
                            variant="contained"
                            className="btn-guardar"
                            onClick={(e) => {
                                putProd(e);
                            }}
                        >
                            Guardar
                        </Button>
                    </div>
                </div>
            }
        />
    );
}
