import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import '../../App.css';
//Context
import { useProductos } from './ProductosContext';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        fontFamily: 'WorkSans',
        position: 'absolute',
        width: 588,

        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '0px 0px 0px 0px',
    },
}));

const useStyles1 = makeStyles((theme) => ({
    paper: {
        fontFamily: 'WorkSans',
        position: 'absolute',
        width: 1050,
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '0px 0px 0px 0px',
    },
}));

function PopUp(props) {
    const classes = useStyles();
    const classes1 = useStyles1();
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);
    const selcClass = props.popupFlag ? classes1.paper : classes.paper;
    const { nuevo, setNuevo, editar, setEditar, borrar, setBorrar } = useProductos();

    const handleOpen = () => {
        switch(props.name){
          case 'nuevo':
            setNuevo(true);
          break;
          case 'editar':
            setEditar(true);
          break;
          case 'borrar':
            setBorrar(true);
          break;
          default:
          break;
        }
      }
  
      const handleClose = () => {
        switch(props.name){
          case 'nuevo':
            setNuevo(false);
          break;
          case 'editar':
            setEditar(false);
          break;
          case 'borrar':
            setBorrar(false);
          break;
          default:
          break;
        }
      }

    useEffect(() => {
        switch(props.name){
          case 'nuevo':
            setOpen(nuevo);
          break;
          case 'editar':
            setOpen(editar);
          break;
          case 'borrar':
            setOpen(borrar);
          break;
          default:
          break;
        }
      }, [nuevo, editar, borrar]);

    const body = (
        <div style={modalStyle} className={selcClass}>
            <div id="simple-modal-title">{props.popupTitle}</div>
            <div id="simple-modal-description">
                {props.popupBody}
                <div className="popupbottom">
                    <div className={props.popupsbclass}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className="popup-button-s"
                            onClick={handleClose}
                            fullWidth
                        >
                            {props.popupSeconButton}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

  return (
    <div>
      <Button 
        variant={props.isContained}
        color={props.colorButton} 
        className={props.buttonClass} 
        onClick={handleOpen}
        disabled={!props.isActive}
      >
        {props.textButton}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
    </div>
  )
}

PopUp.defaultProps = {
  textButton: "Agregar",
  colorButton: "primary",
  popupTitle: "Titulo",
  popupPrimButton: "Aceptar",
  popupSeconButton: "Cancelar",
  popupsbclass: "popupbp",
  isContained: "contained",
  isActive: true
}

export default PopUp;
