import React from 'react';
//Material-UI
import { 
  Grid,
  makeStyles,
  Divider,
  TextField,} from "@material-ui/core";
//Components
import DotsMenu from './DotsMenu';
//Context
import { useCuenta } from './CuentaContext';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: 20,
    paddingTop: 8,
    paddingBottom: 5,
  },
}));

export default function Contacto () {
  const { theUser } = useCuenta();
  const classes = useStyles();
  
  return(
    <div>
      <div className={classes.content}>
        <Grid container>
          <Grid item md={11}>
            <h3>Información de Contacto</h3>
          </Grid>  
          <Grid item md={1}>
            <DotsMenu />
          </Grid>
        </Grid>
      </div>
      <Divider light />
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <TextField 
              label="Teléfono" 
              fullWidth 
              value={theUser.phoneNumber}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              label="Correo Electrónico"
              fullWidth 
              value={theUser.email}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}