import React from 'react';
//Material-UI
import { 
  Grid,
  makeStyles,
  Divider,
  TextField} from "@material-ui/core";
//Components
import DotsMenu from './DotsMenu';
//Context
import { useCuenta } from './CuentaContext';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: 20,
    paddingTop: 8,
    paddingBottom: 5,
  },
}));

export default function Personal () {
  const { theUser } = useCuenta();
  const classes = useStyles();
  return(
    <div>
      <div className={classes.content}>
      <Grid container>
          <Grid item md={11}>
          <h3>Información Personal</h3>
          </Grid>  
          <Grid item md={1}>
            <DotsMenu option="Cambiar contraseña" />
          </Grid>
        </Grid>
      </div>
      <Divider light />
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <TextField 
              label="Nombres" 
              fullWidth 
              value={theUser.nombre}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Apellidos" 
              fullWidth 
              value={theUser.apellido}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={4}>
            <TextField 
              label="Nombre de usuario" 
              fullWidth 
              value={theUser.usuario}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={12}>
            <TextField 
              label="Contraseña" 
              type="password"
              value="*******"
              fullWidth 
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}