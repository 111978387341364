import { createContext, useState, useMemo, useContext } from "react";

const ShiftsContext = createContext();

export function ShiftsProvider(props) {
  const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));
  const [lineName, setLineName] = useState("");
  const [lines, setLines] = useState([]);
  const [selectedLine, setSelectedLine] = useState(0);
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [third, setThird] = useState("");
  const [selectedShift, setSelectedShift] = useState(-1);
  const [schedules, setSchedules] = useState([]);
  const [timePickActivos, setTimePickActivos] = useState([]);
  const [btnShifts, setBtnShifts] = useState(true);
  const [btnSchedule, setBtnSchedule] = useState([])

  const getLines = async () => {
    const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/lineas`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
      },
    })
    const line = await data.json()
    setLines(line)
  }

  const getShifts = (line) => {
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/turnos/linea/${line.toString()}`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
      },
    }).then(res => res.json())
      .catch()
      .then(response => {
        if (response !== null) {
          if (response.length === 3) {
            setFirst(response[0].horaInicio);
            setSecond(response[1].horaInicio);
            setThird(response[2].horaInicio);
          }
        }
      })
  }

  const putShifts = (line, shift, beginingHour) => {
    const data = {
      "turno": shift,
      "linea": line,
      "horaInicio": beginingHour
    }
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/turnos/editarturnoslinea/${line.toString()}/${shift.toString()}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(res => res.json())
      .catch(error => console.error(error))
      .then(response => {
        // TODO: Please don't do that, use react router hooks.
        window.location.replace(''); //refrescamos la página 
      })
  }

  const putSchedule = (id, beginingHour, endingHour) => {
    const data = {
      "linea": selectedLine,
      "turno": selectedShift + 1,
      "segmentos": [
        {
          "segmento": id,
          "inicio": beginingHour,
          "fin": endingHour
        }
      ]
    }
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/horarios/modificar/${selectedLine.toString()}/${(selectedShift + 1).toString()}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(res => res.json())
      .catch(error => console.error(error))
      .then(response => {
        // TODO: Please don't do that, use react router hooks.
        window.location.replace(''); //refrescamos la página 
      })
  }

  const getSchedule = (line, shift) => {
    fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/horarios/${line.toString()}/${shift.toString()}`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${tokenSessionObject.token}`,
        Accept: 'application/json',
      },
    }).then(res => res.json())
      .catch()
      .then(response => {
        if (response !== "No existen horarios asignados a línea y turno suministrados.")
          setSchedules(response.segmentos)
        else
          setSchedules([])
      })
  }

  const codif = (i) => {
    if (i === 0) {
      return "12AM";
    }
    else if (i < 12 && i > 0) {
      return [i] = `${i.toString()}AM`;
    }
    else if (i === 12) {
      return [i] = `${i.toString()}PM`;
    }
    else if (i > 12 && i < 24) {
      const format = i - 12;
      return [i] = `${format.toString()}PM`;
    }
  }

  const decod = (val) => {
    let periodo = val[val.length - 2] + val[val.length - 1];
    if (periodo === "AM") {
      if (val.length === 3) {
        return `0${val[0]}`
      }
      else if (val.length === 4) {
        return val[0] + val[1]
      }
    }
    else if (periodo === "PM") {
      const hour = parseInt((val[0] + val[1]).toString());
      if (hour !== 12) {
        return (hour + 12).toString()
      }
      return hour.toString()
    }
  }

  const buscarEnBtnSchedule = (id) => {
    btnSchedule.forEach(item => {
      if (item === id) {
        return true;
      }
    })
    return false;
  }

  const setTPvalue = (valor, id) => { //Set the value for a time picker control
    //Buscamos el id en la lista de controles time pickers
    let flag = 0;
    const newtimePickActivos = [...timePickActivos];
    newtimePickActivos.forEach(item => {
      if (item.id === id) {
        //Lo encontré, lo actualizo
        item.valor = valor;
        flag++;
      }
    })
    if (flag === 0) {
      //No se encontró, lo insertamos 
      const obj = {
        id: id,
        valor: valor
      }
      newtimePickActivos.push(obj)
    }
    setTimePickActivos(newtimePickActivos);
    if (id in [1, 2, 3]) {
      setBtnShifts(false)
    }
    else {
      if (!buscarEnBtnSchedule(id)) {
        const nBtnSchedule = [...btnSchedule];
        nBtnSchedule.push(id);
        setBtnSchedule(nBtnSchedule);
      }
    }
  }

  const shiftsValues = useMemo(() => {
    return ({
      lines, setLines,
      lineName, setLineName,
      btnShifts, setBtnShifts,
      btnSchedule, setBtnSchedule,
      selectedLine, setSelectedLine,
      selectedShift, setSelectedShift,
      first, setFirst,
      second, setSecond,
      third, setThird,
      schedules, setSchedules,
      timePickActivos, setTimePickActivos,
      setTPvalue, codif, decod, buscarEnBtnSchedule,
      getLines, getShifts, getSchedule,
      putShifts, putSchedule,
    })
  }, [lines, selectedLine, first, second, third, selectedShift, schedules, timePickActivos, btnShifts]);

  return <ShiftsContext.Provider value={shiftsValues} {...props} />;
}

export function useShifts() {
  const context = useContext(ShiftsContext);
  if (!context) {
    throw new Error('useShifts debe estar dentro del proveedor ShiftsContext');
  }
  return context;
}