import React from "react";
//Material-UI
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
//Style
import '../../styles/parametros.css';
//Context
import { useLlamadas } from "./LlamadasContext";

export default function Normal() {
  const { handleInputChange } = useLlamadas();
  return (
    <div>
      <FormControl component="fieldset" fullWidth>
        <FormLabel focused>TIPO DE LLAMADA</FormLabel>
        <Grid container>
          <Grid item sm={4}>
            <Checkbox
              name="esDowntime"
              onChange={e => handleInputChange(e)}
            />
            Downtime
          </Grid>
          <Grid item sm={3}>
            <Checkbox
              name="esSonora"
              onChange={e => handleInputChange(e)}
            />
            Sonora
          </Grid>
          <Grid item sm={5}>
            <Checkbox
              name="requiereClasificacion"
              onChange={e => handleInputChange(e)}
            />
            Requiere clasificación
          </Grid>
        </Grid>
      </FormControl>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <TextField
            name="tiempoStdRespuesta"
            label="TIEMPO DE RESPUESTA"
            type="number"
            fullWidth
            onChange={e => handleInputChange(e)}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            name="tiempoStdReparacion"
            label="TIEMPO DE REPARACIÓN"
            type="number"
            fullWidth
            onChange={e => handleInputChange(e)}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            name="tiempoNivel3"
            label="TIEMPO NIVEL 3"
            type="number"
            fullWidth
            onChange={e => handleInputChange(e)}
          />
        </Grid>
      </Grid>
    </div>
  )
}