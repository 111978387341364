import React, { useEffect, useState } from 'react';

//Components
import Menu from './Menu';
import Botones from './Botones';
import GraphBarReport from './GraphBarReport';
import ProductionReport from './ProductionReport';
import GraphProgress from './GraphProgress';
import GraphRadar from './GraphRadar';
import TendenciaOEE from './TendenciaOEE';
//Context
import { ReportesProvider, useReportes } from './ReportesContex';

import '../../styles/reportes.css';
import SkuOrden from './SkuOrden';
import Corridas from './Corridas';

const setColor = (newColor) => {
    document.documentElement.style.setProperty('--color', newColor);
};

function Reportes() {
    const { selectedIndex } = useReportes();

    let primColor = '';
    if (process.env.REACT_APP_THEME === 'tecnoTheme') {
        primColor = '#ff9800';
        setColor(primColor);
    } else if (process.env.REACT_APP_THEME === 'colTheme') {
        primColor = '#58ab01';
        setColor(primColor);
    } else if (process.env.REACT_APP_THEME === 'matteTheme') {
        primColor = '#253139';
        setColor(primColor);
    } else if (process.env.REACT_APP_THEME === 'skyTheme') {
        primColor = '#001B69';
        setColor(primColor);
    } else {
        primColor = '#903bff';
        setColor(primColor);
    }

    return (
        <div className="reportesView">
            <div className="reportesView-menu">
                <div className="reportesView-menu-head">
                    <h1 className="titulo">Reportes</h1>
                </div>
                <div className="reportesView-menu-body">
                    <Menu />
                </div>
            </div>
            <div className="reportesView-body">
                <div className="reportesView-body-head">
                    <Botones />
                </div>
                {/* <div className="reportesView-body-graph">
                    <div className="reportesView-body-graph-panel3">
                        <TendenciaOEE />
                    </div>
                </div>
                <div className="reportesView-body-graph-panel45">
                    <div className="reportesView-body-graph-panel45-head">
                        <h4>OEE</h4>
                    </div>
                    <div className="reportesView-body-graph-panel45-body">
                        <div className="reportesView-body-graph-panel4">
                            <GraphProgress progress={60} linea={'linea G'} />
                            <GraphProgress progress={20} linea={'linea A'} />
                            <GraphProgress progress={90} linea={'linea B'} />
                            <GraphProgress progress={100} linea={'linea F'} />
                        </div>
                        <div className="reportesView-body-graph-panel5">
                            <GraphRadar />
                        </div>
                    </div>
                </div>
                <div className="reportesView-body-graph">
                    <div className="reportesView-body-graph-panel3">
                        <ProductionReport />
                    </div>
                </div>
                <div className="reportesView-body-graph">
                    <div className="reportesView-body-graph-panel1">
                        <GraphBarReport
                            data={[400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]}
                            categories={[
                                'Derrame',
                                'Banda transportadora',
                                'Sensor',
                                'Atasco de maquina',
                                'Envasado',
                                'Desgaste Rodillo',
                                'Desgaste escobillas',
                                'Vascula desajustada',
                                'Motor atrascado',
                                'Fala electricidad',
                            ]}
                            text="Fallas de mayor duración:"
                        />
                    </div>
                    <div className="reportesView-body-graph-panel2">
                        <GraphBarReport
                            data={[200, 530, 348, 470, 540, 580, 690, 1100, 1150, 1480]}
                            categories={[
                                'Derrame',
                                'Banda transportadora',
                                'Sensor',
                                'Atasco de maquina',
                                'Envasado',
                                'Desgaste Rodillo',
                                'Desgaste escobillas',
                                'Vascula desajustada',
                                'Motor atrascado',
                                'Fala electricidad',
                            ]}
                            text="Fallas de mayor incidencia:"
                        />
                    </div>
                </div> */}
                <div className="reportesView-body-graph">
                    {selectedIndex === 1 ? <SkuOrden /> : null}
                    {selectedIndex === 2 ? <Corridas /> : null}
                </div>
            </div>
        </div>
    );
}

export default () => (
    <ReportesProvider>
        <Reportes></Reportes>
    </ReportesProvider>
);
