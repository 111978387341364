import React, { useEffect, useRef, useState } from 'react';
//Context
import { useHome } from './HomeContext';
//Styles
import '../../styles/home.css';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';

export default function GanttGraph() {
    const { lines, lineSelect, nSeries } = useHome();
    const es = require('apexcharts/dist/locales/es.json');

    const [flag, setFlag] = useState(false);
    let indice = undefined;
    const options = {
        annotations: {
            position: 'front',
            xaxis: [
                {
                    x: new Date().getTime(),
                    x2: null,

                    strokeDashArray: 0,
                    // borderColor: '#5E3F94',
                    // fillColor: '#5E3F94',
                    opacity: 1,
                    offsetX: 0,
                    offsetY: 0,
                    label: {
                        // borderColor: '#5E3F94',
                        borderWidth: 1,
                        borderRadius: 2,
                        textAnchor: 'middle',
                        position: 'top',
                        orientation: 'vertical',
                    },
                },
            ],
        },
        // colors: ['#5E3F94'],

        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                const label = opts.w.globals.seriesNames[opts.seriesIndex];
                return label;
            },
            distributed: false,
            style: {
                colors: ['#fff'],
                fontSize: '16px',
                fontFamily: 'WorkSans',
                fontWeight: 550,
                cssClass: 'apexcharts-yaxis-label',
            },
        },
        chart: {
            redrawOnWindowResize: true,
            redrawOnParentResize: true,
            type: 'rangeBar',
            group: 'timeline',
            id: 'rangeBar',
            responsive: [
                {
                    breakpoint: 200,
                    options: {
                        chart: {
                            width: '40%',
                        },
                    },
                },
            ],
            events: {
                dataPointMouseEnter: function (event, chartContext, config) {
                    const chart = ApexCharts.getChartByID('rangeBar');
                    let idx = config.w.globals.seriesNames.indexOf(
                        config.w.globals.seriesNames[config.seriesIndex]
                    );

                    while (idx !== -1) {
                        if (idx !== config.seriesIndex) {
                            indice = idx;
                        }
                        idx = config.w.globals.seriesNames.indexOf(
                            config.w.globals.seriesNames[config.seriesIndex],
                            idx + 1
                        );
                    }
                    console.log(indice);
                    chart.toggleDataPointSelection(indice, 0);
                },
                dataPointMouseLeave: function (event, chartContext, config) {
                    const chart = ApexCharts.getChartByID('rangeBar');
                    console.log(indice);
                    console.log(config);
                    chart.toggleDataPointSelection(config.seriesIndex, 0);
                    chart.toggleDataPointSelection(config.seriesIndex, 0);
                },
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 500,
                },
            },
            locales: [es],
            defaultLocale: 'es',
            fontFamily: 'WorkSans',
            offsetY: -80,
            toolbar: {
                show: true,
                offsetY: -120,
                tools: {
                    download: false,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                },
                autoSelected: 'pan',
            },
            zoom: {
                enabled: true,
            },
        },
        states: {
            hover: {
                filter: {
                    type: 'lighten',
                    value: 0.15,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'lighten',
                    value: 0.15,
                },
            },
        },

        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: 60,
                borderRadius: 5,
                borderRadiusApplication: 'around',
                // borderRadiusWhenStacked: 'all',
                distributed: true,
                rangeBarGroupRows: true,
                // rangeBarOverlap: false,
                dataLabels: {
                    show: true,
                    position: 'center',
                    orientation: 'horizontal',
                    hideOverflowingLabels: true,
                },
            },
        },
        xaxis: {
            type: 'datetime',
            position: 'Top',
            datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm',
            },
            axisBorder: {
                show: true,
                color: '#78909C',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0,
            },
            labels: {
                show: true,
                offsetY: -15,
                datetimeUTC: false,
                datetimeFormatter: {
                    year: 'yyyy',
                    month: "MMM 'yy",
                    day: 'dd MMM',
                    hour: 'HH:mm',
                },
                style: {
                    fontSize: '16px',
                    fontFamily: 'WorkSans',
                    fontWeight: 600,
                    cssClass: 'apexcharts-xaxis-label',
                    textAlign: 'left',
                    letterSpacing: 0,
                    color: '#2B2930',
                    opacity: 1,
                },
            },
        },
        // colors: [
        // function ({ value, seriesIndex, w }) {
        //     // console.log(w.config.series[seriesIndex].data[0].x === 'Plan');
        //     // console.log(seriesIndex);
        //     if (w.config.series[seriesIndex].data[0].x === 'Plan') {
        //         return '#7456a8';
        //     } else {
        //         return '#00b884';
        //     }

        // if (w.globals.seriesZ[seriesIndex][0] === 0) {
        //     return '#8A8A8A';
        // } else if (w.globals.seriesZ[seriesIndex][0] === 1) {
        //     return '#2ccb9e';
        // } else if (w.globals.seriesZ[seriesIndex][0] === 2) {
        //     return '#ffc800';
        // } else if (w.globals.seriesZ[seriesIndex][0] === 3) {
        //     return '#FE93C2';
        // } else if (w.globals.seriesZ[seriesIndex][0] === 4) {
        //     return '#c8afe8';
        // } else if (w.globals.seriesZ[seriesIndex][0] === 5) {
        //     return '#e77f85';
        // } else {
        //     return '#fe424d';
        // }
        // },
        // ],

        yaxis: {
            show: true,
            axisBorder: {
                show: true,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0,
            },
            labels: {
                show: true,
                minWidth: 40,
                align: 'right',
                style: {
                    fontSize: '18px',
                    fontFamily: 'WorkSans',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-label',
                },
            },
        },

        stroke: {
            width: 1,
        },
        fill: {
            type: 'solid',
            opacity: 0.8,
        },
        legend: {
            show: false,
            position: 'bottom',
            onItemClick: {
                toggleDataSeries: true,
            },
        },

        grid: {
            show: true,
            borderColor: '#90A4AE',
            strokeDashArray: 0,
            position: 'front',
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
            row: {
                colors: undefined,
                opacity: 0.5,
            },
            column: {
                colors: undefined,
                opacity: 0.5,
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
        tooltip: {
            enabled: true,
            enabledOnSeries: undefined,
            shared: false,
            followCursor: true,
            fillSeriesColor: true,

            // x: {
            //     show: true,
            //     format: 'dd MMM hh:mm',
            // },

            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const fechaIni = new Date(w.config.series[seriesIndex].data[0].y[0]);
                const fechaFin = new Date(w.config.series[seriesIndex].data[0].y[1]);
                if (w.config.series[seriesIndex].data[0].x === 'Plan') {
                    return (
                        '<div class="arrow_box">' +
                        '<h3>' +
                        'Corrida: ' +
                        w.config.series[seriesIndex].name +
                        '</h3>' +
                        '<span>' +
                        '<b>' +
                        w.config.series[seriesIndex].data[0].x +
                        ': ' +
                        '</b>' +
                        '</br>' +
                        'Fecha inicial: ' +
                        fechaIni.toLocaleString().slice(0, fechaIni.toLocaleString().length - 3) +
                        '</br>' +
                        'Fecha Final: ' +
                        fechaFin.toLocaleString().slice(0, fechaFin.toLocaleString().length - 3) +
                        '</br>' +
                        'Meta' +
                        ': ' +
                        w.config.series[seriesIndex].data[0].z +
                        '</span>' +
                        '</div>'
                    );
                } else {
                    return (
                        '<div class="arrow_box">' +
                        '<h3>' +
                        'Corrida: ' +
                        w.config.series[seriesIndex].name +
                        '</h3>' +
                        '<span>' +
                        '<b>' +
                        w.config.series[seriesIndex].data[0].x +
                        ': ' +
                        '</b>' +
                        '</br>' +
                        'Fecha inicial: ' +
                        fechaIni.toLocaleString().slice(0, fechaIni.toLocaleString().length - 3) +
                        '</br>' +
                        'Fecha Final: ' +
                        fechaFin.toLocaleString().slice(0, fechaFin.toLocaleString().length - 3) +
                        '</br>' +
                        'Producido' +
                        ': ' +
                        w.config.series[seriesIndex].data[0].z +
                        '</span>' +
                        '</div>'
                    );
                }
            },
        },

        noData: {
            text: 'No existen corridas para la fecha seleccionada',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: '25px',
                fontFamily: undefined,
            },
        },
    };

    useEffect(() => {
        if (lines.length > 0) {
            setFlag(true);
        }
    }, [lines]);

    return (
        <div className="wrapper">
            {flag === true ? (
                <div className="line">
                    <div
                        style={{
                            display: 'flex',
                            backgroundColor: 'red',
                            width: '10%',
                            height: '100%',
                        }}
                    ></div>
                    <div
                        style={{
                            display: 'flex',
                            width: '90%',
                            justifyContent: 'center',
                        }}
                    >
                        <h3>{lines[lineSelect - 1].nombre} </h3>
                    </div>
                </div>
            ) : null}

            <div className="graphPvR">
                <div id="Plan">
                    <Chart
                        options={options}
                        series={nSeries}
                        type="rangeBar"
                        // ref={gantGraph}
                        height={180}
                        width="100%"
                    />
                </div>
            </div>
        </div>
    );
}
