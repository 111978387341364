// Icons
import {
    AdjustmentsHorizontal,
    ClipboardCheck,
    GridDots,
    Package,
    Point,
    ReportAnalytics,
    Settings,
    Urgent,
    Users,
    Logout,
    Checkbox,
} from 'tabler-icons-react';

// Route Components
// This should come from pages/ and not from routes.
import MyAccount from '../pages/MyAccount.jsx';
import CalendarProduc from './calendarProduc.js';
import Fallas from '../pages/Fallas';
import OrdenProduc from '../pages/ListaOrdenes';
import Productos from '../pages/Products';
import Turnos from '../pages/Shifts';
import Festivos from '../pages/Holidays';
import Llamadas from '../pages/Llamadas';
import LogOut from './LogOut.js';
import Home from '../pages/Home';
import Capacidades from '../pages/Capacidades';
import Usuarios from '../pages/Usuarios';
import Reportes from '../pages/Reportes';
import Inventarios from '../pages/Inventarios';
import Cuenta from '../pages/Cuenta/index.jsx';
import Beepers from '../pages/Beepers/index.jsx';

export const routeList = [
    {
        id: 'home',
        text: 'Panel Principal',
        icon: GridDots,
        path: '/',
        component: Home,
    },
    {
        id: 'reportes',
        text: 'Reportes',
        icon: ReportAnalytics,
        path: '/reportes',
        component: Reportes,
    },
    {
        id: 'ordenes',
        text: 'Órdenes de Producción',
        icon: ClipboardCheck,
        path: '/ordenes',
        isModal: false,
        component: OrdenProduc,
        children: [
            {
                id: 'ordenes-lista',
                text: 'Lista de ordenes',
                icon: Point,
                path: '/ordenes/lista',
                component: OrdenProduc,
            },
            {
                id: 'ordenes-calendario',
                text: 'Calendario de ordenes',
                icon: Point,
                path: '/ordenes/calendario',
                component: CalendarProduc,
            },
        ],
    },
    {
        id: 'productos',
        text: 'Productos',
        icon: Package,
        path: '/productos',
        component: Productos,
    },
    {
        id: 'alertas',
        text: 'Fallas, llamadas y alertas',
        icon: Urgent,
        path: '/alertas',
        component: Fallas,
        children: [
            {
                id: 'alertas-fallas',
                text: 'Fallas',
                icon: Point,
                path: '/alertas/fallas',
                component: Fallas,
            },
            {
                id: 'alertas-llamadas',
                text: 'Llamadas',
                icon: Point,
                path: '/alertas/llamadas',
                component: Llamadas,
            },
            {
                id: 'alertas-noprod',
                text: 'Alertas (No Producción)',
                icon: Point,
                path: '/alertas/noprod',
                component: Home,
            },
        ],
    },
    {
        id: 'parametros',
        text: 'Parámetros',
        icon: AdjustmentsHorizontal,
        path: '/parametros',
        component: Home,
        children: [
            {
                id: 'parametros-ritmo',
                text: 'Ritmo Base',
                icon: Point,
                path: '/parametros/ritmo',
            },
            {
                id: 'parametros-turnos',
                text: 'Turnos',
                icon: Point,
                path: '/parametros/turnos',
                component: Turnos,
            },
            {
                id: 'parametros-capacidad',
                text: 'Capacidad',
                icon: Point,
                path: '/parametros/capacidad',
                component: Capacidades,
            },
            {
                id: 'parametros-festivos',
                text: 'Festivos',
                icon: Point,
                path: '/parametros/festivos',
                component: Festivos,
            },
        ],
    },
    {
        id: 'usuarios',
        text: 'Usuarios y Roles',
        icon: Users,
        path: '/usuarios',
        component: Usuarios,
    },
    {
        id: 'inventarios',
        text: 'Inventarios',
        icon: Checkbox,
        path: '/inventarios',
        component: Inventarios,
    },
    {
        id: 'config',
        text: 'Configuración',
        icon: Settings,
        path: '/config',
        component: Home,
        children: [
            {
                id: 'config-cuenta',
                text: 'Mi Cuenta',
                icon: Point,
                path: '/config/cuenta',
                component: Cuenta,
            },
            {
                id: 'config-beepers',
                text: 'Beepers',
                icon: Point,
                path: '/config/beepers',
                component: Beepers,
            },
            {
                id: 'config-ip',
                text: 'Direccion IP',
                icon: Point,
                path: '/config/ip',
                component: Home,
            },
            {
                id: 'config-correo',
                text: 'Servidor de Correo',
                icon: Point,
                path: '/config/correo',
                component: Home,
            },
        ],
    },
];

export const cerrarSesion = [
    {
        id: 'LogOut',
        text: 'Cerrar Sesión',
        icon: Logout,
        path: '/LogOut',
        component: LogOut,
    },
];
