import React, { useState, useEffect } from "react";
//Components
import PopUp from "./PopUp";
import General from "./General";
import Tipo from "./Tipo";
//Material-UI
import { Button, Grid } from "@material-ui/core";
import { IconButton } from '@material-ui/core';
//Icons
import { X } from 'tabler-icons-react';
import { CirclePlus } from 'tabler-icons-react';
//Context
import { useLlamadas } from './LlamadasContext';
import LevantarNotificar from "./LevantarNotificar";
//Styles
import '../../styles/llamadas.css'


function NuevoTipoLlamada() {
  const { step, setStep, setNuevo, postCallType, clear } = useLlamadas();
  const [primaryButton, setPrimaryButton] = useState('Siguiente');
  const [secondaryBtn, setSecondaryBtn] = useState('Cancelar');
  const increment = (e) => {
    e.preventDefault();
    if (step < 2)
      setStep(step + 1);
    else if (step > 1)
      postCallType();
  };
  const secondAction = () => {
    if (step > 0)
      setStep(step - 1);
    else{
      setNuevo(false);
      clear();
    }
  }

  const handleClose = (event) => {
    event.preventDefault();
    setNuevo(false);
    setStep(0);
    clear();
  }

  useEffect(() => {
    if (step > 0)
      setSecondaryBtn('Atrás');
    else
      setSecondaryBtn('Cancelar');
    if (step > 1)
      setPrimaryButton('Finalizar');
    else
      setPrimaryButton('Siguiente');
  }, [step]);

  return (
    <PopUp
      name="nuevo"
      buttonClass="productos-button"
      colorButton="primary"
      isContained="contained"
      textButton={
        <div>
          <div><CirclePlus size={18} className="icon" /> Agregar </div>
        </div>
      }
      popupTitle={ 
        <div className="popupheadCalltype">
          <h2 className="popuptitle">
            Nuevo Tipo de Llamada
          </h2>
          <IconButton className="exClose" onClick={(handleClose)}>
            <X />
          </IconButton>
        </div>
      }
      popupBody={
        <div>
          {
            (step === 0)
              ? <General /> :
              (step === 1)
                ? <Tipo /> :
                (step === 2)
                  ? <LevantarNotificar />
                  : <h1>Error</h1>
          }
          <Grid
            container
            spacing={1}
            className='popupButtons'
          >
            <Grid item md={10}>
              <Button
                variant="outlined"
                color="primary"
                className="popup-button-s"
                onClick={secondAction}
              >
                {secondaryBtn}
              </Button>
            </Grid>
            <Grid item md={2}>
              <Button
                className="popup-button-s"
                variant="contained"
                color="primary"
                onClick={e => increment(e)}
              >
                {primaryButton}
              </Button>
            </Grid>
          </Grid>
        </div>
      }
    />
  )
}

export default NuevoTipoLlamada;