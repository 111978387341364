import React from "react";
//Components
import PopUp from "./popup";
import TablaProductos from "./TablaProductos";
//Material-UI
import { Grid, makeStyles, } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    minHeight: 562,
  },
}));

export default function ProductosList (){
  const classes = useStyles();
  return (
    <PopUp
      name="productos"
      colorButton="primary"
      buttonClass="popup-button-p"
      textButton="Buscar"
      popupSeconButton="Cancelar"
      popupsbclass="popupbp"
      popupFlag
      popupTitle={
          <div className="popuphead4">
              <h2 className="popuptitle">Buscar Producto</h2>
              <p className="popuptitled">
                  Seleccione un proudcto
              </p>
          </div>
      }
      popupBody={
        <div className="popupbody1">
          <Grid container style={{marginBottom: 50}}>
            <Grid item md={12}>
              <div className={classes.root}>
                <TablaProductos />
              </div>
            </Grid>
          </Grid>
        </div>
      }
    />
  )
}