import { createTheme } from '@material-ui/core/styles';

export const skyTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#001B69',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#3f4a51',
            contrastText: '#FFFFFF',
        },
        divider: '#f2f4f8',
    },
});
