import React, { useState, useEffect } from 'react';
//Components
import PopUp from './popup';
//Material-UI
import { TextField, Button, InputLabel, Select, MenuItem } from '@material-ui/core';
//Icons
import { Edit } from 'tabler-icons-react';
//Context
import { useOrdProd } from './OrdProdContext';

export default function EditarOrd() {
    const { editOrd, product, selectionModel, theOrder, isEdit, setEditar } = useOrdProd();
    const [prod, setProd] = useState();
    const [skuLocal, setSkuLocal] = useState('');
    const [fechaLocal, setFechaLocal] = useState('2022-01-01T00:00');
    const [cantidadLocal, setCantidadLocal] = useState(0);
    const [fechaE, setFechaE] = useState('2022-01-01');
    const [cliente, setCliente] = useState('');

    const putNewOrd = (event) => {
        event.preventDefault();
        let status = 1;

        if (parseInt(document.getElementById('edCantidad').value) >= theOrder.cantidad) {
            status = theOrder.estatus;
        }
        const ordEd = {
            id: null,
            ordenProd: document.getElementById('edOrdenProd').value,
            sku: prod,
            fecha: document.getElementById('edFecha').value + 'T00:00:00',
            cantidad: parseInt(document.getElementById('edCantidad').value),
            estatus: status,
            skuNombre: null,
            fechaEntrega: document.getElementById('edFechaF').value + 'T00:00:00',
            cliente: document.getElementById('edCliente').value,
            cantidadCalendario: theOrder.cantidadCalendario,
            producido: theOrder.producido,
        };
        editOrd(ordEd);
        setEditar(false);
    };

    const handleChange = (event) => {
        setProd(event.target.value);
    };

    useEffect(() => {
        if (theOrder !== undefined) {
            setSkuLocal(theOrder.sku);
            setProd(theOrder.sku);
            setFechaLocal(theOrder.fecha.slice(0, 10));
            setCantidadLocal(theOrder.cantidad);
            setFechaE(theOrder.fechaEntrega.slice(0, 10));
            setCliente(theOrder.cliente);
        }
    }, [theOrder]);

    return (
        <PopUp
            name="editar"
            colorButton="default"
            textButton={
                <div>
                    <Edit size={20} className="icon" /> Editar
                </div>
            }
            buttonClass="actions-edit"
            popupSeconButton="Cancelar"
            popupsbclass="popupbps"
            isContained="text"
            isActive={isEdit}
            popupTitle={
                <div className="popuphead">
                    <h2 className="popuptitle-nodesc">Editar Orden De Producción</h2>
                </div>
            }
            popupBody={
                <div className="popupbody">
                    <div className="popuptextfield">
                        <TextField
                            id="edOrdenProd"
                            label="ORDEN DE PRODUCCIÓN"
                            fullWidth
                            disabled
                            defaultValue={selectionModel[0]}
                        />
                    </div>
                    <div className="popuptextfield">
                        <InputLabel id="uxedSku" fullWidth>
                            PRODUCTO
                        </InputLabel>
                        <Select
                            labelId="edSku"
                            name="edSku"
                            fullWidth
                            onChange={handleChange}
                            value={prod}
                            defaultValue={skuLocal}
                        >
                            {product.map((item) => (
                                <MenuItem key={item.id} value={item.sku}>
                                    {item.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="popuptextfield">
                        <TextField
                            id="edFecha"
                            name="edFecha"
                            label="FECHA CREACIÓN"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            defaultValue={fechaLocal}
                            fullWidth
                        />
                    </div>
                    <div className="popuptextfield">
                        <TextField
                            id="edFechaF"
                            name="fecha"
                            label="FECHA POSIBLE DE ENTREGA"
                            type="date"
                            value={fechaE}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            // onChange={handleinputChange}
                        />
                    </div>
                    <div className="popuptextfield">
                        <TextField
                            id="edCliente"
                            name="cliente"
                            required
                            label="NOMBRE DEL CLIENTE"
                            defaultValue={cliente}
                            fullWidth
                            // onChange={handleinputChange}
                        />
                    </div>
                    <div className="popuptextfield">
                        <TextField
                            id="edCantidad"
                            label="CANTIDAD"
                            type="number"
                            defaultValue={cantidadLocal}
                        />
                    </div>
                    <div id="popupbs">
                        <Button
                            color="primary"
                            variant="contained"
                            className="btn-guardar"
                            onClick={putNewOrd}
                        >
                            Guardar
                        </Button>
                    </div>
                </div>
            }
        />
    );
}
