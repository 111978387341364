import { createContext, useState, useEffect, useMemo, useContext } from 'react';

const HomeContext = createContext();

export function HomeProvider(props) {
    const tokenSessionObject = JSON.parse(sessionStorage.getItem('token'));
    const [prueba, setPrueba] = useState('false');
    const [selectedF, setSelectedF] = useState(new Date());
    const [selectedL, setSelectedL] = useState(3);
    const [lines, setLines] = useState([]);
    const [lineSelect, setLineSelect] = useState(1);
    const [fechaWIni, setFechaWIni] = useState(new Date('11-07-2022'));
    const [fechaWFin, setFechaWFin] = useState(new Date('11-15-2022'));
    const [orders, setOrders] = useState([]);
    const [corridasR, setCorridasR] = useState([]);
    const [oee, setOee] = useState(0);
    const [mtbf, setMtbf] = useState(0);
    const [mttr, setMttr] = useState(0);
    const [nSeries, setNSeries] = useState([]);
    const [nSeries1, setNSeries1] = useState([]);

    const getLines = async () => {
        const data = await fetch(`${process.env.REACT_APP_SERVICE_ENDPOINT}/lineas`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${tokenSessionObject.token}`,
                Accept: 'application/json',
            },
        });
        const lines = await data.json();

        if (data.status !== 400) {
            setLines(lines);
            findWeek();
        }
    };

    // const getAllOrders = async () => {
    //     const fechaini = fechaWIni.toISOString().slice(0, 10);
    //     const fechafin = fechaWFin.toISOString().slice(0, 10);

    //     const data = await fetch(
    //         `${process.env.REACT_APP_SERVICE_ENDPOINT}/Calendario/ListadoOrdenesFecha?start=${fechaini}&end=${fechafin}`,
    //         {
    //             method: 'GET',
    //             headers: {
    //                 authorization: `Bearer ${tokenSessionObject.token}`,
    //                 Accept: 'application/json',
    //                 'Content-Type': 'application/json',
    //             },
    //         }
    //     );
    //     const ordenes = await data.json();
    //     if (data.status !== 400) {
    //     }
    // };

    const getOrders = async (fIni, fFin) => {
        if (lineSelect !== -1) {
            const fechaini = fIni.toISOString().slice(0, 10) + 'T00:00:00.000Z';
            const fechafin = fFin.toISOString().slice(0, 10) + 'T23:59:59.000Z';

            const data = await fetch(
                `${process.env.REACT_APP_SERVICE_ENDPOINT}/Calendario/ordenesFecha?linea=${lineSelect}&start=${fechaini}&end=${fechafin}`,
                {
                    method: 'GET',
                    headers: {
                        authorization: `Bearer ${tokenSessionObject.token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
            );

            const ordenes = await data.json();

            if (data.status === 200) {
                setOrders(ordenes);
                getCorridasR(fIni, fFin);
            }
        }
        //else {
        //     getAllOrders();
        // }
    };

    const getCorridasR = async (fIni, fFin) => {
        if (lineSelect !== -1) {
            const fechaini = fIni.toISOString().slice(0, 10) + 'T00:00:00.000Z';
            const fechafin = fFin.toISOString().slice(0, 10) + 'T23:59:59.000Z';

            const data = {
                fechaini: fechaini,
                fechafin: fechafin,
            };

            const corri = await fetch(
                `${process.env.REACT_APP_SERVICE_ENDPOINT}/Estadisticas/ReporteCorridas`,
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        authorization: `Bearer ${tokenSessionObject.token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
            );

            const corridas = await corri.json();

            if (corri.status === 200) {
                setCorridasR(corridas);
                updateStatistics(fIni, fFin);
            }
        }
    };

    const sumarDias = (fecha, dias) => {
        fecha.setDate(fecha.getDate() + dias);
        return fecha;
    };

    const handleDateUpdateEvents = async (line) => {};

    const findWeek = () => {
        // Toda la semana
        // const dia = new Date(selectedF).getDay();
        // const fIni = sumarDias(new Date(selectedF), -(dia - 1));
        // const fFin = sumarDias(new Date(fIni), 7);
        // tres dias

        const fIni = sumarDias(new Date(selectedF), -1);
        const fFin = sumarDias(new Date(selectedF), 1);

        setFechaWIni(fIni);
        if (fFin.getTime() > selectedF.getTime()) {
            setFechaWFin(fFin);
        } else {
            setFechaWFin(selectedF);
        }
        getOrders(fIni, fFin);
    };

    const updateStatistics = async (fIni, fFin) => {
        const fechaini = fIni.toISOString();
        const fechafin = fFin.toISOString();
        const data = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Estadisticas/GetOEEGeneral?ini=${fechaini}&fin=${fechafin}&linea=${lineSelect}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );

        const res = await data.json();
        if (data.status === 200) {
            setOee(Math.round(res));
        } else {
            setOee(0);
        }
        const data1 = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Estadisticas/GetMtbfGeneral?ini=${fechaini}&fin=${fechafin}&linea=${lineSelect}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );
        const res1 = await data1.json();
        if (data1.status === 200) {
            setMtbf(res1);
        } else {
        }
        const data2 = await fetch(
            `${process.env.REACT_APP_SERVICE_ENDPOINT}/Estadisticas/GetMttrGeneral?ini=${fechaini}&fin=${fechafin}&linea=${lineSelect}`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${tokenSessionObject.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );
        const res2 = await data2.json();
        if (data2.status === 200) {
            setMttr(res2);
        }
    };

    const updateData = () => {
        let datos = [];
        console.log(lineSelect, selectedL);

        if (lineSelect === 0) {
            lines.map((item) => {
                datos.push({
                    name: item.nombre,
                    data: [
                        {
                            x: item.nombre,
                            y: [new Date().getTime(), sumarDias(new Date(), 2).getTime()],
                        },
                    ],
                });
            });
        } else {
            if (selectedL === 3) {
                orders.map((orden, index) => {
                    if (index < corridasR.length && corridasR.length > 0) {
                        datos.push(
                            {
                                name: orden.corrida,

                                data: [
                                    {
                                        x: 'Plan',
                                        y: [
                                            new Date(orden.fechaIni).getTime(),
                                            new Date(orden.fechaFin).getTime(),
                                        ],
                                        z: orden.meta,
                                    },
                                ],
                            },

                            {
                                name: corridasR[index].corrida,

                                data: [
                                    {
                                        x: 'Real',
                                        y: [
                                            new Date(corridasR[index].fechaIniReal).getTime(),
                                            new Date(corridasR[index].fechaFinReal).getTime(),
                                        ],
                                        z: corridasR[index].producido,
                                    },
                                ],
                            }
                        );
                    } else {
                        datos.push({
                            name: orden.corrida,

                            data: [
                                {
                                    x: 'Plan',
                                    y: [
                                        new Date(orden.fechaIni).getTime(),
                                        new Date(orden.fechaFin).getTime(),
                                    ],
                                    z: orden.meta,
                                },
                            ],
                        });
                    }
                });
            } else if (selectedL === 2) {
                if (corridasR.length > 0) {
                    orders.map((orden, index) => {
                        datos.push({
                            name: corridasR[index].corrida,
                            data: [
                                {
                                    x: 'Real',
                                    y: [
                                        new Date(corridasR[index].fechaIniReal).getTime(),
                                        new Date(corridasR[index].fechaFinReal).getTime(),
                                    ],
                                    z: corridasR[index].producido,
                                },
                            ],
                        });
                    });
                }
            } else {
                orders.map((orden, index) => {
                    datos.push({
                        name: orden.corrida,
                        data: [
                            {
                                x: 'Plan',
                                y: [
                                    new Date(orden.fechaIni).getTime(),
                                    new Date(orden.fechaFin).getTime(),
                                ],
                                z: orden.meta,
                            },
                        ],
                    });
                });
            }
        }
        console.log(datos);
        setNSeries(datos);
    };

    useEffect(() => {
        getLines();
    }, []);

    useEffect(() => {
        updateData();
    }, [orders, corridasR]);

    useEffect(() => {
        if (lines.length > 0) {
            setOrders([]);
            setCorridasR([]);
            findWeek();
        }
    }, [lineSelect, selectedF]);

    useEffect(() => {
        if (lines.length > 0) {
            updateData();
        }
    }, [selectedL]);

    const HomeValues = useMemo(() => {
        return {
            prueba,
            setPrueba,
            selectedF,
            setSelectedF,
            selectedL,
            setSelectedL,
            lines,
            lineSelect,
            setLineSelect,
            handleDateUpdateEvents,
            fechaWIni,
            setFechaWIni,
            fechaWFin,
            setFechaWFin,
            orders,
            getOrders,
            findWeek,
            sumarDias,
            updateData,
            nSeries,
            updateStatistics,
            oee,
            mtbf,
            setMtbf,
            mttr,
            setMttr,
            nSeries1,
        };
    }, [
        prueba,
        setPrueba,
        selectedF,
        setSelectedF,
        selectedL,
        setSelectedL,
        lines,
        lineSelect,
        setLineSelect,
        handleDateUpdateEvents,
        fechaWIni,
        setFechaWIni,
        orders,
        getOrders,
        findWeek,
        sumarDias,
        updateData,
        nSeries,
        updateStatistics,
        oee,
        mtbf,
        setMtbf,
        mttr,
        setMttr,
        nSeries1,
    ]);

    return <HomeContext.Provider value={HomeValues} {...props} />;
}

export function useHome() {
    const context = useContext(HomeContext);
    if (!context) {
        throw new Error('useOrdProd debe estar dentro del proveedor HomeContext');
    }
    return context;
}
