import React, { useEffect } from 'react';
//Components
import NuevaCatego from './NuevaCatego';
import NuevaFalla from './NuevaFalla';
//Context
import { FallasProvider, useFallas } from './FallasContext';
//Material-UI
import Grid from '@material-ui/core/Grid';
import { DataGrid, esES } from '@material-ui/data-grid';
import { Divider } from '@material-ui/core';
import '../../styles/fallas.css';

const categoColumns = [
    { field: 'categoria', headerName: 'Categoría', width: 140 },
    { field: 'nombre', headerName: 'Nombre', width: 130 },
];

const fallasColumns = [
    { field: 'areaNombre', headerName: 'Área', width: 110 },
    { field: 'categoria', headerName: 'Categoría', width: 140 },
    { field: 'nombre', headerName: 'Nombre de la falla', width: 220 },
];

function Fallas() {
    const { catego, fallas, handleDataSelect, getAreasAndFallas, getCatego } = useFallas();

    useEffect(() => {
        getCatego();
        getAreasAndFallas();
    }, []);

    return (
        <div className="fallasView">
            <div className="head">
                <h1 className="titulo">Fallas</h1>
                <p className="desc">Visualizar las fallas existentes y sus categorías</p>
            </div>
            <div className="bodyFallas">
                <Grid container spacing={0} alignItems="center">
                    <Grid item md={5} lg={6}>
                        <Grid container spacing={0}>
                            <Grid className="bodyfallas-title" item md={5} lg={5}>
                                <h2>Categorías</h2>
                            </Grid>
                            <Grid item className="bodyfallas-button" md={6} lg={6}>
                                <NuevaCatego />
                            </Grid>
                            <Grid item className="divider" md={1} lg={1}>
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    style={{ height: '67px', width: '1px' }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid item md={11} lg={11}>
                                <div style={{ height: '630px', width: '100%' }}>
                                    <DataGrid
                                        checkboxSelection
                                        onSelectionModelChange={(info) => {
                                            handleDataSelect(info);
                                        }}
                                        columns={categoColumns}
                                        sortable={false}
                                        disableColumnFilter={true}
                                        disableColumnMenu={true}
                                        disableColumnSelector={true}
                                        hideFooter={true}
                                        localeText={esES.props.MuiDataGrid.localeText}
                                        rows={catego}
                                    />
                                </div>
                            </Grid>
                            <Grid item className="divider" md={1} lg={1}>
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    style={{ height: '630px', width: '1px' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={7} lg={6}>
                        <Grid container spacing={0}>
                            <Grid className="bodyfallas-title" item md={5} lg={6}>
                                <h2>Fallas</h2>
                            </Grid>
                            <Grid className="bodyfallas-button" item md={7} lg={6}>
                                <NuevaFalla />
                            </Grid>
                        </Grid>
                        <Grid item md={12} lg={12}>
                            <div style={{ height: '630px', width: '100%' }}>
                                <DataGrid
                                    columns={fallasColumns}
                                    sortable={false}
                                    disableColumnFilter={true}
                                    disableColumnMenu={true}
                                    disableColumnSelector={true}
                                    localeText={esES.props.MuiDataGrid.localeText}
                                    rows={fallas}
                                    checkboxSelection={false}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
export default () => (
    <FallasProvider>
        <Fallas></Fallas>
    </FallasProvider>
);
