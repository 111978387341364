import React, { useEffect } from "react";
//Components
import NuevoUsuario from "./NuevoUsuario";
import TablaUsuarios from "./TablaUsuarios";
import EditarUsuario from './EditarUsuario';
import BorrarUsuario from "./BorrarUsuario";
//Material-UI
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core';
//Context
import { useUsuarios, UsuariosProvider } from "./UsuariosContext";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    minHeight: 562,
    width: '100%',
    minWidth: 1070,
  },
}));

function Usuarios () {
  const classes = useStyles();
  const { roles, lineas, getInfo, selectionModel } = useUsuarios();
  
  useEffect(() => {
    getInfo('turnos');
  }, [lineas, roles]);

  return(
    <Grid container className="doc">
      <Grid item md={12} className="head">
        <Grid container alignItems="center" spacing={2}>
          <Grid item md={10}>
            <h1 className="titulo">Usuarios</h1>
            <p className="desc">
              Especifica el nombre y usuario, contacto y roles, 
              tambien le permite crear, editar y borrar usuarios
            </p>    
          </Grid>
          <Grid item md={2}>
            <NuevoUsuario />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} className="body">
        <div className="actions">
          <div className="actions-selected">{selectionModel.length} Seleccionados</div>
          <EditarUsuario />
          <BorrarUsuario />
        </div>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <div className={classes.root}>
              <TablaUsuarios />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default () => (
  <UsuariosProvider>
    <Usuarios> </Usuarios>
  </UsuariosProvider>
)