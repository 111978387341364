import React from 'react';
//Components
import PopUp from './popup';
import ProductosList from './ProductosList';
//Icons
import { CirclePlus } from 'tabler-icons-react';
//Material-UI
import { TextField, Button, Grid } from '@material-ui/core';
//Context
import { useOrdProd } from './OrdProdContext';

export default function NuevaOrdProd() {
    const { setNewOrder, setNuevo, newOrd, setNewOrd, selectedProd } = useOrdProd();

    const postNewOrd = (event) => {
        event.preventDefault();
        setNewOrder(newOrd);
        setNuevo(false);
    };

    const handleinputChange = (event) => {
        setNewOrd({
            ...newOrd,
            [event.target.name]:
                event.target.type === 'number' ? parseInt(event.target.value) : event.target.value,
        });
    };

    return (
        <PopUp
            name="nuevo"
            colorButton="primary"
            textButton={
                <div>
                    <CirclePlus size={18} className="icon" /> Crear nueva orden
                </div>
            }
            buttonClass="listado-ordenes-button"
            popupPrimButton="Crear nueva orden"
            popupSeconButton="Cancelar"
            popupsbclass="popupbp"
            popupTitle={
                <div className="popuphead">
                    <h2 className="popuptitle">Nueva Orden</h2>
                    <p className="popuptitled">
                        Agrega una nueva órden de producción y agrega las características
                        correspondientes a la misma
                    </p>
                </div>
            }
            popupBody={
                <div className="popupbody">
                    <div className="popuptextfield">
                        <TextField
                            id="uxTxtOrdenProd"
                            name="ordenProd"
                            required
                            label="ORDEN DE PRODUCCIÓN"
                            fullWidth
                            onChange={handleinputChange}
                        />
                    </div>
                    <div className="popuptextfield">
                        <Grid container spacing={4}>
                            <Grid item md={7}>
                                <TextField
                                    id="sku"
                                    name="sku"
                                    label="PRODUCTO"
                                    disabled
                                    required
                                    fullWidth
                                    value={selectedProd}
                                    onChange={(e) => handleinputChange(e)}
                                />
                            </Grid>
                            <Grid item md={5}>
                                <ProductosList />
                            </Grid>
                        </Grid>
                    </div>
                    <div className="popuptextfield">
                        <TextField
                            id="date"
                            name="fecha"
                            label="FECHA DE CREACIÓN"
                            type="date"
                            defaultValue={new Date(Date.now()).toISOString().slice(0, 10)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            onChange={handleinputChange}
                        />
                    </div>
                    <div className="popuptextfield">
                        <TextField
                            id="uxTxtOrdenProd"
                            name="cliente"
                            required
                            label="NOMBRE DEL CLIENTE"
                            fullWidth
                            onChange={handleinputChange}
                        />
                    </div>
                    <div className="popuptextfield">
                        <TextField
                            id="date"
                            required
                            name="fechaEntrega"
                            label="FECHA POSIBLE DE ENTREGA"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            onChange={handleinputChange}
                        />
                    </div>

                    <div className="popuptextfield">
                        <TextField
                            id="uxTxtCant"
                            name="cantidad"
                            required
                            label="CANTIDAD"
                            type="number"
                            onChange={handleinputChange}
                        />
                    </div>
                    <div id="popupbs">
                        <Button
                            color="primary"
                            variant="contained"
                            className="popup-button-p"
                            onClick={postNewOrd}
                        >
                            Crear nueva orden
                        </Button>
                    </div>
                </div>
            }
        />
    );
}
