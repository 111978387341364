import React, { useState } from 'react';
//Componentes
import PopUp from './PopUp';
//Material-UI
import { Grid, TextField, Button, Select, MenuItem } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
//Context
import { useBeepers } from './BeepersContext';

export default function NuevoBeeper() {
    const { areasAtencion, postBeeper, setNuevo } = useBeepers();
    const [selectedArea, setSelectedArea] = useState(1);
    const [levels, setLevels] = useState({
        one: false,
        two: false,
        three: false,
    });

    const { one, two, three } = levels;

    const handleChange = (event) => {
        setSelectedArea(event.target.value);
    };

    const createBeeper = (event) => {
        event.preventDefault();
        const data = {
            beeper1: document.getElementById('uxTxtBeeper').value,
            areaAtencion: selectedArea,
            nivel1: one,
            nivel2: two,
            nivel3: three,
            usarSonido: false,
            comentario: '',
        };
        postBeeper(data);
        setNuevo(false);
    };

    const lelvelsChange = (e) => {
        setLevels({ ...levels, [e.target.name]: e.target.checked });
    };

    return (
        <PopUp
            name="nuevo"
            colorButton="default"
            buttonClass="actions-edit"
            popupSeconButton="Cancelar"
            popupsbclass="popupbp"
            isContained="contained"
            popupTitle={
                <div className="popuphead">
                    <h2 className="popuptitle-nodesc">Nuevo Beeper</h2>
                </div>
            }
            popupBody={
                <div className="popupbody">
                    <Grid container spacing={3} style={{ marginBottom: 10 }} alignItems="center">
                        <Grid item md={6}>
                            <TextField
                                id="uxTxtBeeper"
                                label="Número de Beeper"
                                type="number"
                                inputProps={{ min: 0 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6}>
                            <div style={{ marginTop: 15 }}>
                                <Select value={selectedArea} onChange={handleChange} fullWidth>
                                    {areasAtencion.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.nombre}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </Grid>
                        <Grid item md={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">NIVELES</FormLabel>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={one}
                                                onChange={lelvelsChange}
                                                name="one"
                                            />
                                        }
                                        label="Nivel 1"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={two}
                                                onChange={lelvelsChange}
                                                name="two"
                                            />
                                        }
                                        label="Nivel 2"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={three}
                                                onChange={lelvelsChange}
                                                name="three"
                                            />
                                        }
                                        label="Nivel 3"
                                    />
                                </FormGroup>
                                <FormHelperText>Puede marcar más de uno</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div id="popupbs">
                        <Button
                            color="primary"
                            className="popup-button-p"
                            variant="contained"
                            onClick={(e) => createBeeper(e)}
                        >
                            Crear Nuevo Beeper
                        </Button>
                    </div>
                </div>
            }
        />
    );
}
