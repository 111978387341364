import React, { useEffect } from 'react';
//Context
import { useFallas } from './FallasContext';
//Components
import ModalS from '../../components/ModalS';
//Material-UI
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
//Icons
import { CirclePlus } from 'tabler-icons-react';

export default function NuevaFalla() {
    const {
        areas,
        catego,
        textF,
        setTextF,
        openM,
        createFalla,
        handleOpenM,
        handleCloseM,
        selectedCatego,
        setSelectedCatego,
        selectedArea,
        setSelectedArea,
        getAreasAndFallas,
        updateGridF,
        setUpdateGridF,
    } = useFallas();

    useEffect(() => {
        if (updateGridF === true) {
            getAreasAndFallas();
            setUpdateGridF(false);
        }
    }, [updateGridF]);

    return (
        <>
            <Button
                className="popup-button-p"
                variant="contained"
                color="primary"
                onClick={handleOpenM}
            >
                <div>
                    <CirclePlus size={18} />
                </div>
                &nbsp;Agregar falla
            </Button>

            <ModalS
                openM={openM}
                popupTitle={
                    <div className="popuphead">
                        <h2 className="popuptitle">Nueva Falla</h2>
                        <p className="popuptitled1">
                            Agrega una falla que se haya presentado en el desarrollo de la actividad
                            de producción.
                        </p>
                    </div>
                }
                popupBody={
                    <div className="popupbody">
                        <div className="popuptextfield">
                            <TextField
                                id="uxTxtNombre"
                                name="nombre"
                                label="NOMBRE"
                                value={textF}
                                onChange={(e) => setTextF(e.target.value)}
                                fullWidth
                            />
                        </div>
                        <FormControl className="popuptextfield">
                            <InputLabel shrink id="lblArea">
                                AREA
                            </InputLabel>
                            <Select
                                labelId="lblArea"
                                id="area"
                                onChange={(e) => setSelectedArea(e.target.value)}
                                displayEmpty
                                value={selectedArea}
                            >
                                {areas.map((item) => (
                                    <MenuItem key={item.area1} value={item.area1.toString()}>
                                        {item.nombre.toString()}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className="popuptextfield">
                            <InputLabel shrink id="lblCatego">
                                CATEGORÍA
                            </InputLabel>
                            <Select
                                labelId="lblCatego"
                                id="catego"
                                onChange={(e) => setSelectedCatego(e.target.value)}
                                displayEmpty
                                value={selectedCatego}
                            >
                                {catego.map((item) => (
                                    <MenuItem
                                        key={item.categoria}
                                        value={item.categoria.toString()}
                                    >
                                        {item.nombre.toString()}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div className="popupbottom">
                            <div className="popupbpd10">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className="popup-button"
                                    onClick={handleCloseM}
                                    fullWidth
                                >
                                    Cancelar
                                </Button>
                            </div>
                        </div>
                        <div className="popupbpd9">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className="popup-button"
                                onClick={createFalla}
                                fullWidth
                            >
                                Crear nueva falla
                            </Button>
                        </div>
                    </div>
                }
            />
        </>
    );
}
